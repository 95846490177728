import { Loader } from '@components/lib/loader/loader';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  DragEventHandler,
  forwardRef,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  DragAndDropContainer,
  DragAndDropDescription,
  DragAndDropIcon,
  DragAndDropInput,
  DragAndDropLabel,
  DragAndDropMobileButton,
  DragAndDropMobileContainer,
  DragAndDropText,
} from './drag-and-drop.styles';

export interface DragAndDropProps {
  onUpload: (files: File[]) => void;
  isLoading?: boolean;
  accept?: string;
  description?: string;
}

export interface HandleRef {
  trigger: () => void;
}

export const DragAndDrop = forwardRef<HandleRef, DragAndDropProps>(
  ({ onUpload, isLoading = false, accept, description = '' }, ref) => {
    const [isHovered, setIsHovered] = useState(false);
    const theme = useTheme();
    const belowTablet = useMediaQuery(theme.breakpoints.down('md'));
    const id = useId();

    const onUploadWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isLoading) return;

      const files = Array.from(event.target.files ?? []);

      onUpload(files);

      event.target.value = '';
    };

    const onDrop: DragEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault();

      if (isLoading) return;

      let files: Array<File | null | undefined> = [];

      if (e?.dataTransfer?.files) {
        Array.from(e.dataTransfer.files).forEach((file) => {
          files = [...files, file] as Array<File | null | undefined>;
        });
      }

      setIsHovered(false);

      onUpload(
        files.filter((file) => file !== null && file !== undefined) as File[],
      );
    };

    const onDragOver: DragEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault();

      if (isLoading) return;

      setIsHovered(true);
    };

    const onDragLeave: DragEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault();

      if (isLoading) return;

      setIsHovered(false);
    };

    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(
      ref,
      () => {
        return {
          trigger: () => {
            inputRef.current?.click();
          },
        };
      },
      [],
    );

    if (belowTablet) {
      return (
        <DragAndDropMobileContainer htmlFor={id}>
          <DragAndDropMobileButton color="tertiary" variant="outlined">
            {isLoading ? (
              <>
                <span>Uploading Files</span>
                <Loader
                  props={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                />
              </>
            ) : (
              <>
                <span>Upload Files</span>
                <FontAwesomeIcon icon={regular('upload')} />
              </>
            )}
          </DragAndDropMobileButton>
          <DragAndDropInput
            onInput={onUploadWrapper}
            type="file"
            data-cy="drag-and-drop-input-mobile"
            data-testid="drag-and-drop-input-mobile"
            ref={inputRef}
            id={id}
            multiple={true}
            disabled={isLoading}
          />
        </DragAndDropMobileContainer>
      );
    }

    return (
      <DragAndDropContainer
        data-cy="drag-and-drop"
        data-testid="drag-and-drop"
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        $isLoading={isLoading}
        $isHovered={isHovered}
      >
        <DragAndDropIcon>
          {isLoading ? (
            <Loader
              props={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          ) : (
            <FontAwesomeIcon icon={regular('upload')} />
          )}
        </DragAndDropIcon>
        <DragAndDropText>
          <span>Drag and drop a file or</span>
          &nbsp;
          <DragAndDropLabel>
            <DragAndDropInput
              onInput={onUploadWrapper}
              type="file"
              data-cy="drag-and-drop-input"
              data-testid="drag-and-drop-input"
              ref={inputRef}
              multiple={true}
              disabled={isLoading}
              accept={accept}
            />
            click to upload
          </DragAndDropLabel>

          {description && (
            <DragAndDropDescription>{description}</DragAndDropDescription>
          )}
        </DragAndDropText>
      </DragAndDropContainer>
    );
  },
);
