import axios from '@gen2/api/axios.request';
import { config } from '@gen2/config';
import { AxiosPromise } from 'axios';

export type TGetFileUrlRequest = {
  fileId: string;
};

export type TGetFileUrlQueryParams = {
  fileId: string;
};

export type TGetFileUrlResponse = {
  data: {
    signed_url: string;
  };
};

export type TGetFilePreviewUrlResponse = {
  data: {
    preview_url: string;
  };
};

export const getFileUrl = ({
  fileId,
}: TGetFileUrlQueryParams): AxiosPromise<TGetFileUrlResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/files/${fileId}/signed-url`,
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
  });
};

export const getPreviewUrl = ({
  fileId,
}: TGetFileUrlQueryParams): AxiosPromise<TGetFilePreviewUrlResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/files/${fileId}/preview-url`,
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
  });
};

export type TFileUploadRequest = {
  type: string;
  typeId: string;
  file: File;
};

// single file upload
export const uploadFile = ({
  type,
  typeId,
  file,
}: TFileUploadRequest): AxiosPromise<unknown> => {
  const formData = new FormData();
  formData.append('file', file);

  return axios.request({
    url: `${config.apiVersion.v1}/files/${type}/${typeId}`,
    data: formData,
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
};

export type DeleteFilesRequest = {
  type: string;
  typeId: string;
  files: Array<{
    id: string;
  }>;
};

export const deleteFiles = ({
  type,
  typeId,
  files,
}: DeleteFilesRequest): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/files/${type}/${typeId}`,
    method: 'delete',
    data: {
      files: files,
    },
    headers: {
      Accept: 'application/json',
    },
  });
};
