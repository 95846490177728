import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  COMMAND_PRIORITY_HIGH,
  KEY_DOWN_COMMAND,
  LexicalEditor,
} from 'lexical';
import { useEffect } from 'react';
import { EMPTY_EDITOR_STATE } from '../../rich-text-editor';

export type TSetupPluginProps = {
  disabled?: boolean;
  maxLength?: number;
  defaultEditorState?: string;
  onSetup?: (editor: LexicalEditor) => void;
};

export const SetupPlugin = ({
  defaultEditorState,
  maxLength,
  onSetup,
}: TSetupPluginProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor) {
      return;
    }

    const editorState = editor.parseEditorState(
      defaultEditorState || EMPTY_EDITOR_STATE,
    );

    editor.setEditorState(editorState);
  }, [editor, defaultEditorState]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    if (!onSetup) return;

    onSetup(editor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    editor.registerCommand(
      KEY_DOWN_COMMAND,
      (e) => {
        const textContentSize =
          (e?.target as HTMLDivElement)?.textContent?.length ?? 0;

        // stop user from typing if maxLength is reached
        if (maxLength && textContentSize >= maxLength) {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }

        return true;
      },
      COMMAND_PRIORITY_HIGH,
    );
  }, [editor, maxLength]);

  return null;
};
