import { mapKeys } from 'lodash';
import { Link } from 'react-router-dom';

export type ErrorMessage = {
  [key: string]: string[];
};

export interface ErrorResponse {
  message: string;
  errors?: object;
}

export type I18nText = {
  login: string;
  or: string;
  reset: string;
};

export const formatErrorMessage = (messages: ErrorMessage, text: I18nText) => {
  const messageArray: React.ReactNode[] = [];

  mapKeys(messages, (value, key) => {
    const errorMessage = value.join(' ');
    const emailMessage = (
      <>
        <Link to="/login"> {text.login}</Link>{' '}
        {text.or} <Link to="/reset-password">{text.reset}</Link>.
      </>
    );
    messageArray.push(
      <span key={key}>
        {errorMessage} {key === 'email' && emailMessage}
      </span>,
    );
  });
  return messageArray;
};

export const combineErrorMessage = (errors: ErrorMessage) => {
  let messageArray: string[] = [];
  Object.values(errors).forEach((item) => {
    messageArray = [...messageArray, ...[`${item.toString()}`]];
  });
  return messageArray.join('\n');
};
