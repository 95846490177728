import { weights } from '@components/theme/gen2';
import { Dialog, MenuItem, styled } from '@mui/material';
import { rem } from 'polished';

export const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: rem(550),
    margin: 0,
  },
});

export const StyledCreateFolderLabel = styled('p')({
  display: 'flex',
  marginBottom: rem(5),

  '& > span': {
    fontWeight: weights.semiBold,
    marginLeft: rem(5),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export const StyledCreateFolderGroup = styled('div')({
  '&:not(:last-child)': {
    marginBottom: rem(20),
  },
});

export const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  gap: rem(10),
});
