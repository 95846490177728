import { StyledAvatarTooltip } from '@components/lib/avatar-tooltip/avatar-tooltip.styles';
import { Button, Chip } from '@mui/material';
import {
  StyledTagChipLoader,
  StyledTagsLine,
  StyledTagsLineError,
  StyledTagsLineLabel,
  StyledTagsLineTags,
} from './tags-line.styles';

export interface TagsLineProps {
  label: string | React.ReactNode;
  tags: Array<{ id: string; name: string; tooltip?: JSX.Element | string }>;
  onDeleteTag?: (id: string) => void;
  onLabelClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  disabled?: boolean;
  'data-cy'?: string;
  error?: string | React.ReactNode;
  isHover?: boolean;
  hiddenButoon?: boolean;
}

export function TagsLine({
  label,
  tags,
  isLoading,
  onDeleteTag,
  onLabelClick,
  disabled=false,
  'data-cy': dataCy,
  error = '',
  isHover = true,
  hiddenButoon = true,
  ...rest
}: Readonly<TagsLineProps>) {
  return (
    <StyledTagsLine
      isHover={isHover}
      isError={Boolean(error)}
      className="disabled"
      aria-disabled={disabled}
      data-cy={dataCy}
      {...rest}
    >
      <StyledTagsLineLabel isError={Boolean(error)} isHover={isHover}>
        {Boolean(error) && <span data-cy="asterisk">*</span>}
        {label}:
      </StyledTagsLineLabel>
      <StyledTagsLineTags>
        {!error ? (
          tags &&
          tags.map((tag, index: number) => {
            if (!isLoading) {
              const tagContent = (
                <Chip
                  color="contact"
                  key={tag.id}
                  label={tag.name}
                  data-cy={`tag-${tag.name}`}
                  sx={{
                    height: 'auto',
                    '& .MuiChip-label': {
                      display: 'block',
                      whiteSpace: 'normal',
                      textAlign: 'left',
                    },
                  }}
                  {...(onDeleteTag
                    ? { onDelete: () => onDeleteTag(tag.id) }
                    : {})}
                />
              );

              return tag.tooltip ? (
                // <>
                <StyledAvatarTooltip
                  placement="bottom-start"
                  followCursor={true}
                  title={tag.tooltip}
                  key={`tooltip-${index}`}
                  data-testid={`tooltip-${index}`}
                >
                  {tagContent}
                </StyledAvatarTooltip>
              ) : (
                // </>
                tagContent
              );
            }

            return (
              <StyledTagChipLoader key={tag.id} animation="wave">
                {tag.name}
              </StyledTagChipLoader>
            );
          })
        ) : (
          <StyledTagsLineError data-cy="errorMessage">
            {error}
          </StyledTagsLineError>
        )}
        {!hiddenButoon && (
          <Button
            data-cy="add-contacts"
            color="tertiary"
            variant="outlined"
            size="small"
            disabled={disabled}
            onClick={onLabelClick}
          >
            Add Contacts
          </Button>
        )}
      </StyledTagsLineTags>
    </StyledTagsLine>
  );
}

export default TagsLine;
