import { QueryClient } from '@tanstack/react-query';

// Create a client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      suspense: false,
      // staleTime: 60 * 1000 * 3 // 3 minutes
    },
  },
});
