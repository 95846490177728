import { config } from '@gen2/config';
import { AxiosPromise } from 'axios';
import axios from '../axios.request';

export const deleteInvites = (inviteIds: string[]): AxiosPromise<{data: [], message:string }> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites`,
    data: {invite_ids: inviteIds},
    method: 'delete',
  });
};
