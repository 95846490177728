import { TInviteRequest } from '@gen2/api/invite-requests/api';
import { create } from 'zustand';

export interface IRequestsStore {
  toDeleteRequest?: TInviteRequest;
  activeRequest: TInviteRequest | null;
  setToDeleteRequest: (req?: TInviteRequest) => void;
  setActiveRequest: (req: TInviteRequest | null) => void;
}

const defaultRequestsState = {
  selectedRequest: {} as TInviteRequest,
  activeRequest: null,
};

export const useRequestsStore = create<IRequestsStore>()((set) => ({
  // state
  ...defaultRequestsState,

  // actions
  setToDeleteRequest: (req) => set({ toDeleteRequest: req }),
  setActiveRequest: (req) => set({ activeRequest: req }),
}));
