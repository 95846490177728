import * as base from "../AuthCodeExchangeVerifier";
import { loginRequest } from "./authConfig";
export class AuthCodeExchangeVerifier extends base.AuthCodeExchangeVerifier {
  override parseState(state: string): string {
    return state.split('|')[1];
  }

  override parseScope(scope: string | null): string {
    return scope ?? loginRequest.scopes.join(',');
  }
}
