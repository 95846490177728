import { colors, weights } from '@components/theme/gen2';
import {
  Box,
  styled,
  ButtonBase,
  Stack,
  ButtonBaseProps,
  Popover,
  Chip,
  Avatar,
} from '@mui/material';

import { rem } from 'polished';

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
interface StyleBaseButtonProps extends ButtonBaseProps {
  num: number;
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const StyleBaseButton = styled(ButtonBase)<StyleBaseButtonProps>(
  ({ num }) => ({
    display: 'inline-flex',
    gap: rem(2),
    height: rem(38),
    width: num >= 100 ? rem(54) : rem(38),
    padding: rem(5),
    borderRadius: num >= 100 ? rem(20) : '50%',
    border: `1px solid ${colors.gray}`,
    backgroundColor: colors.white,
    color: colors.grayDark3,
  }),
);

export const StyledStack = styled(Stack)({
  width: '100%',
});

export const FilterBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: rem(3),
});

export const SelectBox = styled(Box)({
  width: rem(208),
});

// MuiPopover-paper
export const StyledPopover = styled(Popover)({
  '& .MuiPopover-paper': {
    overflow: 'visible',
  },
});

export const Container = styled('div')({
  width: rem(644),
  height: rem(508),
  background: colors.white,
  borderRadius: rem(4),
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  overflowY: 'auto',
  overflowX: 'hidden',
});

export const Content = styled('div')({
  width: '100%',
  height: rem(456),
  maxHeight: rem(456),
  display: 'flex',
});

export const Actions = styled('div')({
  padding: `${rem(8)} ${rem(12)}`,
  background: colors.grayLight3,
  display: 'flex',
  gap: rem(10),
  alignItems: 'center',
  justifyContent: 'flex-end',
});

export const Tabs = styled('div')({
  width: rem(165),
  background: colors.grayLight3,
  paddingLeft: rem(12),
  paddingTop: rem(24),
});

export const Title = styled('span')({
  display: 'block',
  fontSize: rem(12),
  fontWeight: 600,
  color: colors.grayDark2,
  textTransform: 'uppercase',
  marginBottom: rem(12),
});

export const Tab = styled('div')<{ active: boolean }>(({ active }) => ({
  padding: rem(12),
  fontSize: rem(14),
  fontWeight: 500,
  color: active ? colors.blueDark2 : colors.grayDark2,
  backgroundColor: active ? colors.blueLight4 : colors.grayLight3,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledChip = styled(Chip)({
  backgroundColor: colors.secondaryDark1,
  color: colors.white,
  cursor: 'pointer',
});

export const TabPanel = styled('div')({
  flex: '1 1 auto',
  background: colors.white,
  padding: `${rem(24)} ${rem(20)}`,
});

export const Heading = styled('div')({
  fontSize: rem(15),
  fontWeight: 600,
  color: colors.grayDark3,
  marginBottom: rem(12),
  '> span': {
    display: 'block',
    fontSize: rem(14),
    fontWeight: 400,
    color: colors.grayDark3,
  },
});

export const List = styled('div')({
  paddingTop: rem(12),
  maxHeight: rem(300),
  overflow: 'auto',
});

export const Item = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${rem(4)} ${rem(12)}`,
  borderBottom: `1px solid ${colors.grayLight3}`,
});

export const Info = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: colors.grayDark3,
  gap: rem(8),
});

export const Name = styled('div')({
  '> span': {
    display: 'block',
    fontSize: rem(12),
    color: colors.grayDark2,
  },
});

export const StyledAvatar = styled(Avatar)({
  width: rem(28),
  height: rem(28),
  background: colors.white,
  color: colors.grayDark2,
  fontSize: rem(14),
  fontWeight: weights.normal,
  textTransform: 'uppercase',
  '&.MuiAvatar-root': {
    border: `1px solid ${colors.grayDark2}`,
  },
});

export const ChipList = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: rem(5),
  marginTop: rem(12),
});

export const ChipItem = styled(Chip)({
  '&.MuiChip-root:hover': {
    backgroundColor: colors.grayLight2,
  },
});

export const StyledEmpty = styled('div')({
  display: 'flex',
  padding: rem(20),
  alignItems: 'flex-start',
  justifyContent: 'center',
  height: rem(300),
  color: colors.grayDark3,
});

export const LoadingBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: rem(300),
  color: colors.grayDark3,
});
