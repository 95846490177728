import { colors } from '@components/theme/gen2';
import { Button, styled } from '@mui/material';
import { rem, transparentize } from 'polished';

interface DragAndDropContainerProps {
  $isHovered: boolean;
  $isLoading: boolean;
}

export const DragAndDropContainer = styled('div')<DragAndDropContainerProps>(
  ({ $isHovered, $isLoading }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: rem(10),
    border: `${rem(1)} dashed ${colors.gray}`,
    paddingTop: rem(18),
    paddingBottom: rem(18),
    width: '100%',

    ...($isLoading && {
      backgroundColor: transparentize(0.9, colors.grayDark2),
      borderColor: colors.grayDark1,
      color: colors.grayDark1,

      '& label': {
        opacity: 0.5,
      },
    }),

    ...($isHovered && !$isLoading && {
      backgroundColor: transparentize(0.8, colors.secondary),
      borderColor: colors.tertiary,
    }),
  }),
);

export const DragAndDropIcon = styled('div')({
  fontSize: rem(24),
  minHeight: rem(36),
});

export const DragAndDropText = styled('div')({});

export const DragAndDropLabel = styled('label')({
  color: colors.secondaryDark1,
  cursor: 'pointer',
  textDecoration: 'underline',
  '&:hover': {
    color: colors.secondaryDark3,
    textDecoration: 'none',
  },
});

export const DragAndDropInput = styled('input')({
  display: 'none',
});

export const DragAndDropMobileContainer = styled('label')({});

export const DragAndDropMobileButton = styled(Button)({
  width: '100%',
  gap: rem(10),
  pointerEvents: 'none',
});

export const DragAndDropDescription = styled('p')({
  textAlign: 'center',
});
