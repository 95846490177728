export const TemplateIcon = () => {
  return (
    <svg
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 0L42.5 7.5H37.5C36.0938 7.5 35 6.40625 35 5V0ZM11.25 9.375V29.375C11.25 33.2031 14.2969 36.25 18.125 36.25H33.125C34.1406 36.25 35 37.1094 35 38.125C35 39.2188 34.1406 40 33.125 40H18.125C12.1875 40 7.5 35.3125 7.5 29.375V9.375C7.5 8.35938 8.28125 7.5 9.375 7.5C10.3906 7.5 11.25 8.35938 11.25 9.375Z"
        fill="#97A5B4"
      />
      <path
        d="M20 0H35V5C35 6.40625 36.0938 7.5 37.5 7.5H42.5V27.5C42.5 30.3125 40.2344 32.5 37.5 32.5H20C17.1875 32.5 15 30.3125 15 27.5V5C15 2.26562 17.1875 0 20 0Z"
        fill="#EFA707"
      />
    </svg>
  );
};
