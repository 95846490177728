import {
  IForgotPasswordForm,
  handleForgotPasswordError,
} from '@gen2/api/forgot-password/api';
import { useForgotPasswordMutation } from '@gen2/api/forgot-password/hooks';
import { useGlobalRegionsQuery } from '@gen2/api/global-region/hooks';
import { useLoginEmailMutation } from '@gen2/api/login/hooks';
import { useRouter } from '@gen2/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Stack, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import AuthLayout from '../layout/AuthLayout/AuthLayout';
import { Title, StyledSubmit, Subtitle, Email } from './forgot-password.styled';

const schema = Yup.object().shape({
  email: Yup.string().email('email.invalid').required('email.required'),
});

const initialFormValues: IForgotPasswordForm = {
  email: '',
};

const ForgotPassword = () => {
  const router = useRouter();
  const { t } = useTranslation('validation');
  const [searchParams] = useSearchParams();
  const { data, isError } = useGlobalRegionsQuery();
  const regions = useMemo(() => {
    return data ?? [];
  }, [data]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPasswordForm>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });

  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);
  const { mutateAsync: forgotPassAsync } = useForgotPasswordMutation();
  const { mutateAsync: globalEmailAsync } = useLoginEmailMutation();
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [resendEmail, setResendEmail] = useState<string>();

  const sanitizeEmail: string | undefined = useMemo(() => {
    let email = '';

    if (resendEmail) {
      email = resendEmail;

      try {
        schema.validateSync({ email });
      } catch (error) {
        email = '';
      }
    }

    return email;
  }, [resendEmail]);

  useEffect(() => {
    if (searchParams.has('resend') && !resendEmail) {
      setIsShowSuccess(true);
      setResendEmail(searchParams.get('resend')?.toString());
    } else if (searchParams.has('invalid')) {
      setMessages([t('reset_password.invalid_link', { ns: 'page' })]);
      setIsShow(true);
    }
  }, [searchParams, resendEmail, t]);

  const onSubmit: SubmitHandler<IForgotPasswordForm> = async (form) => {
    try {
      setIsLoading(true);
      const res = await globalEmailAsync(form);
      if (res.status === 200) {
        const region = res.data.data.region;
        const result = regions.find((r) => r.name === region);
        if (result) {
          localStorage.setItem('regionEndpoint', result.endpoint);
        }
        await forgotPassAsync(form);
        router.navigate(
          `/forgot-password/confirmation?email=${encodeURIComponent(
            form.email,
          )}`,
        );
      }
    } catch (error) {
      handleForgotPasswordError(error, t, setIsShow, setMessages);
    } finally {
      setIsLoading(false);
    }
  };

  if (isError) {
    return <span>Error</span>;
  }

  return (
    <AuthLayout isShowAlert={isShow}>
      <header>
        <Title data-cy="title">
          {t('forgot_password.title', { ns: 'page' })}
        </Title>
      </header>

      <Subtitle data-cy="subtitle">
        {t('forgot_password.subtitle', { ns: 'page' })}
      </Subtitle>

      <form noValidate>
        <Stack spacing={3} sx={{ flexWrap: 'wrap' }}>
          {isShow && messages.length > 0 && (
            <Alert data-cy="alert" icon={false} severity="error" color="error">
              {messages &&
                messages.map((message, idx) => {
                  return <div key={idx}>{message}</div>;
                })}
            </Alert>
          )}

          {isShowSuccess && (
            <Alert icon={false} severity="success" data-cy="alert">
              <span>{t('reset_password.resend', { ns: 'page' })}</span>
              <Email>{`"${sanitizeEmail}"`}</Email>
            </Alert>
          )}

          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                required
                id="email"
                label={t('email.label')}
                error={Boolean(errors.email)}
                helperText={errors.email?.message && t(errors.email.message)}
                inputProps={{
                  'data-cy': 'email-input',
                }}
              />
            )}
          />

          <StyledSubmit
            variant="contained"
            color="primary"
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
            type="submit"
            data-cy="submit"
          >
            {t('forgot_password.btn', { ns: 'page' })}
          </StyledSubmit>
        </Stack>
      </form>
      <p>
        <Link data-cy="login-link" to="/login">
          {t('forgot_password.footnote', { ns: 'page' })}
        </Link>
      </p>
    </AuthLayout>
  );
};

export default ForgotPassword;
