import * as Yup from 'yup';

export const MIN_TITLE_LENGTH = 2;
export const MAX_TITLE_LENGTH = 151;
export const MIN_MESSAGE_LENGTH = 2;
export const MAX_MESSAGE_LENGTH = 1001;
export const MAX_REQUESTS = 50;

export const inviteRequestsSchema = Yup.object().shape({
  requests: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
      title: Yup.string()
        .test('title', 'request.title.required', function(value) {
          if (!value) {
            return false;
          }

          return true;
        })
        .test('title', 'request.title.max', function(value) {
          if (value && value.length > MAX_TITLE_LENGTH - 1) {
            return false;
          }

          return true;
        })
        .test('title', 'request.title.min', function(value) {
          if (value && value.length < MIN_TITLE_LENGTH) {
            return false;
          }

          return true;
        }),
      description: Yup.string()
        .min(MIN_MESSAGE_LENGTH, 'request.fileUpload.message.min')
        .max(MAX_MESSAGE_LENGTH - 1, 'request.fileUpload.message.max')
        .transform((_, originalValue) => {
          if (!originalValue) return null; // Treat falsy values as null
          return originalValue;
        })
        .nullable(),
    }),
  ),
});
