import SplashScreen from '@gen2/app/components/SplashScreen';
import routes from '@gen2/config/routes';
import { ToastContextProvider } from '@gen2/contexts';
import { useAuth } from '@gen2/hooks';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { gen2Theme } from '@nx-fe/components';
import { FC } from 'react';
import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router';
const App: FC = () => {
  const { isInitialized } = useAuth();
  const content = useRoutes(routes);

  return (
    <ThemeProvider theme={gen2Theme}>
      <ToastContextProvider>
        <CssBaseline />
        {isInitialized ? content : <SplashScreen />}
      </ToastContextProvider>
      <Toaster />
    </ThemeProvider>
  );
};

export default App;
