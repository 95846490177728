import { ReactElement } from 'react';
import {
  StyledAvatar,
  StyledTooltipContainer,
  StyledTooltipDetails,
  StyledTooltipEmail,
  StyledTooltipName
} from './avatar-tooltip.styles';

export const createAvatarTooltip = (avatar: string | ReactElement, name: string, email: string) => {
  return (
    <StyledTooltipContainer>
      <StyledAvatar
        data-cy="tooltip-avatar-btn"
      >
        {avatar}
      </StyledAvatar>
      <StyledTooltipDetails>
        <StyledTooltipName>{name}</StyledTooltipName>
        <StyledTooltipEmail>{email}</StyledTooltipEmail>
      </StyledTooltipDetails>
    </StyledTooltipContainer>
  );
};
