import { colors, textSizes, typography, weights } from '@components/theme/gen2';
import { styled } from '@mui/material';
import { rem } from 'polished';

interface TContentStyleProps {
  sender: boolean;
}

interface TMessageAvatarProps {
  fromMessage?: boolean;
}

export const MessageLineContainer = styled('div')({
  marginTop: rem(10),
  overflowWrap: 'anywhere',
  '&:first-of-type > div': {
    marginBottom: rem(0),
  },
});

export const Main = styled('div')({
  marginBottom: rem(20),
  display: 'flex',
  gap: rem(10),
});

export const AvatarContainer = styled('div')(({ theme }) => ({
  width: rem(40),
  height: rem(40),
  borderRadius: rem(25),
  [theme.breakpoints.down('sm')]: {
    flexShrink: 0,
  },
}));

export const Content = styled('div')<TContentStyleProps>(
  ({ theme, sender }) => ({
    padding: rem(20),
    backgroundColor: colors.white,
    borderRadius: rem(10),
    borderTopLeftRadius: !sender ? rem(10) : 0,
    borderTopRightRadius: sender ? rem(10) : 0,
    border: `1px solid ${colors.grayLight1}`,
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
  }),
);

export const MessageDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: rem(5),
  [theme.breakpoints.down('sm')]: {
    gap: rem(5),
    justifyContent: 'space-between',
  },
}));

export const MessageName = styled('span')(({ theme }) => ({
  flexGrow: 1,
  color: colors.grayDark3,
  fontFamily: typography.fontFamily,
  fontSize: rem(textSizes.base),
  fontStyle: 'normal',
  fontWeight: weights.semiBold,
  lineHeight: 'normal',
  [theme.breakpoints.down('sm')]: {
    width: 'fit-content',
    maxWidth: '55%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export const MessageDateTime = styled('span')(({ theme }) => ({
  color: colors.grayDark2,
  textAlign: 'right',
  fontFamily: typography.fontFamily,
  fontSize: textSizes.sm,
  fontStyle: 'normal',
  fontWeight: weights.normal,
  lineHeight: 'normal',
  [theme.breakpoints.down('sm')]: {
    whiteSpace: 'nowrap',
  },
}));

export const MessageContent = styled('span')({
  color: colors.grayDark4,
  fontFamily: typography.fontFamily,
  fontSize: rem(textSizes.sm1),
  fontStyle: 'normal',
  fontWeight: weights.normal,
  lineHeight: 'normal',
  flex: '0 0 100%',
  whiteSpace: 'pre-wrap',
});

export const StyledAvatar = styled('div')<TMessageAvatarProps>(
  ({ theme, fromMessage = false }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    width: rem(40),
    height: rem(40),
    fontFamily: typography.fontFamily,
    lineHeight: 1,
    borderRadius: '50%',
    overflow: 'hidden',
    userSelect: 'none',
    backgroundColor: colors.white,
    color: colors.grayDark2,
    borderColor: colors.grayDark2,
    borderWidth: rem(1),
    borderStyle: 'solid',
    fontSize: rem(18),
    [theme.breakpoints.down('sm')]: {
      display: !fromMessage ? 'none' : 'flex',
    },
  }),
);
