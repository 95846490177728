import Indicator from '@components/lib/indicators/indicators';
import { useAuth } from '@gen2/hooks';
import { Invite } from '@gen2/types/invite';
import { utcToOrgTimezone } from '@gen2/utils/time';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IndicatorBox, Subject } from './invite-listing-tab.styled';

export const Content = ({ subject, due_at, indicator, status, archived_at }: Invite) => {
  const { t } = useTranslation('inviteListing');
  const { user } = useAuth();
  // timezone
  const orgTimezone = get(user, 'organisations[0].timezone');
  return (
    <>
      <Subject>
        <h4 data-cy="title">{subject}</h4>
        <p data-cy="due-at">
          {
            status && status === 'archived'
              ? (`${t('archivedAt')} ${utcToOrgTimezone(archived_at, orgTimezone)}`)
              : (`${t('dueAt')} ${due_at ? utcToOrgTimezone(due_at, orgTimezone) : ''}`)
          }
        </p>
      </Subject>

      {indicator && indicator !== 'draft' && (
        <IndicatorBox>
          <Indicator
            status={indicator}
            text={t(indicator, { ns: 'indicators' })}
          />
        </IndicatorBox>
      )}
    </>
  );
};
