import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import React from 'react';
import { ToolbarPlugin } from './plugins/ToolbarPlugin/ToolbarPlugin';
import {
  StyledContainer,
  StyledContentEditable,
  StyledEditorLabel,
  StyledInputContainer,
  StyledPlaceholder,
} from './rich-text-editor.styled';
import { editorTheme } from './theme/theme';

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
const onError: InitialConfigType['onError'] = (error) => {
  console.error(error);
};

const Placeholder = ({ text }: { text?: string }) => {
  return (
    <StyledPlaceholder>{text || 'Type your message...'}</StyledPlaceholder>
  );
};

export type RichTextEditorProps = {
  isAutoFocusEnabled?: boolean;
  label?: string | React.ReactNode;
  children?: React.ReactNode;
  dataCy?: string;
  placeholder?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const EMPTY_EDITOR_STATE =
  '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

const RichTextEditor = React.memo(
  ({
    children,
    dataCy = 'rich-text',
    label,
    isAutoFocusEnabled,
    placeholder,
    ...props
  }: RichTextEditorProps) => {
    const initialConfig: InitialConfigType = {
      namespace: 'fi-rte',
      theme: editorTheme,
      onError,
    };

    return (
      <LexicalComposer initialConfig={initialConfig}>
        {label ? (
          <StyledEditorLabel data-cy={`${dataCy}-label`}>
            {label}
          </StyledEditorLabel>
        ) : (
          ''
        )}
        <StyledContainer>
          <ToolbarPlugin />
          <StyledInputContainer>
            <RichTextPlugin
              contentEditable={
                <StyledContentEditable data-cy={`${dataCy}-input`} {...props} />
              }
              placeholder={<Placeholder text={placeholder} />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            {isAutoFocusEnabled && <AutoFocusPlugin />}
            <HistoryPlugin />
            {children}
          </StyledInputContainer>
        </StyledContainer>
      </LexicalComposer>
    );
  },
);

export default RichTextEditor;
