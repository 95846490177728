/* eslint-disable @typescript-eslint/no-empty-function */
import { useInviteListQuery } from '@gen2/api/invite-listing/hooks';
import { Invite } from '@gen2/types/invite';
import { SearchCondition } from '@gen2/types/search-condition';
import { InfiniteData } from '@tanstack/react-query';
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

export interface InviteListingContextValue {
  // values
  invites:
    | InfiniteData<{
        data: Invite[];
        next: boolean;
      } | null>
    | undefined;

  hasNext: boolean;
  isLoading: boolean;
  currInviteState: [string, Dispatch<SetStateAction<string>>];
  searchState: [SearchCondition, Dispatch<SetStateAction<SearchCondition>>];
  selected: [Invite[], Dispatch<SetStateAction<Invite[]>>];
  collateModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  arvhiveModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  resendModalState: [boolean, Dispatch<SetStateAction<boolean>>];

  openLeft: boolean;
  openRight: boolean;

  // functions
  fetchMoreData: () => void;
  toggleLeftSidebar: () => void;
  toggleRightSidebar: () => void;
}

interface InviteListingProviderProps {
  children: ReactNode;
  initialSearchCondition?: SearchCondition;
}

export const defaultSearchCondition: SearchCondition = {
  'filter[status]': [
    'sent',
    'progressing',
    'ready_for_review',
    'in_review',
    'completed',
  ],
  'filter[subject]': '',
  'sort[column]': 'sent_at',
  'sort[order]': 'desc',
  per_page: 25,
  page: 1,
};

export const InviteListingContext = createContext<InviteListingContextValue>({
  invites: undefined,
  hasNext: true,
  isLoading: false,
  currInviteState: ['', (f) => f],
  searchState: [defaultSearchCondition, (f) => f],
  selected: [[] as Invite[], (f) => f],
  collateModalState: [false, (f) => f],
  arvhiveModalState: [false, (f) => f],
  resendModalState: [false, (f) => f],
  openLeft: true,
  openRight: true,

  // functions
  fetchMoreData: () => {},
  toggleLeftSidebar: () => {},
  toggleRightSidebar: () => {},
});

export const InviteListingProvider: FC<InviteListingProviderProps> = ({
  children,
  initialSearchCondition,
}) => {
  const [currInviteStatus, setCurrInviteStatus] = useState<string>('');
  const [selectedInvites, setSelectedInvites] = useState<Invite[]>([]);

  // control some modal
  const [isCollateModalOpen, setIsCollateModalOpen] = useState<boolean>(false);
  const [archiveOpen, setArchiveOpen] = useState(false);
  const [resendOpen, setResendOpen] = useState(false);

  // start tabs
  const [openLeftSidebar, setOpenLeftSidebar] = useState<boolean>(true);
  const [openRightSideBar, setOpenRightSideBar] = useState<boolean>(false);
  const toggleLeftSidebar = () => {
    setOpenLeftSidebar((prev) => !prev);
    setOpenRightSideBar(false);
  };
  const toggleRightSidebar = () => {
    setOpenRightSideBar((prev) => !prev);
    setOpenLeftSidebar(false);
  };
  // tabs ending

  // start listing logic
  const [searchCondition, setSearchCondition] = useState<SearchCondition>(
    initialSearchCondition || defaultSearchCondition,
  );
  const { data, hasNextPage, fetchNextPage, isLoading } =
    useInviteListQuery(searchCondition);

  const invites = useMemo(() => {
    return data;
  }, [data]);
  // ending list logic

  return (
    <InviteListingContext.Provider
      value={{
        invites: invites,
        hasNext: Boolean(hasNextPage),
        isLoading,
        currInviteState: [currInviteStatus, setCurrInviteStatus],
        searchState: [searchCondition, setSearchCondition],
        selected: [selectedInvites, setSelectedInvites],
        collateModalState: [isCollateModalOpen, setIsCollateModalOpen],
        arvhiveModalState: [archiveOpen, setArchiveOpen],
        resendModalState: [resendOpen, setResendOpen],
        fetchMoreData: fetchNextPage,
        openLeft: openLeftSidebar,
        openRight: openRightSideBar,
        toggleLeftSidebar,
        toggleRightSidebar,
      }}
    >
      {children}
    </InviteListingContext.Provider>
  );
};

export const InviteListingConsumer = InviteListingContext.Consumer;
