import { EditorThemeClasses } from 'lexical';
import './theme.css';

export const editorTheme: EditorThemeClasses = {
  text: {
    bold: 'fi-rte-bold',
    italic: 'fi-rte-italic',
    underline: 'fi-rte-underline',
  },
};
