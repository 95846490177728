import { textSizes, weights } from '@components/theme/gen2';
import { styled } from '@mui/material';
import { rem } from 'polished';

export const StyledAddContactHeader = styled('div')({
  fontSize: rem(textSizes.md),
  fontWeight: weights.medium,
  padding: `${rem(20)} ${rem(20)}`,
});

export const StyledAddContactForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(20),
  padding: `0 ${rem(20)}`,
  [theme.breakpoints.up('sm')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',

    '& > *': {
      gridColumn: '1 / -1',
    },

    '& > .firstName': {
      gridColumn: '1 / 2',
    },

    '& > .lastName': {
      gridColumn: '2 / -1',
    },
  },
}));

export const StyledAddContactFooter = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  columnGap: rem(15),
  paddingTop: rem(15),
  paddingBottom: rem(15),
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
