import { colors } from '@components/theme/gen2';
import { styled } from '@mui/material/styles';
import { rem } from 'polished';

interface ToggleBoxProps {
  switchWidth: number;
  height: number;
}

interface ToggleItemProps {
  itemWidth: number;
  fontColor: string;
  height: number;
  fontWeight: number;
  [key: string]: unknown;
}

export const ToggleBox = styled('div')<ToggleBoxProps>(
  ({ switchWidth, height }) => ({
    boxSizing: 'content-box',
    width: rem(switchWidth),
    height: rem(height),
    display: 'inline-block',
    borderRadius: '100px',
    border: `1px solid ${colors.gray}`,
    '&.toggle-primary': {
      background: colors.primary,
    },
    '&.toggle-secondary': {
      background: colors.secondary,
    },
    '&.toggle-gray': {
      background: colors.grayDark1,
    },
    '&.toggle-gradient': {
      background: `linear-gradient(180deg, rgba(236, 239, 241, 0) 0%, #ECEFF1 100%)`,
    },
    '&.toggle-white': {
      backgroundColor: colors.white,
    },
  }),
);

export const ToggleItem = styled('span')<ToggleItemProps>(
  ({ itemWidth, height, fontColor, fontWeight }) => ({
    width: rem(itemWidth),
    lineHeight: rem(height),
    fontSize: rem(12),
    fontWeight: fontWeight,
    color: fontColor === 'white' ? colors.white : colors.grayDark3,
    display: 'inline-block',
    textAlign: 'center',
    cursor: 'pointer',
    borderRight: '1px solid #d9dee4',
    textTransform: 'uppercase',
    '&:last-child': {
      borderRight: 'unset',
    },
    '&.shadow': {
      boxShadow: 'inset 4px 4px 4px rgba(0, 0, 0, 0.15)',
    },
    '&.border-rounded-start': {
      borderRadius: '14px 0px 0px 14px',
    },
    '&.border-rounded-end': {
      borderRadius: '0px 14px 14px 0px',
    },
    '&.border-rounded': {
      borderRadius: '14px',
    },
  }),
);
