import { styled } from '@mui/material';
import { rem } from 'polished';
import { StyledSubMenu } from './sidebar/sidebar.styled';

export const StyledLayout = styled('div')(({ theme }) => ({
  '--sidebar-header-height': '50px',
  '--header-height': '50px',
  '--footer-height': '50px',
  '--sidebar-header-z': '100',
  '--sidebar-submenu-z': '100',
  '--sidebar-z': '100',
  '--header-z': '100',
  '--footer-z': '100',

  [theme.breakpoints.up('sm')]: {
    '--sidebar-header-height': '60px',
    '--header-height': '60px',
    '--footer-height': '0',
    '--sidebar-z': '110',
    '--sidebar-header-z': '110',
    '--header-z': '110',

    display: 'grid',
    gridTemplateColumns: `${rem(70)} 1fr`,
    gridTemplateRows: 'auto 1fr',
  },
}));

export const StyledContent = styled('div')(({ theme }) => ({
  minHeight: '100vh',

  main: {
    paddingTop: `calc(var(--header-height) + var(--sidebar-header-height))`,
    paddingBottom: 'var(--footer-height)',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 'unset',
    },
  },

  [theme.breakpoints.up('sm')]: {
    gridRow: '2 / -1',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: 'auto 1fr',
    minWidth: '100vw',
    minHeight: `calc(100vh - var(--header-height))`,

    [StyledSubMenu]: {
      gridColumn: '1 / 1',
      gridRow: '1 / -1',
      top: '0',
    },

    main: {
      gridColumn: '2 / -1',
      gridRow: '1 / -1',
    },
  },
}));
