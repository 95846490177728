import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popper, Tooltip } from '@mui/material';
import {
  DateCalendar,
  DateCalendarProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import {
  StyledCalendarPickerContainer,
  StyledDatePickerLine,
  StyledDatePickerLineContainer,
  StyledDisplay,
  StyledIcon,
  StyledLabel,
} from './date-picker-line.styles';

/* eslint-disable-next-line */
export type DatePickerLineProps = {
  label: string;
  name: string;
  className?: string;
  displayFormat?: string;
  error?: string | React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clearErrors: any;
  tooltipTitle: string;
} & DateCalendarProps<Dayjs | null>;

export function DatePickerLine({
  name,
  label,
  className,
  displayFormat = 'DD-MMM-YYYY',
  error = '',
  clearErrors,
  tooltipTitle,
  ...props
}: DatePickerLineProps) {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const labelRef = useRef<HTMLLabelElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);

  const toggleCalendarPicker = () => {
    setIsPickerOpen(!isPickerOpen);
    clearErrors(name);
  };

  const onCalendarPickerChange = (date: Dayjs | null) => {
    if (props.onChange) {
      props.onChange(date);
    }
    setIsPickerOpen(false);
  };

  useOnClickOutside(popperRef, () => {
    setIsPickerOpen(false);
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDatePickerLine
        isError={!!error}
        className={className}
        onClick={toggleCalendarPicker}
        data-cy={name}
      >
        <Tooltip placement="top" title={tooltipTitle}>
          <FontAwesomeIcon icon={solid('circle-question')} size="lg" />
        </Tooltip>
        <StyledLabel
          ref={labelRef}
          htmlFor={name}
          data-cy={`${name}-label`}
          isError={!!error}
        >
          {!!error && <span data-cy="asterisk">*</span>}
          {label}:
        </StyledLabel>
        <StyledDisplay>
          {!error ? (
            props.value?.format(displayFormat) || 'Day-Month-Year'
          ) : (
            <span data-cy="errorMessage">{error}</span>
          )}
        </StyledDisplay>
        <StyledDatePickerLineContainer>
          {props.value && <StyledIcon icon={regular('pencil-alt')} />}
        </StyledDatePickerLineContainer>
      </StyledDatePickerLine>
      <Popper
        id={`${name}-calendar-popper`}
        data-testid={`${name}-calendar-popper`}
        open={isPickerOpen}
        anchorEl={labelRef?.current}
        placement="bottom-start"
        ref={popperRef}
      >
        <StyledCalendarPickerContainer>
          <DateCalendar
            data-cy={`${name}-calendar-picker`}
            {...props}
            onChange={onCalendarPickerChange}
          />
        </StyledCalendarPickerContainer>
      </Popper>
    </LocalizationProvider>
  );
}

export default DatePickerLine;
