import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
  StyledHintList,
  StyledHintListItem,
  StyledHintListTitle,
  StyledUl,
} from './hint-list.styled';

export const HintListItem = ({
  children,
  active,
}: {
  children: React.ReactNode;
  active?: boolean;
}) => {
  return (
    <StyledHintListItem
      active={active}
      data-cy={active ? 'success-status' : 'fail-status'}
    >
      {active ? (
        <FontAwesomeIcon icon={solid('check-circle')} />
      ) : (
        <FontAwesomeIcon icon={regular('times')} />
      )}
      {children}
    </StyledHintListItem>
  );
};

export const HintList = ({
  children,
  label,
}: {
  children: React.ReactNode;
  label: string;
}) => {
  return (
    <StyledHintList>
      <StyledHintListTitle>{label}</StyledHintListTitle>
      <StyledUl>{children}</StyledUl>
    </StyledHintList>
  );
};

export const PasswordHintList = ({
  password
}: {
  password: string
}) => {
  const { t } = useTranslation('validation');

  return (
    <HintList label={t('hint.label')}>
      <HintListItem active={password.length >= 7}>
        {t('hint.totalChars')}
      </HintListItem>
      <HintListItem active={/[A-Z]/.test(password)}>
        {t('hint.upperCase')}
      </HintListItem>
      <HintListItem active={/[a-z]/.test(password)}>
        {t('hint.lowerCase')}
      </HintListItem>
      <HintListItem active={/\d/.test(password)}>
        {t('hint.number')}
      </HintListItem>
      <HintListItem active={/[!@#$%^&*']/.test(password)}>
        {t('hint.specialChar')}
      </HintListItem>
    </HintList>
  )
}
