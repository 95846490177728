import { MenuItem } from '@gen2/app/layout/sidebar/data';
import { sleep } from '@gen2/utils/time';
import { create } from 'zustand';

type TSelected = Omit<MenuItem, 'icon'>;

export interface ISidebar {
  open: boolean;
  subMenuOpen: boolean;
  selected: TSelected;
  setSubMenuOpen: (open: boolean) => void;
  setOpen: (open: boolean) => void;
  setSelected: (item: TSelected) => void;
  switchSubmenu: (item: TSelected) => void;
}

export const useSidebarStore = create<ISidebar>()((set) => ({
  open: false,
  subMenuOpen: false,
  selected: {
    id: 'invites',
    to: '/',
    title: 'Invites',
  },
  setOpen: (open: boolean) => set({ open }),
  setSubMenuOpen: (open: boolean) => set({ subMenuOpen: open }),
  switchSubmenu: (item: TSelected) => {
    set({ subMenuOpen: false, selected: item });

    // 300 is the animation duration
    sleep(300).then(() => {
      set({ subMenuOpen: true });
    });
  },
  setSelected: (item: TSelected) => set({ selected: item }),
}));
