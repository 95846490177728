import { authRefreshToken } from '@gen2/api/auth/api';
import history from '@gen2/app/components/router/history';
import { config } from '@gen2/config';
import { IAuthTokenParams } from '@gen2/types/auth';
import { encode as base64encode } from 'base64-arraybuffer';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import randomstring from 'randomstring';
import { diffFromNow, increaseTimeToLocal, timeToUtcObject } from '../time';
const MIN_REFRESH_GAP = 60 * 1000; // 1 min
const Redirect_Protocol = process.env['NX_RUNTIME_ENV'] === 'local' ? 'http' : 'https';

export const Client_ID = config.clientId;
export const Redirect_Uri = `${Redirect_Protocol}://${config.redirectUri}`;

type OAuthCode = {
  state: string;
  challenge: string;
};

export const generateOauthCode = async (): Promise<OAuthCode> => {
  const state = randomstring.generate(40);
  const code_verifier = randomstring.generate(128);
  const storage = window.sessionStorage;
  storage.clear();
  storage.setItem('state', state);
  storage.setItem('code_verifier', code_verifier);

  const encoder = new TextEncoder();
  const data = encoder.encode(code_verifier);
  const digest = await window.crypto.subtle.digest('SHA-256', data);
  const challenge = base64encode(digest)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
  return { state, challenge };
};

export const generateStateString = (): string => {
  const storage = window.sessionStorage;

  if (storage.getItem('state')) {
    return storage.getItem('state') as string;
  }

  storage.removeItem('state');

  const state = randomstring.generate(40);

  storage.setItem('state', state);

  return state;
};

export const removeStateString = (): void => {
  window.sessionStorage.removeItem('state');
};

export const openRedirectUri = (state: string, challenge: string) => {
  globalThis.open(
    `//${localStorage.getItem('regionEndpoint')}${config.apiVersion.v1}/authorize?response_type=code&code_challenge_method=S256&client_id=${Client_ID}&state=${state}&code_challenge=${challenge}&redirect_uri=${Redirect_Uri}&scope`,
    '_self',
  );
};

const getNewAccessTokenByRefreshToken = async (refresh_token: string) => {
  const params = new URLSearchParams();
  const queryParams: IAuthTokenParams = {
    client_id: String(Client_ID),
    refresh_token,
    grant_type: 'refresh_token',
    scope: '',
  };
  for (const param in queryParams) {
    params.append(param, queryParams[param as keyof IAuthTokenParams] ?? '');
  }
  const { data } = await authRefreshToken(params);
  localStorage.setItem('accessToken', data?.access_token);
  localStorage.setItem('refreshToken', data?.refresh_token);
  return data?.access_token || '';
};

// Only for decoding jwt token.
const formatTimestamp = (jwtExp: number): string =>
  increaseTimeToLocal(timeToUtcObject('1970-1-1'), jwtExp, 'second');

class AccessTokenManager {
  public cache: string | null | undefined;
  public isFetching: boolean | undefined;
  public refreshing: Promise<string> | null | undefined;

  constructor() {
    this.getUserAccessToken = this.getUserAccessToken.bind(this);
    const at = localStorage.getItem('accessToken');
    if (at) {
      this.cache = at;
    }
  }

  isAccessTokenExpired(token: string): string {
    const jwtDecode = jwt_decode<JwtPayload>(token);
    if (jwtDecode && jwtDecode.exp) {
      const expiredAt = formatTimestamp(jwtDecode.exp);
      const diff = diffFromNow(expiredAt) as number;
      const isExpired = MIN_REFRESH_GAP > diff;
      return isExpired ? 'EXIPRED' : 'ACTIVE';
    }
    return 'INVALID';
  }

  async getUserTokenInner(): Promise<string> {
    let accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    // Only 2 tokens are a valid format
    if (!accessToken || !refreshToken) return '';
    const status = this.isAccessTokenExpired(accessToken);
    if (status !== 'INVALID') {
      if (status === 'EXIPRED') {
        try {
          accessToken = await getNewAccessTokenByRefreshToken(refreshToken);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          if (error.status === 401 || error.status === 500) {
            history.push('/logout');
          }
        }
      }
    } else {
      // jwt_token can not be decoded.
      accessToken = '';
    }
    return accessToken;
  }

  async getUserAccessToken() {
    if (!this.cache || this.isAccessTokenExpired(this.cache) === 'EXIPRED') {
      if (this.refreshing) {
        await this.refreshing;
      } else {
        this.refreshing = this.getUserTokenInner().then((accessToken) => {
          this.cache = accessToken;
          this.refreshing = null;
          return this.cache;
        });
        await this.refreshing;
      }
    }
    return this.cache;
  }
}

const instance = new AccessTokenManager();
export const getUserToken = instance.getUserAccessToken;
