import { Box, Button } from '@mui/material';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledSocialLoginBtn = styled(Button)({
  //
});

export const StyledSocialLoginIcon = styled('img')({
  maxWidth: rem(20),
});

export const StyledOrBox = styled(Box)({
  display: 'inline-flex',
  margin: 0,
});

export const StyledLeftBar = styled(Box)({
  borderTop: '1px solid #BDC6CF',
  width: '100%',
  margin: '10px',
  marginLeft: 0,
});

export const StyledRightBar = styled(Box)({
  borderTop: '1px solid #BDC6CF',
  width: '100%',
  margin: '10px',
  marginRight: 0,
});
