import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  StyledFollowUpSuccess,
  StyledFollowUpSuccessHeading,
} from './follow-up.styled';

export const FollowUpSuccess = () => {
  const { inviteId } = useParams<{ inviteId: string }>();
  const { t } = useTranslation('followUp');

  return (
    <StyledFollowUpSuccess>
      <img src="/assets/invite-send-art.svg" alt="Success" />
      <StyledFollowUpSuccessHeading>
        {t('afterSent.title')}
      </StyledFollowUpSuccessHeading>
      <p>{t('afterSent.description')}</p>
      <Link to={`/invite-listing/${inviteId}`}>
        <Button variant="contained" color="primary">
          {t('afterSent.view_btn')}
        </Button>
      </Link>
    </StyledFollowUpSuccess>
  );
};
