import { useUploadProfileAvatarMutation } from '@gen2/api/user-settings/hooks';
import { useAuth, useToast } from '@gen2/hooks';
import { stringAvatar } from '@gen2/utils/name';
import { Input } from '@mui/material';
import { Loader } from '@nx-fe/components';
import { AxiosResponse } from 'axios';
import { ChangeEvent, useRef } from 'react';
import Resizer from 'react-image-file-resizer';
import { StyledAvatar, StyledImg } from './avatar.styled';

const Avatar = () => {
  const toast = useToast();
  const { user, initialize } = useAuth();
  const inputRef = useRef<HTMLInputElement>(null);

  const { mutateAsync, isLoading } = useUploadProfileAvatarMutation();

  const handleProfileClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const uploadFile = async (file: File) => {
    Resizer.imageFileResizer(
      file,
      220,
      220,
      'JPEG',
      100,
      0,
      (value: string | File | Blob | ProgressEvent<FileReader>) => {
        const blob = value as Blob;
        const newImg = new File([blob], file?.name, { type: blob.type });
        handleAvatarUploadImage(newImg);
      },
      'blob',
      150,
      150,
    );
  };

  const handleAvatarUploadImage = async (newImg: File) => {
    try {
      const formData = new FormData();
      formData.append('file', newImg);

      if (user) {
        await mutateAsync({ id: user.id, file: formData });

        initialize();

        toast.show({
          text: 'Avatar uploaded successfully',
          variant: 'success',
        });
      }
    } catch (e) {
      const error = e as AxiosResponse;
      if (error.status === 422) {
        const errorMessage = error?.data?.message;
        toast.show({
          text: `${errorMessage}`,
          variant: 'error',
        });
      } else {
        toast.show({
          text: 'Avatar upload error',
          variant: 'error',
        });
      }
    }
  };

  return (
    <StyledAvatar
      aria-haspopup="true"
      onClick={handleProfileClick}
      data-cy="header-avatar-btn"
      disabled={isLoading}
    >
      {isLoading ? (
        <Loader
          props={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      ) : user?.avatar_url ? (
        <StyledImg src={user?.avatar_url} alt="avatar" />
      ) : (
        stringAvatar(user?.first_name, user?.last_name)
      )}

      <Input
        type="file"
        inputProps={{
          accept: 'image/png, image/jpg, image/jpeg',
        }}
        inputRef={inputRef}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const file = e.target.files?.[0];

          if (file) {
            uploadFile(file);
          }
        }}
        style={{ display: 'none' }}
      />
    </StyledAvatar>
  );
};

export default Avatar;
