import { colors } from '@components/theme/gen2';
import styled from 'styled-components';

export const StyledFooterNav = styled.footer`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${colors.grayDark4};
  grid-column: 1 / -1;
  height: var(--footer-height);
  z-index: var(--footer-z);

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: none;
  }
`;
