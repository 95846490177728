import { IntFolder } from '@gen2/api/integrations/api';
import { AlertColor } from '@mui/material';
import { create } from 'zustand';

type AlertSettings = {
  severity: AlertColor;
  message: string;
  open: boolean;
};

export interface IIntegrationsStore {
  alert: AlertSettings;
  selectedFolder: IntFolder | null;
  currentFolder: IntFolder;
  isFinderOpen: boolean;
  isCreateFolderOpen: boolean;

  setAlert: (alert: AlertSettings) => void;
  setSelectedFolder: (folder: IntFolder | null) => void;
  setCurrentFolder: (folder: IntFolder) => void;
  setFinderOpen: (isOpen: boolean) => void;
  setCreateFolderOpen: (isOpen: boolean) => void;
}

export const useIntegrationsStore = create<IIntegrationsStore>()((set) => ({
  alert: {
    severity: 'success',
    message: '',
    open: false,
  },
  selectedFolder: null,
  currentFolder: {
    id: '',
    name: '',
    path: '',
  },
  isFinderOpen: false,
  isCreateFolderOpen: false,

  setAlert: (alert) => set({ alert }),
  setSelectedFolder: (selectedFolder) => set({ selectedFolder }),
  setCurrentFolder: (currentFolder) => set({ currentFolder }),
  setFinderOpen: (isOpen) => set({ isFinderOpen: isOpen }),
  setCreateFolderOpen: (isOpen) => set({ isCreateFolderOpen: isOpen }),
}));
