import axios from '@gen2/api/axios.request';
import { config } from '@gen2/config';
import { AxiosPromise } from 'axios';
import { TInviteRequest } from '../invite-requests/api';

export type TFollowUp = {
  id: string;
  name: string;
  status: 'draft' | 'sent' | 'invalid';
  sent_at: string;
  requests: Array<TInviteRequest>;
};

export type TCreateFollowUpRequest = {
  name: string;
  inviteId: string;
};

export type TCreateFollowUpResponse = {
  data: {
    follow_up: TFollowUp;
  };
};

export const createFollowUp = ({
  inviteId,
  ...payload
}: TCreateFollowUpRequest): AxiosPromise<TCreateFollowUpResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/follow-ups`,
    data: payload,
    method: 'post',
    headers: {
      Accept: 'application/json',
    },
  });
};

export type TGetFollowUpResponse = {
  data: {
    follow_up: TFollowUp;
  };
};

export type TGetFollowUpQueryParams = {
  inviteId: string;
  followUpId: string;
};

export const getFollowUp = ({
  inviteId,
  followUpId,
}: TGetFollowUpQueryParams): AxiosPromise<TGetFollowUpResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/follow-ups/${followUpId}`,
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
  });
};

export type TGetFollowUpsResponse = {
  data: {
    follow_ups: TFollowUp[];
  };
};

export type TGetFollowUpsQueryParams = {
  inviteId: string;
};

export const getFollowUps = ({
  inviteId,
}: TGetFollowUpsQueryParams): AxiosPromise<TGetFollowUpsResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/follow-ups`,
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
  });
};

export type TUpdateFollowUpRequest = {
  name: string;
  inviteId: string;
  followUpId: string;
};

export type TUpdateFollowUpResponse = {
  data: {
    follow_up: TFollowUp;
  };
};

export const updateFollowUp = ({
  inviteId,
  followUpId,
  ...payload
}: TUpdateFollowUpRequest &
  TGetFollowUpQueryParams): AxiosPromise<TUpdateFollowUpResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/follow-ups/${followUpId}`,
    data: payload,
    method: 'put',
    headers: {
      Accept: 'application/json',
    },
  });
};

export type TSendFollowUpRequest = {
  inviteId: string;
  followUpId: string;
};

export type TSendFollowUpResponse = {
  data: [];
  message: string;
};

export const sendFollowUp = ({
  inviteId,
  followUpId,
}: TSendFollowUpRequest): AxiosPromise<TSendFollowUpResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/follow-ups/${followUpId}/send`,
    method: 'post',
    headers: {
      Accept: 'application/json',
    },
  });
};
