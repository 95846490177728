/* eslint-disable @typescript-eslint/no-explicit-any */
import { config } from '@gen2/config';
import { AxiosPromise } from 'axios';
import axios from '../axios.request';


export const getFeatureFlags = (): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/feature-flags`,
    method: 'get',
  });

