import { colors } from '@components/theme/gen2';
import { Box, styled } from '@mui/material';
import { rem } from 'polished';

export const AttachmentItem = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: rem(50),
  padding: `0 ${rem(20)}`,
  borderBottom: `1px solid ${colors.grayLight1}`,
  [theme.breakpoints.down('md')]: {
    padding: `0 ${rem(5)}`,
    gap: rem(5),
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: rem(5),
  '> span': {
    color: colors.grayDark2,
  },
  [theme.breakpoints.down('md')]: {
    '> p': {
      maxWidth: rem(110),
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
}));
