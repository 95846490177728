import { useConfirmationModalStore } from '@gen2/app/components/confirmation-modal/hooks/confirmation-modal-store';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Loader, ModalCloseButton } from '@nx-fe/components';
import { Trans } from 'react-i18next';
import {
  StyledContentContainer,
  StyledConfirmButton,
} from './confirmation-modal.styled';

export const ConfirmationModal = () => {
  const {
    isConfirmationModalOpen: open,
    onClose,
    onSubmit,
    isLoading,
    title,
    submitLabel,
    confirm,
    submitColor,
    closeConfirmationModal,
    message,
  } = useConfirmationModalStore();

  const handleClose = () => {
    if (isLoading) {
      return false;
    }

    // TODO: remove this line later
    onClose && onClose();

    // NOTE: this is the new one
    closeConfirmationModal();

    return true;
  };

  const handleConfirm = () => {
    if (isLoading) {
      return false;
    }

    // TODO: remove this line later
    onSubmit && onSubmit();

    // NOTE: this is the new one
    confirm();

    return true;
  };

  return (
    <Dialog
      id="confirmation-modal"
      open={open ?? false}
      onClose={handleClose}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
      data-cy="confirmation-modal"
      disableEscapeKeyDown={isLoading}
    >
      <ModalCloseButton
        data-cy="close-btn"
        aria-label="close"
        onClick={handleClose}
      />
      <StyledContentContainer>
        <DialogTitle id="dialog-title">{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="dialog-description" align="left">
            <Trans components={{ strong: <strong />, br: <br /> }}>
              {message}
            </Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="tertiary"
            variant="outlined"
            data-cy="cancel-btn"
            disabled={isLoading || (!open && !isLoading)}
          >
            Cancel
          </Button>

          <StyledConfirmButton
            onClick={handleConfirm}
            color={submitColor}
            variant="contained"
            data-cy="confirmation-btn"
            disabled={isLoading || (!open && !isLoading)}
          >
            {isLoading ? (
              <Loader
                props={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            ) : (
              submitLabel
            )}
          </StyledConfirmButton>
        </DialogActions>
      </StyledContentContainer>
    </Dialog>
  );
};
