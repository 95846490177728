import Logout from '@gen2/app/components/Logout';
import ProtectedRoute from '@gen2/app/components/ProtectedRoute';
import { SocialLoginCallback } from '@gen2/app/components/social-login/social-login-callback';
import AuthorizationRequired from '@gen2/app/error/AuthorizationRequired';
import NotFound from '@gen2/app/error/NotFound';
import FollowUp from '@gen2/app/follow-up/follow-up';
import { FollowUpSuccess } from '@gen2/app/follow-up/follow-up-success';
import ForgotPasswordConfirmation from '@gen2/app/forgot-password-confirmation/forgot-password-confirmation';
import ForgotPassword from '@gen2/app/forgot-password/forgot-password';
import StorageIntegrationCallback from '@gen2/app/integrations/callbacks/int-callback';
import Integrations from '@gen2/app/integrations/integrations';
import CloudStorage from '@gen2/app/integrations/storage/storage';
import { IntegrationProvider } from '@gen2/app/integrations/useIntegrationContext';
import DraftListingLayout from '@gen2/app/invite-listing/layout/draft-listing-layout';
import SendInvites from '@gen2/app/invites/send-invites/send-invites';
import { SendInviteSuccess } from '@gen2/app/invites/send-invites/success/success';
import Login from '@gen2/app/login/Login';
import OauthCallbackHandler from '@gen2/app/login/OauthCallbackHandler';
import ResetPassword from '@gen2/app/reset-password/reset-password';
import SignupInvite from '@gen2/app/signup-invite/set-password';
import Signup from '@gen2/app/signup/signup';
import UserSettings from '@gen2/app/user-settings/user-settings';
import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Layout } from '../app/layout/Layout';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) =>
(
  <Suspense fallback={null}>
    <Component {...props} />
  </Suspense>
);

const Dashboard = Loadable(lazy(() => import('../app/dashboard/Dashboard')));
const InviteListingLayout = Loadable(
  lazy(() => import('../app/invite-listing/layout/invite-listing-layout')),
);
const InviteListingMain = Loadable(
  lazy(() => import('../app/invite-listing/layout/invite-listing-main')),
);
const InviteListingDetails = Loadable(
  lazy(() => import('../app/invite-listing/layout/invite-listing-details')),
);
const Branding = Loadable(lazy(() => import('../app/branding/branding')));
const FileOption = Loadable(
  lazy(() => import('../app/file-options/file-options')),
);
const Templates = Loadable(lazy(() => import('../app/templates/templates')));

const CollationHistory = Loadable(
  lazy(() => import('../app/invites/collation-history/collation-history')),
);

const ArchivedLayout = Loadable(
  lazy(() => import('../app/invite-listing/layout/archived-listing-layout')),
);

const SwitchOrganisations = Loadable(
  lazy(() => import('../app/switch-organisations/switch-organisations')),
);

const Account = Loadable(lazy(() => import('../app/account/account')));

const TeamMemberNew = Loadable(
  lazy(() => import('../app/team-member/layout/team-member-create')),
);

const TeamIndex = Loadable(
  lazy(() => import('../app/team-member/layout/team-member-listing')),
);

const TeamUserView = Loadable(
  lazy(() => import('../app/team-member/layout/team-member-view')),
);

const Register = Loadable(Signup);
const SendInvite = Loadable(SendInvites);
const Signin = Loadable(Login);
const ForgotPass = Loadable(ForgotPassword);
const ResetPass = Loadable(ResetPassword);
const SetPassword = Loadable(SignupInvite);
const ProfileSettings = Loadable(UserSettings);
const SocialLoginGenericCallback = Loadable(SocialLoginCallback);
const StorageGoogleCallback = Loadable(StorageIntegrationCallback);

const routes: RouteObject[] = [
  // The gen2 pages.
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="/invite-listing" replace />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: '/send-invites', // for create invite
        element: <SendInvite />,
      },
      {
        path: '/send-invites/:id', // for update invite
        element: <SendInvite />,
      },
      {
        path: '/success-sent/:id', // for create invite success page
        element: <SendInviteSuccess />,
      },
      {
        path: 'templates',
        element: <Templates />,
      },
      {
        path: 'integrations',
        element: (
          <IntegrationProvider>
            <Outlet />
          </IntegrationProvider>
        ),
        children: [
          {
            index: true,
            element: <Integrations />,
          },
          /* {
            path: 'google',
            element: <GoogleDrive />,
          }, */
          {
            path: ':vendor',
            element: <CloudStorage />,
          },
        ],
      },
      {
        path: 'invite-listing',
        element: <InviteListingLayout />,
        children: [
          {
            index: true,
            element: <InviteListingMain />,
          },
          {
            path: ':id',
            element: <InviteListingDetails />,
          },
        ],
      },
      {
        path: 'draft-listing',
        element: <DraftListingLayout />,
        children: [
          {
            index: true,
            element: <InviteListingMain />,
          },
          {
            path: ':id',
            element: <InviteListingDetails />,
          },
        ],
      },
      {
        path: 'archived-listing',
        element: <ArchivedLayout />,
        children: [
          {
            index: true,
            element: <InviteListingMain />,
          },
          {
            path: ':id',
            element: <InviteListingDetails />,
          },
        ],
      },
      {
        path: '/user-settings',
        element: <ProfileSettings />,
      },
      {
        path: '/team-members',
        children: [
          {
            index: true,
            element: <TeamIndex />,
          },
          {
            path: ':id',
            element: <TeamUserView />,
          },
        ],
      },
      {
        path: '/team-members/member', // for create user
        element: <TeamMemberNew />,
      },
      {
        path: '/team-members/member/:id', // for update user
        element: <TeamMemberNew />,
      },
      {
        path: '/branding',
        element: <Branding />,
      },
      {
        path: '/file-options',
        element: <FileOption />,
      },
      {
        path: '/invites/collation-history',
        element: <CollationHistory />,
      },
      {
        path: '/switch-organisations',
        element: <SwitchOrganisations />,
      },
      {
        path: '/account',
        element: <Account />,
      },
      {
        path: '/follow-up/:inviteId/:followUpId',
        element: <FollowUp />,
      },
      {
        path: '/follow-up/:inviteId/success',
        element: <FollowUpSuccess />,
      },
    ],
  },
  {
    path: '/login',
    element: <Signin />,
  },
  {
    path: '/auth/integration/:vendor/callback',
    element: <StorageGoogleCallback />,
  },
  {
    path: '/auth/:identityProvider/callback',
    element: <SocialLoginGenericCallback />,
  },
  {
    path: '/sign-up',
    element: <Register />,
  },
  {
    path: '/logout', // for logout programmatically
    element: <Logout />,
  },
  {
    path: '/2023-02/callback',
    element: <OauthCallbackHandler />,
  },
  {
    path: '/forgot-password',
    children: [
      {
        index: true,
        element: <ForgotPass />,
      },
      {
        path: 'confirmation',
        element: <ForgotPasswordConfirmation />,
      },
    ],
  },
  {
    path: '/reset-password',
    element: <ResetPass />,
  },
  {
    path: '/signup-invite/:uuid/:hash',
    element: <SetPassword />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '401',
    element: <AuthorizationRequired />,
  },
];

export default routes;
