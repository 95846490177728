import { Snackbar, styled } from '@mui/material';
import { rem } from 'polished';

export const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
      width: rem(400),
      minHeight: rem(96),
      '& .MuiAlert-action': {
        alignItems: 'start',
      },
      [theme.breakpoints.down('xs')]: {
        width: rem(360),
        minHeight: rem(80),
      }
}));
