import { colors } from '@components/theme/gen2';
import { Box, FormControl, Typography, styled } from '@mui/material';
import { rem } from 'polished';

export const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'visible',
  // paddingTop: rem(10),
  rowGap: 5,
});

export const StyledBox = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  '> span': {
    display: 'inline-block',
    marginRight: rem(5),
  },
  '> p': {
    position: 'absolute',
    right: rem(-20),
  },
});

export const StyledTypography = styled(Typography)({
  fontSize: rem(13),
  color: colors.grayDark2,
});

export const StyledFormControl = styled(FormControl)({
  margin: 0,
  width: '100%',
});
