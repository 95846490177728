import { Box, styled } from '@mui/material';
import { rem } from 'polished';

export const ContainerStyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const ComponentsStyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: rem(30),
  padding: `${rem(30)} 0 0 0`,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: rem(15),
  },
}));

export const MessageBox = styled(Box)(({ theme }) => ({
  width: `calc(70% + ${rem(30)})`,
  maxWidth: rem(1030),

  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: `0 ${rem(15)}`,
  },

  [theme.breakpoints.down('sm')]: {
    padding: '0',
  },
}));