import { colors, headingType, weights } from '@components/theme/gen2';
import { Theme } from '@mui/material';
import { PickerComponents } from '@mui/x-date-pickers/themeAugmentation';
import { rem } from 'polished';

export const MuiDateCalendar: PickerComponents<Theme>['MuiDateCalendar'] = {
  styleOverrides: {
    root: {
      '& .Mui-selected, & .Mui-selected:focus': {
        backgroundColor: colors.primary,
        color: colors.white,
        fontWeight: weights.medium,
      },
    },
  },
};

export const MuiPickersPopper: PickerComponents<Theme>['MuiPickersPopper'] = {
  styleOverrides: {
    paper: {
      borderRadius: rem(10),
      border: `${rem(1)} solid ${colors.grayLight1}`,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
    },
  },
};

export const MuiPickersCalendarHeader: PickerComponents<Theme>['MuiPickersCalendarHeader'] =
  {
    styleOverrides: {
      root: {
        '& .MuiPickersArrowSwitcher-spacer': {
          width: rem(10),
        },
      },
      label: {
        fontSize: rem(18),
        color: colors.grayDark3,
        fontFamily: headingType,
        fontWeight: weights.medium,
      },
      switchViewIcon: {
        color: colors.grayDark3,
      },
    },
  };

export const MuiPickersDay: PickerComponents<Theme>['MuiPickersDay'] = {
  styleOverrides: {
    root: {
      color: colors.grayDark3,
      fontSize: rem(14),
    },
  },
};

export const MuiDayCalendar: PickerComponents<Theme>['MuiDayCalendar'] = {
  styleOverrides: {
    weekDayLabel: {
      color: colors.grayDark3,
      fontSize: rem(14),
    },
  },
};

export const MuiPickersArrowSwitcher: PickerComponents<Theme>['MuiPickersArrowSwitcher'] =
  {
    styleOverrides: {
      button: {
        color: colors.grayDark3,
      },
    },
  };
