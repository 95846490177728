import { useRouter } from '@gen2/hooks';
import { SearchCondition } from '@gen2/types/search-condition';
import { useMediaQuery, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import {
  InviteListingConsumer,
  InviteListingProvider,
} from '../context/invite-listing-context';
import { useInviteListing } from '../hook/useInviteListing';
import DraftListingTab from '../invite-listing-tab/draft-listing-tab';
import { Left, StyledBox } from './invite-listing-layout.styled';

const DraftListingLayout: FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const router = useRouter();
  const { searchState } = useInviteListing();
  const [searchCondition] = searchState;
  const { id } = router.query;

  const initialSearchCondition: SearchCondition = useMemo(() => {
    return {
      ...searchCondition,
      'sort[column]': 'created_at',
      'filter[status]': ['draft'],
      per_page: 10,
    };
  }, [searchCondition]);

  const shouldRenderSidebar = !(matches && id);

  return (
    <InviteListingProvider initialSearchCondition={initialSearchCondition}>
      <StyledBox>
        {shouldRenderSidebar && (
          <InviteListingConsumer>
            {({ openLeft }) => (
              <Left open={openLeft} data-cy="left-sidebar">
                <DraftListingTab />
              </Left>
            )}
          </InviteListingConsumer>
        )}
        <Outlet />
      </StyledBox>
    </InviteListingProvider>
  );
};

export default DraftListingLayout;
