import { config } from '@gen2/config';
import { FilterStatus } from '@gen2/types/search-condition';
import { AxiosPromise } from 'axios';
import axios from '../axios.request';

export interface InvitesListPayload {
  'filter[status]': FilterStatus[];
  'filter[subject]'?: string;
  'sort[column]'?: string;
  'sort[order]'?: string;
  per_page: number;
  page: number;
}

export const getInvitesList = (
  search: InvitesListPayload,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites`,
    params: { ...search, 'filter[status]': search['filter[status]'].join(',') },
    method: 'get',
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInvitesItem = (inviteId: string): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}`,
    method: 'get',
  });

export const getInvitesRequests = (
  inviteId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/requests?sort[order]=asc&per_page=50&filter[exclude_follow_up_requests]=1`,
    method: 'get',
  });

export const getDownloadFiles = (
  inviteId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/files/zip`,
    method: 'get',
  });

export const archiveInvite = (
  inviteId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/archive`,
    method: 'post',
  });

export interface ResendPayload {
  message: string;
  contact_ids: string[];
}

export const resendInvite = (
  inviteId: string,
  payload: ResendPayload,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/resend`,
    method: 'post',
    data: payload,
  });
