import { useSidebarStore } from '@gen2/hooks/stores';
import { StyledFooterNav } from './footerNav.styled';

export const FooterNav = () => {
  const sidebarStore = useSidebarStore();

  function toggleSidebar() {
    sidebarStore.setSubMenuOpen(false);
    sidebarStore.setOpen(!sidebarStore.open);
  }

  return (
    <StyledFooterNav>
      <button onClick={toggleSidebar}>Toggle Sidebar</button>
    </StyledFooterNav>
  );
};
