import { baseType, colors, weights } from '@components/theme/gen2';
import { Chip, styled } from '@mui/material';
import { rem } from 'polished';

export const ToLine = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: rem(10),
  padding: `${rem(10)} ${rem(16)}`,
  background: 'none',
  border: 'none',
  [theme.breakpoints.up('sm')]: {
    padding: `${rem(10)} ${rem(50)}`,
  },
  color: colors.grayDark3,
}));

export const ToLineLabel = styled('p')({
  fontWeight: weights.medium,
  fontFamily: baseType,
  fontSize: rem(14),
  color: colors.grayDark2,
  userSelect: 'none',
});

export const ToLineTags = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: rem(6),
});

export const StyledChip = styled(Chip)({
  '&.MuiChip-colorContact:hover': {
    backgroundColor: colors.grayLight2,
  },
  '& .MuiChip-icon': {
    color: colors.grayDark1,
  },
});

export const StyledBox = styled('div')({
  borderRadius: rem(5),
  border: `1px solid ${colors.grayLight1}`,
  background: colors.white,
  boxShadow: `0px 4px 6px 0px rgba(0, 0, 0, 0.15)`,
  padding: `${rem(8)}`,
  color: colors.grayDark3,
  '>p': {
    width: rem(212),
    height: rem(34),
    padding: `${rem(6)} ${rem(10)}`,
  },
});
