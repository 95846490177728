import { useAuth } from '@gen2/hooks';
import { FC, useLayoutEffect } from 'react';
import { Outlet } from 'react-router';
import { FooterNav } from './footer-nav/footerNav';
import { Header } from './header/header';
import { StyledContent, StyledLayout } from './layout.styled';
import { SidebarHeader } from './sidebar/header/header';
import { Sidebar } from './sidebar/sidebar';

export const Layout: FC = () => {
  const { user } = useAuth();

  useLayoutEffect(() => {
    if (!user) return;

    try {
      const { organisations } = user;

      pendo.initialize({
        visitor: {
          id: user?.email ?? '', // Email of the user
          uuid: user?.id ?? '', // UUID of the user
          full_name: `${user?.first_name} ${user?.last_name}`, // full name of the user
          countryCode: organisations[0].country_iso2_code ?? '', // country code of the user
        },
        account: {
          id: organisations[0].accounts[0].id ?? '', // id of the account
          // currentPlanName: 'FileInvite Free',
          companyName: organisations[0].name, // name of the organization
          environment: window.location.origin, // name of the environment
        },
      });
    } catch (e: unknown) {
      console.error('Pendo Error:', e);
    }
  }, [user]);

  return (
    <StyledLayout>
      <Header />
      <StyledContent>
        <SidebarHeader />
        <Sidebar />
        <main>
          <Outlet />
        </main>
        <FooterNav />
      </StyledContent>
    </StyledLayout>
  );
};
