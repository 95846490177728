import { colors, textSizes, weights } from '@components/theme/gen2';
import {
  Components,
  CSSObject,
  Palette,
  PaletteColor,
  Theme,
} from '@mui/material';
import { rem } from 'polished';

export const MuiChip: Components<Theme>['MuiChip'] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const currentColor: PaletteColor = theme.palette[
        ownerState.color as keyof Palette
      ] as PaletteColor;

      let styles: CSSObject = {
        paddingTop: rem(3),
        paddingBottom: rem(3),
        paddingLeft: rem(8),
        paddingRight: rem(8),
        gap: rem(6),
        height: 'auto',
        color: currentColor.contrastText,
      };

      if (ownerState.icon && ownerState.label) {
        styles = {
          ...styles,
          paddingTop: rem(2),
          paddingLeft: rem(8),
          paddingRight: rem(8),
          display: 'inline-flex',
          flexDirection: 'row-reverse',
          alignItems: 'center',
        };
      }

      if (ownerState.icon && !ownerState.label) {
        styles = {
          ...styles,
          width: rem(25),
          height: rem(25),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
          // label
          '& .MuiChip-label': {
            display: 'none',
          },
        };
      }

      if (ownerState.variant === 'filled') {
        return {
          ...styles,
          backgroundColor: currentColor.main,
          border: `1px solid ${currentColor.light}`,
        };
      }
      if (ownerState.variant === 'outlined') {
        return {
          ...styles,
          backgroundColor: colors.white,
          border: `1px solid ${currentColor.dark}`,
        };
      }

      return styles;
    },
    label: {
      textTransform: 'uppercase',
      fontWeight: weights.medium,
      fontSize: textSizes.sm,
      padding: 0,
    },
    icon: {
      margin: 0,
    },
    deleteIcon: {
      margin: 0,
      fontSize: textSizes.md,
    },
  },
  variants: [
    {
      props: { variant: 'outlined', color: 'navy' },
      style: {
        color: colors.secondaryDark2,
      },
    },
    {
      props: { variant: 'filled', color: 'green' },
      style: {
        color: colors.greenDark3,
      },
    },
  ],
};
