import { useSendInviteStore } from "./store";
import { TabPanel } from "./tab-panel/tab-panel"

export const EmailPreview = () => {
  const store = useSendInviteStore();

  return (
    <TabPanel value={store.activeTabIndex} index={2}>
      THIS IS THE EMAIL PREVIEW
    </TabPanel>
  );
}
