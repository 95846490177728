import SearchBar from '@components/lib/searchbar/searchbar';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InviteRequestsKeys } from '@gen2/api/invite-requests/hooks';
import { InvitesKeys } from '@gen2/api/invites/hooks';
import { TTemplate } from '@gen2/api/templates/api';
import {
  useSelectTemplateMutation,
  useTemplatesQuery,
} from '@gen2/api/templates/hooks';
import { Loading } from '@gen2/app/invite-listing/invite-listing-item/loading';
import {
  TemplateCardBadge,
  TemplateCardContent,
  TemplateCardDate,
  TemplateCardHeader,
  TemplateCardName,
  TemplateCard,
  EmptyTemplates,
} from '@gen2/app/templates/templates.styled';
import { queryClient } from '@gen2/config';
import { useToast } from '@gen2/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'usehooks-ts';
import { useSendInviteStore } from '../store';
import { TemplateIcon } from './template-icon';
import {
  TemplateContent,
  TemplatePaginationArrows,
  TemplatesHeader,
  TemplatesHeaderActions,
  TemplatesHeaderTop,
  TemplatesMobileClose,
  TemplatesModal,
  TemplateCards,
} from './templates.styled';

export const Templates = () => {
  const theme = useTheme();
  const mediaMatch = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedTemplate, setSelectedTemplate] = useState<TTemplate>();
  const {
    invite,
    isTemplatesOpen,
    setIsTemplatesOpen,
    reset,
    setContextInviteIdForNewInvite,
  } = useSendInviteStore();
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [page, setPage] = useState(1);
  const { data, isLoading } = useTemplatesQuery(
    {
      page,
      per_page: 25,
      'filter[name]': debouncedQuery,
    },
    {
      enabled: isTemplatesOpen,
    },
  );
  const toast = useToast();
  const {
    mutateAsync: selectTemplateMutation,
    isLoading: isInviteRequestsUpdating,
  } = useSelectTemplateMutation();
  const { t } = useTranslation('templates');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleClose = () => {
    setIsTemplatesOpen(false);
  };

  const nextPage = () => {
    if (!data?.templates?.length) return;

    setPage((prev) => prev + 1);
  };

  const prevPage = () => {
    if (page <= 1) return;

    setPage((prev) => prev - 1);
  };

  const onSelect = (template: TTemplate) => {
    if (selectedTemplate?.id === template.id) {
      setSelectedTemplate(undefined);
    } else {
      setSelectedTemplate(template);
    }
  };

  // it's only new if it was created today
  const isStillNew = (createdAt: Date | string) => {
    return dayjs(createdAt).isSame(dayjs(), 'day');
  };

  const onConfirm = async () => {
    if (!selectedTemplate) return;

    handleCloseConfirmation();

    // reset the form
    reset();

    try {
      await selectTemplateMutation({
        templateId: selectedTemplate.id,
        inviteId: invite?.id,
      });

      await queryClient.invalidateQueries([
        InviteRequestsKeys.getInviteRequests,
      ]);
      await queryClient.invalidateQueries([InvitesKeys.getInvite]);

      toast.show({
        text: t('afterChange'),
        variant: 'success',
      });
    } catch (err) {
      toast.show({
        text: t('failedToChange'),
        variant: 'error',
      });
    } finally {
      if (invite?.id) {
        setContextInviteIdForNewInvite(invite.id);
      }

      handleClose();
    }
  };

  const handleOpenConfirmation = () => {
    setIsConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (page !== 1) {
      setPage(1);
    }

    setQuery(e.target.value);
  };

  return (
    <TemplatesModal
      open={isTemplatesOpen}
      onClose={handleClose}
      aria-labelledby="templates"
      aria-describedby="templates-title"
      fullScreen={mediaMatch}
      data-cy="templates-modal"
    >
      {!mediaMatch && (
        <ModalCloseButton
          data-cy="templates-modal-close"
          aria-label="close"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={regular('close')} />
        </ModalCloseButton>
      )}
      <TemplatesHeader>
        <TemplatesHeaderTop>
          <DialogTitle id="templates-title">Templates</DialogTitle>
          {mediaMatch && (
            <TemplatesMobileClose
              data-cy="templates-modal-mobile-close"
              aria-label="close"
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={regular('x')} />
            </TemplatesMobileClose>
          )}
        </TemplatesHeaderTop>
        <TemplatesHeaderActions>
          <SearchBar
            id="search-templates"
            placeholder="Search templates"
            onChange={handleSearch}
            onReset={() => setQuery('')}
            value={query}
            data-cy="search-templates"
          />
          {!mediaMatch && (
            <LoadingButton
              onClick={handleOpenConfirmation}
              color="primary"
              variant="contained"
              disabled={!selectedTemplate}
              data-cy="load-selected-template-btn"
              loading={isInviteRequestsUpdating}
            >
              Load Selected Template
            </LoadingButton>
          )}
        </TemplatesHeaderActions>
      </TemplatesHeader>
      <TemplateContent dividers $loading={isLoading}>
        <TemplatePaginationArrows onClick={prevPage} disabled={isLoading}>
          <FontAwesomeIcon icon={regular('chevron-left')} />
        </TemplatePaginationArrows>
        <TemplatePaginationArrows
          onClick={nextPage}
          $right
          disabled={isLoading}
        >
          <FontAwesomeIcon icon={regular('chevron-right')} />
        </TemplatePaginationArrows>
        {isLoading ? (
          <Loading />
        ) : (
          <TemplateCards>
            {data?.templates?.length ? (
              data.templates.map((template) => (
                <TemplateCard
                  $active={selectedTemplate?.id === template.id}
                  onClick={() => onSelect(template)}
                  key={template.id}
                  data-cy={`template-card-${template.id}`}
                >
                  <TemplateCardHeader>
                    <TemplateCardDate>
                      {dayjs(template.created_at).format('MMM D, YYYY')}
                    </TemplateCardDate>
                  </TemplateCardHeader>
                  <TemplateCardContent>
                    <TemplateIcon />
                    <TemplateCardName>{template.name}</TemplateCardName>
                  </TemplateCardContent>
                  {isStillNew(template.created_at) && (
                    <TemplateCardBadge>
                      <span>New</span>
                    </TemplateCardBadge>
                  )}
                </TemplateCard>
              ))
            ) : (
              <EmptyTemplates>{t('notFound')}</EmptyTemplates>
            )}
          </TemplateCards>
        )}
      </TemplateContent>
      {mediaMatch && (
        <DialogActions>
          <Button
            onClick={handleOpenConfirmation}
            color="primary"
            variant="contained"
            disabled={!selectedTemplate}
            data-cy="load-selected-template-btn"
          >
            Load Selected Template
          </Button>
        </DialogActions>
      )}

      {/* confirmation */}
      <Dialog data-cy="confirmation-modal" open={isConfirmationOpen}>
        <ModalCloseButton aria-label="close" onClick={handleCloseConfirmation}>
          <FontAwesomeIcon icon={regular('close')} />
        </ModalCloseButton>
        <DialogTitle id="confirmation-dialog-title">
          {t('confirm.title')}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="confirmation-dialog-description">
            {t('confirm.desc')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmation}
            color="tertiary"
            variant="outlined"
            data-cy="cancel-change-template-btn"
          >
            Cancel
          </Button>
          <Button
            data-cy="confirm-change-template-btn"
            onClick={onConfirm}
            color="primary"
            variant="contained"
          >
            Yes, Change Template
          </Button>
        </DialogActions>
      </Dialog>
    </TemplatesModal>
  );
};
