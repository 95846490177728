import { useMutation } from '@tanstack/react-query';
import {
  globalLoginEmail,
  loginPassword,
  socialLogin,
  socialLoginExchangeCode,
} from './api';

export enum AuthKeys {
  LoginEmail = 'loginEmail',
  LoginPassword = 'loginPassword',
  SocialLoginExchangeCode = 'socialLoginExchangeCode',
  SocialLogin = 'socialLogin',
}

export const useLoginEmailMutation = () =>
  useMutation({
    mutationKey: [AuthKeys.LoginEmail],
    mutationFn: globalLoginEmail,
  });

export const useLoginPasswordMutation = () =>
  useMutation({
    mutationKey: [AuthKeys.LoginPassword],
    mutationFn: loginPassword,
  });

export const useSocialLoginExchangeCodeMutation = () =>
  useMutation({
    mutationKey: [AuthKeys.SocialLoginExchangeCode],
    mutationFn: socialLoginExchangeCode,
  });

export const useSocialLoginMutation = () =>
  useMutation({
    mutationKey: [AuthKeys.SocialLogin],
    mutationFn: socialLogin,
  });
