import { baseType, colors, weights } from '@components/theme/gen2';
import { Skeleton, styled } from '@mui/material';
import { rem } from 'polished';

interface StyledTagsLineStyleProps {
  isError: boolean;
  isHover: boolean;
}

export const StyledTagsLine = styled('div')<StyledTagsLineStyleProps>(
  ({ theme, isError, isHover }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: rem(10),
    padding: `${rem(10)} ${rem(16)}`,
    background: 'none',
    border: 'none',
    [theme.breakpoints.up('sm')]: {
      padding: `${rem(10)} ${rem(50)}`,
      cursor: isHover ? 'unset' : 'unset',
    },
    color: isError ? colors.danger : colors.grayDark3,
    '&:hover': {
      color: isHover ? colors.secondary : colors.grayDark3,
    },
  }),
);

export const StyledTagsLineLabel = styled('p')<StyledTagsLineStyleProps>(
  ({ isError, isHover }) => ({
    fontWeight: weights.medium,
    fontFamily: baseType,
    fontSize: rem(14),
    color: isError ? colors.danger : colors.grayDark2,
    userSelect: 'none',
    [`${StyledTagsLine}:not([aria-disabled="true"]):hover &`]: {
      color: isHover ? colors.secondary : colors.grayDark2,
    },
  }),
);

export const StyledTagsLineTags = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: rem(6),
});

export const StyledTagsLineError = styled('span')({
  fontWeight: weights.medium,
  fontFamily: baseType,
  fontSize: rem(14),
});

export const StyledTagChipLoader = styled(Skeleton)({
  display: 'inline-flex',
  height: rem(24),
  borderRadius: rem(99),
  color: 'transparent',
  transform: 'none',
});
