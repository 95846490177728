import { baseType, colors, textSizes, weights } from '@components/theme/gen2';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  Typography,
  styled,
} from '@mui/material';
import { rem } from 'polished';

interface SubmitButtonProps {
  isLoading?: boolean;
}

export const StyledDialog = styled(Dialog)({
  maxHeight: 'unset',
});

export const StyledDialogContent = styled(DialogContent)({
  maxWidth: rem(800),
  overflow: 'hidden',
  gap: rem(30),
  padding: rem(30),
  paddingTop: `${rem(30)} !important`,
});

export const StyledRowBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(3,minmax(0,1fr))',
  gap: rem(30),

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(1,minmax(0,1fr))',
    overflow: 'scroll',
    height: '40vh',
  },
}));

export const StyledColumnBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(20),
  alignItems: 'center',
  textAlign: 'left',

  [theme.breakpoints.down('sm')]: {
    borderBottom: `1px solid ${colors.grayLight1}`,
    paddingBottom: rem(20),

    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0,
    },
  },
}));

export const StyledDescriptionText = styled('p')({
  fontSize: rem(textSizes.base),
  fontWeight: weights.normal,
  fontFamily: baseType,
  color: colors.grayDark3,
  lineHeight: 'normal',
});

export const StyledQrText = styled('p')({
  fontSize: rem(textSizes.sm),
  fontWeight: weights.normal,
  fontFamily: baseType,
  color: colors.grayDark3,
  lineHeight: 'normal',
  '> a': {
    fontWeight: weights.bold,
  },
});

export const StyledDialogTitle = styled(DialogTitle)({
  paddingTop: rem(20),
});

export const Title = styled(Typography)({
  fontSize: rem(20),
  fontWeight: weights.semiBold,
});

export const StyledDialogActions = styled(DialogActions)({
  padding: rem(20),
});

export const StyledCancelButton = styled(Button)({
  // color: colors.grayDark3,
  // backgroundColor: colors.grayLight1,
  // ':disabled': {
  //   backgroundColor: colors.grayLight1,
  // },
});

export const StyledEnableButton = styled(Button)<SubmitButtonProps>(
  ({ isLoading }) => ({
    // color: colors.grayDark3,
    // backgroundColor: colors.primary,
    width: isLoading ? rem(156) : 'auto',
  }),
);

export const StyledBackupButton = styled(Button)<SubmitButtonProps>(
  ({ isLoading }) => ({
    // color: colors.white,
    // backgroundColor: colors.info,
    width: isLoading ? rem(156) : 'auto',
    // ':hover': {
    //   backgroundColor: colors.secondaryDark1,
    // },
  }),
);

export const MfaImage = styled('img')({});

export const StyledFormControl = styled(FormControl)({
  margin: 0,
  width: '100%',
});

export const StyledInput = styled(Input)({
  margin: 0,
  width: '100%',
});
