import { colors } from '@components/theme/gen2';
import { Box, keyframes, styled } from '@mui/material';

export const mulShdSpin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledLoader = styled('span')`
  display: block;
  font-size: 10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  animation: ${mulShdSpin} 1.1s infinite linear;
  transform: translateZ(0);
  background: url('/assets/spinner.svg') no-repeat center center;
`;

export const StyledWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100vh',
  backgroundColor: colors.grayLight2,
});

export const LoadingBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});

export const LoadingTemplateBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});
