import { Box, SxProps, Theme } from '@mui/material';
import { StyledLoader } from './loader.styles';

export const Loader = (props: SxProps<Theme>) => {
  const hasProps = Object.keys(props as object).length > 0;

  return (
    <Box
      data-testid="loader"
      sx={
        hasProps
          ? props
          : {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: 5,
          }
      }
    >
      <StyledLoader />
    </Box>
  );
};
