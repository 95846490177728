import { colors, textSizes, weights } from '@components/theme/gen2';
import {
  Box,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { rem } from 'polished';

export const StyledAvatarTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(({ theme }) => ({
  '& > div': {
    width: 'fit-content',
    maxWidth: rem(600),
    overflow: 'hidden',
    whitespace: 'wrap',

    [theme.breakpoints.down('md')]: {
      maxWidth: rem(300),
    },
  },

  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.white,
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
    color: colors.black,
    fontSize: textSizes.base,
  },
}));

export const StyledAvatar = styled(IconButton)({
  border: `1px solid ${colors.gray}`,
  padding: 0,
  fontSize: rem(16),
  fontWeight: weights.semiBold,
  width: rem(40),
  height: rem(40),
});

export const StyledTooltipContainer = styled(Box)({
  padding: rem(10),
  width: 'fit-content',
  alignItems: 'center',
  display: 'flex',
  gap: rem(20),
  alignContent: 'center',
  alignSelf: 'center',
  borderRadius: rem(20),
});

export const StyledTooltipDetails = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledTooltipName = styled(Box)({
  fontSize: textSizes.lg,
  fontWeight: weights.semiBold,
});

export const StyledTooltipEmail = styled(Box)({
  fontSize: textSizes.sm,
});
