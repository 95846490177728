import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputBaseProps } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { StyledInput, Wrapper } from './searchbar.styles';

export type SearchBarProps = {
  id: string;
  placeholder?: string;
  value: string;
  isVariant?: boolean;
  mode?: 'dark' | 'light';
  onReset: () => void;
  [key: string]: unknown;
} & InputBaseProps;

const SearchBar: FC<SearchBarProps> = ({
  id,
  placeholder = 'Search',
  value,
  isVariant = false, // Is variant on mobile device
  mode = 'dark',
  onReset,
  ...rest
}: SearchBarProps) => {
  const [open, setOpen] = useState<boolean>(isVariant);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isVariant) {
      setOpen(false);
    }
  }, [isVariant]);

  const changeInputSize = () => {
    if (isVariant) {
      setOpen(true);
      inputRef?.current?.focus();
    }
    inputRef?.current?.focus();
  };

  const handleClickAway = () => {
    if (open && !value?.length && isVariant) {
      setOpen(false);
    }
  };

  useOnClickOutside(inputRef, handleClickAway);

  return (
    <Wrapper isVariant={isVariant} open={open} onClick={changeInputSize}>
      <StyledInput
        {...rest}
        id={id}
        color="info"
        inputRef={inputRef}
        placeholder={placeholder}
        value={value}
        fullWidth
        size="small"
        mode={mode}
        startAdornment={<FontAwesomeIcon icon={regular('search')} />}
        endAdornment={
          value.length ? (
            <FontAwesomeIcon onClick={onReset} icon={solid('times-circle')} />
          ) : null
        }
      ></StyledInput>
    </Wrapper>
  );
};

export default SearchBar;
