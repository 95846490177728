export const namespaces = [
  'sendInvite',
  'common',
  'validation',
  'page',
  'page2',
  'inviteListing',
  'userSettings',
  'manageInvite',
  'profile',
  'templates',
  'userListing',
  'integrations',
  'indicators',
  'customDomain',
  'socialLogin',
  'followUp',
];
