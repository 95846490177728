import { config } from '@gen2/config';
import { AxiosPromise } from 'axios';
import axios from '../axios.request';

export interface ISignupForm {
  email?: string;
  password?: string;
  password_confirmation?: string;
  terms_and_conditions?: boolean;
  region?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const signup = (form: ISignupForm): AxiosPromise<unknown | any> => {
  return axios.request({
    url: `${config.apiVersion.v1}/signup`,
    data: { ...form, region: form.region?.toUpperCase() },
    method: 'post',
    headers: {
      "Accept": "application/json",
      "Authorization": '',
    },
  });
}
