import { Player } from '@lottiefiles/react-lottie-player';
import { LoadingBox } from './invite-listing-item.styled';

export const Loading = () => {
  return (
    <LoadingBox>
      <Player
        autoplay
        loop
        src="/assets/initializing.json"
        style={{ height: '34px', width: '38px' }}
      ></Player>
    </LoadingBox>
  );
};
