import { create } from 'zustand';

export interface IChangePasswordStore {
  isSuccessShow: boolean;
  setIsSuccessShow: (show: boolean) => void;
  setIsSuccessShowMessage: (message: string) => void;
  message: string;
}

export const useChangePasswordStore = create<IChangePasswordStore>()((set) => ({
    isSuccessShow: false,
    message: '',
    setIsSuccessShow: (value) => set({ isSuccessShow: value }),
    setIsSuccessShowMessage: (value) => set({ message: value })
}));
  