import { useAuth } from '@gen2/hooks';
import { useEffect } from 'react';
import { StyledLogo, StyledWrapper } from './Login.styled';

const OauthCallbackHandler = () => {
  const { getToken } = useAuth();
  const storage = window.sessionStorage;

  useEffect(() => {
    // Just in case it was not removed from {social}-callback.tsx
    storage.removeItem('social-callback-call');

    getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledWrapper>
      <StyledLogo />
    </StyledWrapper>
  );
};

export default OauthCallbackHandler;
