import { IAuthCodeExchangeVerifier } from "./IAuthCodeExchangeVerifier";

export class AuthCodeExchangeVerifier implements IAuthCodeExchangeVerifier {
  parseState(state: string): string {
    return state;
  }

  parseScope(scope: string | null): string {
    return scope as string;
  }
}
