import {
  baseType,
  colors,
  headingSizes,
  textSizes,
  weights,
} from '@components/theme/gen2';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, styled } from '@mui/material';
import { rem } from 'polished';

interface SubmitButtonProps {
  isloading?: boolean | string;
}

export const stringToBoolean = (value: boolean | string | undefined) => {
  if (typeof value === 'string' && value.toUpperCase() === 'TRUE') {
    return true;
  }
  return false;
};

export const SecuritySettingsContainerStyledBox = styled(Box)(({ theme }) => ({
  width: '30%',
  maxWidth: rem(400),

  [theme.breakpoints.down('md')]: {
    width: '40%',
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const SecurityBlockStyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(35),
  backgroundColor: 'white',
  padding: rem(40),
  borderRadius: rem(10),
  boxShadow: `0px ${rem(2)} ${rem(4)} ${rem(2)} rgba(0, 0, 0, 0.10)`,
  alignItems: 'flex-start',

  [theme.breakpoints.down('md')]: {
    padding: rem(30),
  },
}));

export const SettingsStyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: rem(15),
  alignSelf: 'stretch',
});

export const MfaStyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: rem(5),

  [theme.breakpoints.down('sm')]: {
    gap: rem(10),
  },
}));

export const Title = styled('div')({
  fontSize: rem(headingSizes.h6),
  fontWeight: weights.semiBold,
  fontFamily: baseType,
  color: colors.grayDark3,
  lineHeight: 'normal',
});

export const SubTitle = styled('p')({
  fontSize: rem(textSizes.lg),
  fontWeight: weights.normal,
  fontFamily: baseType,
  color: colors.grayDark3,
  lineHeight: 'normal',
});

export const StyledButton = styled(LoadingButton)<SubmitButtonProps>(
  ({ isloading }) => ({
    textAlign: 'center',
    lineHeight: 'normal',
    width: stringToBoolean(isloading) ? rem(76) : 'auto',
  }),
);

// export const StyledMfaStatus = styled(Chip)<StyledMfaStatusStyleProps>(
//   ({ enabled }) => ({
//     padding: `${rem(3)} ${rem(8)}`,
//     borderRadius: rem(12),
//     border: '1px solid',
//     borderColor: stringToBoolean(enabled) ? colors.success : colors.danger,
//     color: stringToBoolean(enabled) ? colors.success : colors.danger,
//     backgroundColor: colors.white,
//     textTransform: 'uppercase',
//   }),
// );

export const StyledSubmit = styled(LoadingButton)({
  alignSelf: 'flex-start',
});
