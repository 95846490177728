import { useGlobalRegionsQuery } from '@gen2/api/global-region/hooks';
import {
  ISetPasswordForm,
  TSetPasswordPayload,
  TSetPasswordErrorResponse
} from '@gen2/api/signup-invite/api';
import { useSetPasswordMutation } from '@gen2/api/signup-invite/hooks';
import {Hint} from "@gen2/app/login/Login.styled";
import { useAuth, useRouter } from '@gen2/hooks';
import { ErrorResponse } from '@gen2/utils/formatMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Stack, TextField } from '@mui/material';
import { AxiosResponse } from 'axios';
import { has, mapKeys } from 'lodash';
import {
  KeyboardEventHandler,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {useParams, useSearchParams} from 'react-router-dom';
import * as Yup from 'yup';
import { PasswordHintList } from '../components/hint-list/hint-list';
import AuthLayout from '../layout/AuthLayout/AuthLayout';
import { Title, StyledSubmit } from './set-password.styled';

const schema = Yup.object().shape({
  password: Yup.string()
    .required('password.required')
    .matches(
      /^(?=.*[!@#$%^&*'])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,99}$/,
      'password.pattern',
    ),
  password_confirmation: Yup.string()
    .required('confirmPassword.required')
    .oneOf([Yup.ref('password'), ''], 'confirmPassword.match'),
});

const initialFormValues: ISetPasswordForm = {
  password: '',
  password_confirmation: '',
};

const SetPassword = () => {
  const { t } = useTranslation('validation');
  const [searchParams] = useSearchParams();
  const router = useRouter();
  const { user } = useAuth();
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<ReactNode[] | string[]>([]);
  const { data } = useGlobalRegionsQuery();
  const urlParams = useParams<{ uuid: string, hash: string }>();
  const { mutate } = useSetPasswordMutation();

  const regions = useMemo(() => {
    return data ?? [];
  }, [data]);

  useMemo(() => {
    const region = regions.find((r) => r.name === searchParams.get('region'));
    if (region) {
      localStorage.setItem('regionEndpoint', region.endpoint);
    }
  }, [regions, searchParams]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ISetPasswordForm>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });

  //Navigate to dashboard if the user logged in.
  useEffect(() => {
    if (user) {
      router.navigate('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const password = watch('password');

  const onSubmit: SubmitHandler<ISetPasswordForm> = (form) => {
    setIsLoading(true);

    const payload: TSetPasswordPayload = {
      data: form,
      params: {
        expires: searchParams.get('expires'),
        signature: searchParams.get('signature')
      },
      urlParams: {
        uuid: urlParams.uuid,
        hash: urlParams.hash,
      },
    }

    mutate(payload, {
      onError: (error: unknown) => {
        const axiosError = error as AxiosResponse;
        if (axiosError) {
          const { status, data } = axiosError;
          setIsShow(true);
          if (status === 403) {
            setMessages([t('set_password.invalid_link', { ns: 'page' })]);
            setIsShow(true);
          }
          if (status === 422) {
            const { errors }: TSetPasswordErrorResponse =
              data as ErrorResponse;

            if (
              errors &&
              (has(errors, 'password') || has(errors, 'password_confirmation'))
            ) {
              let errorMessages: string[] = [];

              mapKeys(errors ?? [], (value) => {
                if (value) {
                  errorMessages = [...errorMessages, ...value];
                }
              });

              setMessages(errorMessages);
            }
          }
          if (status >= 500) {
            setMessages([t('error.500', { ns: 'common' })]);
          }
        }
      },
      onSuccess: () => {
        router.navigate('/login?signup_invite_success=1');
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
  };

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  };

  return (
    <AuthLayout isShowAlert={isShow}>
      <header>
        <Title data-cy="title">
          {t('set_password.title', { ns: 'page' })}
        </Title>
      </header>
      <form noValidate>
        <Stack spacing={3} sx={{ flexWrap: 'wrap' }}>
          {isShow && messages.length > 0 && (
            <Alert data-cy="alert" icon={false} severity="error" color="error">
              {messages &&
                messages.map((message, idx) => {
                  return <div key={idx}>{message}</div>;
                })}
            </Alert>
          )}

          <Hint data-cy="link">
            {t('set_password.hint', { ns: 'page' })}{' '}
          </Hint>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="password"
                type="password"
                label={t('password.label')}
                fullWidth
                required
                onKeyDown={handleKeyPress}
                error={Boolean(errors.password)}
                helperText={
                  errors.password?.message && t(errors.password.message)
                }
                inputProps={{
                  'data-cy': 'password-input',
                }}
              />
            )}
          />
          <Controller
            name="password_confirmation"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="confirmPassword"
                type="password"
                fullWidth
                required
                onKeyDown={handleKeyPress}
                label={t('confirmPassword.label')}
                error={Boolean(errors.password_confirmation)}
                helperText={
                  errors.password_confirmation?.message &&
                  t(errors.password_confirmation.message)
                }
                inputProps={{
                  'data-cy': 'confirm-password-input',
                }}
              />
            )}
          />

          {password && <PasswordHintList password={password} />}

          <StyledSubmit
            variant="contained"
            color="primary"
            loading={isLoading}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            data-cy="submit"
          >
            {t('set_password.btn', { ns: 'page' })}
          </StyledSubmit>
        </Stack>
      </form>
    </AuthLayout>
  );
};

export default SetPassword;
