import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import {
  StyledFileUpload,
  StyledFileUploadActions,
  StyledFileUploadCancel,
  StyledFileUploadFileSize,
  StyledFileUploadIconButton,
  StyledFileUploadIconButtonCancel,
  StyledFileUploadIconButtonCancelElement,
  StyledFileUploadInfo,
  StyledFileUploadMore,
} from './file-upload.styles';

/* eslint-disable-next-line */
export interface FileUploadProps {
  title: string;
  progress: number;
  children?: React.ReactNode;
  fileSize: string;
  isUploading?: boolean;
  icon?: React.ReactNode;
  isDeleting?: boolean;
  onDelete?: () => void;
}

export interface FileUploadActionProps {
  children?: React.ReactNode;
  tablet?: boolean;
}

export const FileUploadActions = ({
  children,
  tablet,
}: FileUploadActionProps) => {
  return (
    <StyledFileUploadActions $tablet={tablet}>
      {children}
    </StyledFileUploadActions>
  );
};

const noop = () => {
  return;
};

export const FileUpload = ({
  children,
  title,
  progress,
  fileSize,
  isUploading = false,
  icon,
  isDeleting,
  onDelete,
  ...rest
}: FileUploadProps) => {
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.up('sm'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: () => void) => () => {
    setAnchorEl(null);
    event();
  };

  return (
    <StyledFileUpload
      $progress={progress}
      $uploading={isUploading}
      $tablet={tablet}
      {...rest}
    >
      <StyledFileUploadInfo>
        {icon}
        <span>{title}</span>
        <StyledFileUploadFileSize>{fileSize}</StyledFileUploadFileSize>
      </StyledFileUploadInfo>
      {tablet ? (
        <FileUploadActions tablet={tablet}>
          {isUploading ? (
            <StyledFileUploadIconButtonCancel $progress={progress}>
              <StyledFileUploadIconButtonCancelElement>
                <FontAwesomeIcon icon={regular('close')} />
              </StyledFileUploadIconButtonCancelElement>
            </StyledFileUploadIconButtonCancel>
          ) : (
            <StyledFileUploadIconButton
              title="Delete"
              data-cy="delete"
              disabled={isDeleting}
              onClick={onDelete}
            >
              <FontAwesomeIcon icon={regular('trash-can')} />
            </StyledFileUploadIconButton>
          )}
        </FileUploadActions>
      ) : (
        <StyledFileUploadActions $tablet={tablet}>
          {isUploading ? (
            <StyledFileUploadCancel>
              <FontAwesomeIcon size="xl" icon={regular('close')} />
            </StyledFileUploadCancel>
          ) : (
            <StyledFileUploadMore
              id="file-upload-menu-btn"
              data-testid="file-upload-menu-btn"
              aria-controls={open ? 'file-upload-menu' : ''}
              aria-haspopup="true"
              onClick={handleMenuOpen}
              title="More"
            >
              <FontAwesomeIcon size="xl" icon={regular('ellipsis-vertical')} />
            </StyledFileUploadMore>
          )}
        </StyledFileUploadActions>
      )}
      {!tablet && (
        <Menu
          id="file-upload-menu"
          data-testid="file-upload-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose(noop)}
          MenuListProps={{
            'aria-labelledby': 'file-upload-menu-btn',
          }}
        >
          <MenuItem disabled={isDeleting} onClick={handleMenuClose(onDelete ?? noop)}>
            Delete
          </MenuItem>
        </Menu>
      )}
      {children}
    </StyledFileUpload>
  );
};

export default FileUpload;
