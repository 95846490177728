import { config } from '@gen2/config';
import { AxiosPromise } from 'axios';
import axios from '../axios.request';

export type TIntegrationType = 'google' | 'microsoft';

export interface ConnectIntPayload {
  code: string;
  redirect_uri: string;
  type?: TIntegrationType;
}

export interface ConnectIntResponse {
  message: string;
}

export const connectInt = ({
  type,
  ...payload
}: ConnectIntPayload): AxiosPromise<ConnectIntResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/integrations/${type}/connect`,
    data: payload,
    method: 'post',
  });
};

export interface DisconnectIntPayload {
  type: TIntegrationType;
}

export interface DisconnectIntResponse {
  message: string;
}

export const disconnectInt = (
  payload: DisconnectIntPayload,
): AxiosPromise<DisconnectIntPayload> => {
  return axios.request({
    url: `${config.apiVersion.v1}/integrations/${payload.type}/disconnect`,
    data: payload,
    method: 'post',
  });
};

export interface GetIntFoldersParams {
  folder_id?: string;
}

export interface IntFolder {
  id: string;
  name: string;
  path: string;
}

export interface GetIntFoldersResponse {
  data: {
    folders: IntFolder[];
    parent: IntFolder;
  };
  message: string;
}

export const getStorageFolders = (
  vendor: TIntegrationType,
  params?: GetIntFoldersParams,
): AxiosPromise<GetIntFoldersResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/integrations/${vendor}/folders`,
    params,
    method: 'get',
  });
};

export interface GetIntSettingsResponse {
  data: {
    folder: {
      id: string;
      name: string;
      path: string;
    };
  };
  message: string;
}

export const getStorageSettings = (
  vendor: TIntegrationType,
): AxiosPromise<GetIntSettingsResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/integrations/${vendor}`,
    method: 'get',
  });
};

export interface SaveIntFolderPayload {
  vendor: TIntegrationType;
  directory_id: string;
}

export const saveIntFolder = ({
  vendor,
  ...payload
}: SaveIntFolderPayload): AxiosPromise<DisconnectIntPayload> => {
  return axios.request({
    url: `${config.apiVersion.v1}/integrations/${vendor}`,
    data: payload,
    method: 'post',
  });
};

export interface CreateIntFolderPayload {
  vendor: TIntegrationType;
  folder_name: string;
  parent_folder_id?: string;
}

export interface CreateIntFolderResponse {
  data: {
    folder: IntFolder;
  };
  message: string;
}

export const createIntFolder = ({
  vendor,
  ...payload
}: CreateIntFolderPayload): AxiosPromise<CreateIntFolderResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/integrations/${vendor}/folders`,
    data: payload,
    method: 'post',
  });
};
