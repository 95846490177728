import { GlobalRegion } from '@gen2/api/global-region/hooks';
import { IGlobalLoginEmail } from '@gen2/api/login/api';
import { useLoginEmailMutation } from '@gen2/api/login/hooks';
import { ErrorResponse } from '@gen2/utils/formatMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, TextField } from '@mui/material';
import { AxiosResponse } from 'axios';
import { has } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Hint } from './Login.styled';

const schema = Yup.object().shape({
  email: Yup.string().email('email.invalid').required('email.required'),
});

interface EmailProps {
  active: boolean;
  regions: GlobalRegion[];
  handleUpdate: (email: string) => void;
}

const EmailForm: FC<EmailProps> = ({ active, regions, handleUpdate  }) => {
  const { t } = useTranslation('validation');

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IGlobalLoginEmail>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);
  const { mutate } = useLoginEmailMutation();

  const onSubmit: SubmitHandler<IGlobalLoginEmail> = (form) => {
    setIsLoading(true);
    mutate(form, {
      onError: (error: unknown) => {
        const axiosError = error as AxiosResponse;
        if (axiosError) {
          const { status, data } = axiosError;
          setIsShow(true);
          if (status === 422) {
            const { errors } = data as ErrorResponse;
            if (errors && has(errors, 'email')) {
              setMessages([t('email.invalid')]);
            }
          }
          if (status === 429) {
            setMessages([data.message]);
          }
          if (status >= 500) {
            setMessages([t('error.500', { ns: 'common' })]);
          }
          if (status === 400) {
            setMessages([t(`error.${status}`, { ns: 'common' })]);
          }
        }
      },
      onSuccess: (res) => {
        const region = res.data.data.region;
        const result = regions.find((r) => r.name === region);
        if (result) {
          localStorage.setItem('regionEndpoint', result.endpoint);
        }
        handleUpdate(form.email);
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!active) {
      setIsShow(false);
      setMessages([]);
    }
  }, [active]);

  if (!active) return null;

  return (
    <Form noValidate>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="email"
            fullWidth
            required
            label={t('email.label')}
            error={Boolean(errors.email)}
            helperText={errors.email?.message && t(errors.email.message)}
            inputProps={{
              'data-cy': 'email-input',
            }}
          />
        )}
      />
      {isShow && (
        <Alert data-cy="alert" icon={false} severity="error" color="error">
          {messages}
        </Alert>
      )}
      <Hint data-cy="link">
        {t('login.hint', { ns: 'page' })}{' '}
        <Link to="/sign-up"> {t('login.link', { ns: 'page' })} </Link>
      </Hint>
      <LoadingButton
        variant="contained"
        color="primary"
        loading={isLoading}
        type="submit"
        onClick={handleSubmit(onSubmit)}
        data-cy="next"
      >
        {t('next', { ns: 'common' })}
      </LoadingButton>
    </Form>
  );
};
export default EmailForm;
