import axios from '@gen2/api/axios.request';
import { AxiosPromise } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getGlobalRegions = (countryCode?: string): AxiosPromise<unknown | any> => {
    return axios.request({
        url: '/regions',
        params: { country_code: countryCode ?? '' },
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": '', // In the scenario that a request does not need an accessToken.
            "global": true,
        },
    });
}