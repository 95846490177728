import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IntFolder, TIntegrationType } from '@gen2/api/integrations/api';
import {
  useCreateStorageFolderMutation,
  useStorageFoldersQuery,
} from '@gen2/api/integrations/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { StyledListItem } from '../integrations.styled';
import { useIntegrationsStore } from '../store';
import {
  StyledCreateFolderGroup,
  StyledCreateFolderLabel,
  StyledDialog,
} from './create-folder.styled';
import { schema } from './schema';

export type TCreateFolderModalProps = {
  open: boolean;
  onClose: () => void;
};

export const initialFolder: IntFolder = {
  id: '',
  name: '',
  path: '',
};

export type TCreateFolderForm = {
  folder_name: string;
};

// TODO: Clarify the dropdown to Liv
export const CreateFolderModal = ({
  open,
  onClose,
}: TCreateFolderModalProps) => {
  const {
    selectedFolder,
    currentFolder,
    setCurrentFolder,
    setAlert,
    setFinderOpen,
  } = useIntegrationsStore();
  const { t } = useTranslation('integrations');
  const { vendor } = useParams<{ vendor: TIntegrationType }>();

  const { data, isLoading } = useStorageFoldersQuery(
    vendor || 'google',
    {
      folder_id: currentFolder.id,
    },
    {
      enabled: open,
    },
  );
  const {
    mutateAsync: createStorageFolderMutation,
    isLoading: isCreateLoading,
  } = useCreateStorageFolderMutation();

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TCreateFolderForm>({
    defaultValues: {
      folder_name: '',
    },
    resolver: yupResolver(schema),
  });

  const folderName = register('folder_name');

  const onBack = () => {
    if (!data?.parent) return;

    setCurrentFolder(data.parent);
  };

  const onCreateFolder: SubmitHandler<TCreateFolderForm> = async (data) => {
    try {
      const res = await createStorageFolderMutation({
        vendor: vendor || 'google',
        folder_name: data?.folder_name,
        parent_folder_id: currentFolder.id,
      });

      setCurrentFolder(res.data.data.folder);
      setFinderOpen(true);
      reset();
      onClose();
    } catch (err) {
      setAlert({
        severity: 'error',
        message: 'Failed to create folder',
        open: true,
      });
    }
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="integration-create-folder"
      aria-describedby="integration-create-folder"
      data-cy="integration-create-folder"
    >
      <ModalCloseButton
        data-cy="integration-create-folder-close"
        aria-label="close"
        onClick={onClose}
      >
        <FontAwesomeIcon icon={regular('close')} />
      </ModalCloseButton>
      <DialogTitle id="alert-dialog-title">Create Folder</DialogTitle>
      <DialogContent dividers>
        <div>
          <StyledCreateFolderGroup>
            <StyledCreateFolderLabel>Folder name:</StyledCreateFolderLabel>
            <TextField
              color="info"
              required
              fullWidth
              inputProps={{
                'data-cy': 'FP-input-folder-name',
              }}
              placeholder="Enter folder name"
              {...folderName}
              error={Boolean(errors.folder_name)}
              helperText={
                <span data-cy="folder-name-error">
                  {errors.folder_name?.message && t(errors.folder_name.message)}
                </span>
              }
            />
          </StyledCreateFolderGroup>

          <StyledCreateFolderGroup>
            <StyledCreateFolderLabel>
              Folder location:{' '}
              <span>
                {currentFolder.path === '' ? 'My Drive' : currentFolder.path}
              </span>
            </StyledCreateFolderLabel>
            {selectedFolder?.id && (
              <List dense>
                {data?.parent && (
                  <ListItem disablePadding divider>
                    <ListItemButton onClick={onBack}>
                      <ListItemText
                        primary={<FontAwesomeIcon icon={regular('ellipsis')} />}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {isLoading ? (
                  <ListItem data-cy="loading-item" disablePadding>
                    <ListItemButton>
                      <ListItemText primary="Loading folders..." />
                    </ListItemButton>
                  </ListItem>
                ) : data?.folders?.length ? (
                  data.folders.map((folder, index) => (
                    <StyledListItem
                      data-cy={`folder-${folder.id}`}
                      disablePadding
                      divider={index !== data.folders.length - 1}
                      key={folder.id}
                      onClick={() => setCurrentFolder(folder)}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <FontAwesomeIcon
                            icon={regular('folder-open')}
                            color="currentColor"
                          />
                        </ListItemIcon>
                        <ListItemText primary={folder.name} />
                      </ListItemButton>
                    </StyledListItem>
                  ))
                ) : (
                  <ListItem data-cy="no-folders-found" disablePadding>
                    <ListItemButton disabled>
                      <ListItemText primary="No folders found" />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            )}
          </StyledCreateFolderGroup>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          data-cy="integration-create-folder-cancel"
          color="tertiary"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          data-cy="integration-create-folder-confirm"
          color="primary"
          variant="contained"
          onClick={handleSubmit(onCreateFolder)}
          loading={isCreateLoading}
        >
          Create New Folder
        </LoadingButton>
      </DialogActions>
    </StyledDialog>
  );
};
