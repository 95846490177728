import { IBackupTwoFactorAuthRequest } from '@gen2/api/user-settings/api';
import { useRecoverTwoFactorAuthMutation } from '@gen2/api/user-settings/hooks';
import { useAuth, useToast } from '@gen2/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, TextField } from '@mui/material';
import { AxiosResponse } from 'axios';
import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, StyledBackupSupportBox } from './Login.styled';

interface MfaFormProps {
  active: boolean;
  handlePageChange: (step?: number) => void;
}

const initialFormValues: IBackupTwoFactorAuthRequest = {
  backup_code: '',
};

const schema = Yup.object().shape({
  backup_code: Yup.string()
    .min(18, 'backupCode.min')
    .max(22, 'backupCode.max')
    .required('backupCode.required'),
});

const MfaBackupForm: FC<MfaFormProps> = ({ active, handlePageChange }) => {
  const { t } = useTranslation('validation');
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);
  const { mutate } = useRecoverTwoFactorAuthMutation();
  const toast = useToast();
  const { authCode } = useAuth();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm<IBackupTwoFactorAuthRequest>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IBackupTwoFactorAuthRequest> = (form) => {
    setIsLoading(true);

    const backup_code = form.backup_code;

    const data = {
      backup_code,
    } as IBackupTwoFactorAuthRequest;

    mutate(data, {
      onSuccess: () => {
        setIsLoading(false);
        toast.show({
          text: t('securitySettings.twoFactorAuth.loginBackupForm.success', {ns: 'userSettings'}),
          variant: 'success',
        });

        authCode();
      },
      onError: (err: unknown) => {
        const {data, status} = err as AxiosResponse;
        setIsLoading(false);
        let message = '';

        if(status === 404) {
          message = data?.message;
        } else {
          message = data?.errors?.backup_code[0];
        }

        setError('backup_code', {
          type: 'manual',
          message,
        });
      },
    });
  };

  useEffect(() => {
    if (!active) {
      setIsShow(false);
      setMessages([]);
      reset();
    }
  }, [active, reset]);

  if (!active) return null;

  return (
    <Form>
      <Controller
        name="backup_code"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="backup_code"
            fullWidth
            required
            label={ t('securitySettings.twoFactorAuth.loginBackupForm.backupCodeInput', {ns: 'userSettings'}) }
            error={Boolean(errors.backup_code)}
            type="text"
            helperText={
              errors.backup_code?.message && t(errors.backup_code.message)
            }
            inputProps={{
              'data-cy': 'backup-code-input',
            }}
          />
        )}
      />

      {isShow && (
        <Alert data-cy="alert" icon={false} severity="error" color="error">
          {messages}
        </Alert>
      )}

      <LoadingButton
        variant="contained"
        color="primary"
        loading={isLoading}
        type="submit"
        onClick={handleSubmit(onSubmit)}
        data-cy="next"
      >
        {t('securitySettings.twoFactorAuth.loginBackupForm.submit', {ns: 'userSettings'})}
      </LoadingButton>

      <StyledBackupSupportBox>
        <span>{t('securitySettings.twoFactorAuth.loginBackupForm.support1', {ns: 'userSettings'})}</span>
        <Link to="https://www.fileinvite.com/contact" target="_blank">
        {t('securitySettings.twoFactorAuth.loginBackupForm.support2', {ns: 'userSettings'})}
        </Link>
      </StyledBackupSupportBox>

      <Link to="#" onClick={() => handlePageChange(2)}>
        {t('login.mfa.back', { ns: 'page' })}
      </Link>
    </Form>
  );
};
export default MfaBackupForm;
