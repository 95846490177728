import { colors, textSizes } from '@components/theme/gen2';
import { Breadcrumbs } from '@mui/material';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${colors.grayDark2};

  :hover {
    color: ${colors.tertiary};
  }
`;

export const StyledSpan = styled.span`
  color: ${colors.gray};
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  &.MuiBreadcrumbs-root {
    font-size: ${rem(textSizes.sm1)};
    color: ${colors.grayDark2};
  }

  .MuiBreadcrumbs-separator {
    font-size: ${rem(textSizes.xs)};
  }
`;
