import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { StyledSubMenuHeading } from '../sidebar.styled';
import {
  StyledContent,
  StyledFormControlLabel,
  StyledHeadLine,
  StyledThreeColumn,
} from './invite-settings.styles';

export const InviteSettings = () => {
  const [cloud, setCloud] = useState('Default Cloud Settings');

  const handleChange = (event: SelectChangeEvent) => {
    setCloud(event.target.value as string);
  };

  return (
    <div>
      <StyledSubMenuHeading>Invite Settings</StyledSubMenuHeading>
      <StyledContent spacing={3}>
        <FormControl fullWidth>
          <InputLabel id="cloud-storage-sync">Cloud Storage Sync</InputLabel>
          <Select
            labelId="cloud"
            id="cloud"
            value={cloud}
            label="Cloud Storage Sync"
            onChange={handleChange}
          >
            <MenuItem value="Default Cloud Settings">
              Default Cloud Settings
            </MenuItem>
            <MenuItem value="AWS">AWS</MenuItem>
            <MenuItem value="GDP">GDP</MenuItem>
            <MenuItem value="ORACLE">ORACLE</MenuItem>
          </Select>
        </FormControl>
        <TextField label="Integration Folder" defaultValue="/" fullWidth />
        <TextField label="Reference ID" fullWidth />
        <StyledHeadLine>Notifications</StyledHeadLine>
        <StyledThreeColumn>
          <p>Almost Due Reminder</p>
          <StyledFormControlLabel control={<Checkbox />} label="Email" />
          <StyledFormControlLabel control={<Checkbox />} label="SMS" />
          <p>Overdue Reminder</p>
          <StyledFormControlLabel control={<Checkbox />} label="Email" />
          <StyledFormControlLabel control={<Checkbox />} label="SMS" />
        </StyledThreeColumn>
        <FormControlLabel
          value="start"
          control={<Switch />}
          label="Password Protected"
          labelPlacement="start"
        />
      </StyledContent>
    </div>
  );
};
