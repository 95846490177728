import queryString from 'query-string';
import { useMemo } from 'react';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';

interface IRouter {
  navigate: NavigateFunction;
  pathname: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: queryString.ParsedQuery<any>;
  location: Location;
}

export const useRouter = (): IRouter => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  return useMemo(() => {
    return {
      navigate,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      location,
    };
  }, [params, navigate, location]);
};

export default useRouter;
