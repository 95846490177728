import { Creator } from '@gen2/types/invite';
import { TUser } from '@gen2/types/user';
import { ReactElement } from 'react';
import { stringAvatar } from '../name';
import { ImageAvatar, TextAvatar } from './avatar.styled';

export const handleAvatar = (user?: TUser | Creator | null) => {
  let result: string | ReactElement;

  if (user?.avatar_url) {
    result = <ImageAvatar imageurl={user?.avatar_url} />;
  } else {
    result = (
      <TextAvatar>{stringAvatar(user?.first_name, user?.last_name)}</TextAvatar>
    );
  }

  return result;
};
