import { useQuery } from '@tanstack/react-query';
import { has } from 'lodash';
import { getGlobalRegions } from '../global-region/api';

export enum Keys {
  queryGlobalRegion = 'getGlobalRegions',
}

export interface GlobalRegion {
  endpoint: string;
  name: string;
}

export const useGlobalRegionsQuery = (countryCode?: string) =>
  useQuery({
    queryKey: [Keys.queryGlobalRegion, countryCode],
    queryFn: async () => {
      try {
        const { data } = await getGlobalRegions(countryCode);

        if (data && has(data, 'data.regions')) {
          return data.data.regions as GlobalRegion[];
        }

        return null;
      } catch (error) {
        return null;
      }
    },
  });
