import * as Yup from 'yup';

export const MAX_FOLLOW_UP_REQUESTS = 50;

export const schema = Yup.object().shape({
  name: Yup.string()
    .required('name.required')
    .min(2, 'name.min')
    .max(150, 'name.max'),
});
