import { createAvatarTooltip } from '@components/lib/avatar-tooltip/avatar-tooltip';
import { TContact } from '@gen2/types/contact';
import { handleAvatar } from '../avatar/avatar';

export const contactsToTags = (contacts: TContact[] | null | undefined) => {
  if (!contacts) {
    return [];
  }

  return contacts.map((contact) => ({
    id: contact.id,
    name: `${contact.first_name} ${contact.last_name}`,
    firstName: contact.first_name,
    lastName: contact.last_name,
    email: contact.email,
    portal_url: contact.portal_url,
    tooltip: createAvatarTooltip(
      handleAvatar(contact),
      `${contact?.first_name} ${contact?.last_name}`,
      contact?.email ?? '',
    ),
  }));
};
