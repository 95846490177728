import { colors, textSizes } from '@components/theme/gen2';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Checkbox, FormControl, Typography, styled } from '@mui/material';
import { rem } from 'polished';

export const StyledSubmit = styled(LoadingButton)({
  alignSelf: 'flex-start',
});

export const StyledCheckbox = styled(Checkbox)({
  paddingTop: 0,
  paddingBottom: 0,
  margin: 0,
});

export const Subtitle = styled(Typography)({
  display: 'inline-flex',
  columnGap: rem(4),
});

export const TermContent = styled('label')({
  fontSize: textSizes.base,
});

export const StyledFormControl = styled(FormControl)({
  margin: 0,
  width: '100%',
});

export const Region = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: 5,
});

export const StyledBox = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  '> span': {
    display: 'inline-block',
    marginRight: rem(5),
  },
  '> p': {
    position: 'absolute',
    right: rem(-20),
  },
});

export const StyledTypography = styled(Typography)({
  fontSize: '.8rem',
  color: colors.grayDark2,
});
