import { GlobalRegion } from '@gen2/api/global-region/hooks';
import { ISocialLogin } from '@gen2/api/login/api';
import { useSocialLoginMutation } from '@gen2/api/login/hooks';
import { ISignupForm } from '@gen2/api/signup/api';
import { Region } from '@gen2/app/components/region/region';
import { useAuth } from '@gen2/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog } from '@mui/material';
import { Loader } from '@nx-fe/components';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  CompleteSetup,
  StyledButton,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  SubTitle,
  TermContent,
  Title,
} from './region-modal.styles';

export type TUpgradeModalProps = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  oauthSignInData?: ISocialLogin;
  regions?: GlobalRegion[];
};

const initialFormValues: ISignupForm = {
  region: '',
};

const schema = Yup.object().shape({
  region: Yup.string().required('region.required'),
});

const RegionModal = ({
  open,
  oauthSignInData,
  regions,
}: TUpgradeModalProps) => {
  const { t } = useTranslation('page');
  const { authCode } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { mutate: socialLoginMutation } = useSocialLoginMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ISignupForm>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<ISignupForm> = (form) => {
    setIsLoading(true);

    const region = form.region;

    const data = {
      ...oauthSignInData,
    } as ISocialLogin;

    const result = regions?.find((r) => r.name === region);
    if (result) {
      localStorage.setItem('regionEndpoint', result.endpoint);
    }

    socialLoginMutation(data, {
      onSuccess: () => {
        setIsLoading(false);
        authCode();
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={undefined}
      disableEscapeKeyDown={true}
      aria-labelledby="region-modal-title"
      aria-describedby="region-modal-description"
      data-cy="region-modal"
    >
      <StyledDialogTitle data-cy="title" id="region-modal-title">
        <Title>Complete Setup</Title>
        <SubTitle>Finish setting up your FileInvite account</SubTitle>
      </StyledDialogTitle>

      <StyledDialogContent data-cy="content">
        <Region
          initialFormValues={initialFormValues}
          control={control}
          errors={errors}
          reset={reset}
        />

        <TermContent htmlFor="terms_and_conditions">
          {t('signup.social_login_terms_1', { ns: 'page' })}{' '}
          <CompleteSetup>
            {'"' + t('signup.social_login_terms_2', { ns: 'page' }) + '"'}
          </CompleteSetup>{' '}
          {t('signup.social_login_terms_3', { ns: 'page' })}{' '}
          <a
            target="_blank"
            href="https://www.fileinvite.com/terms"
            rel="noreferrer"
          >
            {t('signup.terms', { ns: 'page' })}
          </a>{' '}
          {t('signup.and', { ns: 'page' })}{' '}
          <a
            target="_blank"
            href="https://www.fileinvite.com/privacy"
            rel="noreferrer"
          >
            {t('signup.policy', { ns: 'page' })}
          </a>
        </TermContent>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton
          onClick={handleSubmit(onSubmit)}
          color="primary"
          variant="contained"
          data-cy="upgrade-btn"
        >
          {isLoading ? (
            <Loader
              props={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          ) : (
            'Complete Setup'
          )}
        </StyledButton>
      </StyledDialogActions>
    </Dialog>
  );
};

export default RegionModal;
