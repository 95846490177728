import { Invite, Request } from '@gen2/types/invite';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { get, has } from 'lodash';
import {
  InvitesListPayload,
  getInvitesList,
  getInvitesItem,
  getInvitesRequests,
  archiveInvite,
  resendInvite,
  ResendPayload,
} from './api';

export enum InviteListingKeys {
  queryInviteList = 'getInviteList',
  queryInviteItem = 'getInviteItem',
  queryInvitesRequests = 'getInvitesRequests',
  queryDownloadFiles = 'getDownloadFiles',
}
export interface InvitesListResponse {
  data: { invites: Invite[] };
  links: {
    first: string | null;
    last: string | null;
    next: string | null;
    prev: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    path: string;
    per_page: number;
    to: number;
  };
}

export const useInviteListQuery = (search: InvitesListPayload) =>
  useInfiniteQuery({
    queryKey: [InviteListingKeys.queryInviteList, search],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getInvitesList({
        ...search,
        page: pageParam,
        per_page: pageParam === 1 ? 25 : 10,
      });
      if (data && has(data, 'data')) {
        const hasMore = Boolean(get(data, 'links.next', null));
        return {
          data: data.data.invites as Invite[],
          next: hasMore,
          next_page: hasMore ? data.meta?.current_page + 1 : null,
        };
      }
      return null;
    },
    getNextPageParam: (lastPage) =>
      lastPage?.next ? lastPage?.next_page : null,
  });

export const useInviteItemQuery = (inviteId: string) =>
  useQuery({
    queryKey: [InviteListingKeys.queryInviteItem, inviteId],
    queryFn: async () => {
      const { data } = await getInvitesItem(inviteId);
      if (data && has(data, 'data') && has(data.data, 'invite')) {
        return data.data.invite as Invite;
      }
      return null;
    },
    enabled: !!inviteId,
  });

export const useInvitesRequestsQuery = (inviteId: string) =>
  useQuery({
    queryKey: [InviteListingKeys.queryInvitesRequests, inviteId],
    queryFn: async () => {
      const { data } = await getInvitesRequests(inviteId);
      if (data && has(data, 'data') && has(data.data, 'requests')) {
        return data.data.requests as Request[];
      }
      return [];
    },
    enabled: !!inviteId,
  });

export const useArchiveInviteMutation = () =>
  useMutation({
    mutationFn: (id: string) => archiveInvite(id),
  });

export const useResendInviteMutation = () =>
  useMutation({
    mutationFn: ({ id, payload }: { id: string; payload: ResendPayload }) =>
      resendInvite(id, payload),
  });
