import { useAuth } from '@gen2/hooks';
import { FC, ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';

type ProtectedRouteProps = { children: ReactNode; };

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();
  const isPrequalComplete =
    user?.organisations?.[0]?.is_pre_qualification_completed;

  // todo: user's permissions
  if (!isAuthenticated && !user) {
    return <Navigate to="/login" replace />;
  }

  if (!isPrequalComplete && location.pathname !== '/dashboard') {
    return <Navigate to="/dashboard" replace />;
  }

  return children ? <> {children}</> : <Outlet />;
};

export default ProtectedRoute;
