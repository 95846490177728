import { colors, weights } from '@components/theme/gen2';
import { Box, Checkbox, IconButton, styled } from '@mui/material';
import { rem } from 'polished';

interface InviteItemProps {
  isActive: boolean;
}

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: 'inherit',
});

export const Toolbar = styled('div')({
  height: rem(123),
  background: colors.white,
  borderBottom: `1px solid ${colors.grayLight1}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: rem(15),
  padding: `${rem(15)}`,
});

export const InviteItem = styled('div')<InviteItemProps>(({ isActive }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: rem(10),
  padding: rem(15),
  borderBottom: `1px solid ${colors.grayLight1}`,
  borderRight: `4px solid transparent`,
  borderTop: `1px solid transparent`,
  borderLeft: `1px solid transparent`,
  justifyContent: 'space-between',
  cursor: 'pointer',
  minWidth: rem(375),

  ...(isActive && {
    backgroundColor: colors.grayLight2,
    borderStyle: 'solid',
    borderColor: colors.grayDark1,
    borderWidth: '1px 4px 1px 1px',
  }),

  ':hover': {
    background: colors.grayLight2,
    borderTop: `1px solid ${colors.grayDark1}`,
    borderRight: `4px solid ${colors.grayDark1}`,
    borderBottom: `1px solid ${colors.grayDark1}`,
    borderLeft: `1px solid ${colors.grayDark1}`,
  },
  '>a': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'none',
    },
  },
}));

export const Subject = styled('div')({
  '> h4': {
    margin: 0,
    fontSize: rem(14),
    fontWeight: weights.semiBold,
    color: colors.grayDark3,
    wordBreak: 'break-word',
  },
  '> p': {
    marginTop: rem(3),
    fontSize: rem(12),
    fontWeight: weights.medium,
    color: colors.grayDark2,
  },
});

export const InvitesBox = styled('div')({
  flex: '1 1 auto',
  maxHeight: 'calc(100vh - 123px - 60px)',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: rem(10),
    backgroundColor: colors.grayLight2,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: rem(5),
    backgroundColor: colors.grayLight1,
  },
});

export const InviteLoadingBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: rem(10),
  width: '100%',
  height: rem(300),
});

export const EmptyMessage = styled('div')({
  padding: rem(20),
  color: colors.grayDark2,
  textAlign: 'center',
  fontWeight: weights.medium,
});

export const StyledCheckbox = styled(Checkbox)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  alignSelf: 'center',
  padding: 0,
});

export const StyledIconButton = styled(IconButton)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  padding: 0,
  flexGrow: 0,
});

export const IndicatorBox = styled(Box)({
  display: 'flex',
});
