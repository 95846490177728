import * as Yup from 'yup';

export const MIN_MESSAGE_LENGTH = 2;
export const MIN_SUBJECT_LENGTH = 2;
export const MAX_MESSAGE_LENGTH = 1000;
export const MAX_SUBJECT_LENGTH = 100;
export const MAX_CONTACT_LIMIT = 10;
export const MAX_ALMOST_DUE_VALUE = 90; // days

export const saveDraftSchema = Yup.object().shape(
  {
    subject: Yup.string()
      .test('subject', 'subject.required', function (value) {
        const { rawMessage } = this.parent;

        if (!rawMessage && !value) {
          return false;
        }

        return true;
      })
      .test('subject', 'subject.max', function (value) {
        if (value && value.length > MAX_SUBJECT_LENGTH) {
          return false;
        }

        return true;
      })
      .test('subject', 'subject.min', function (value) {
        if (value && value.length < MIN_SUBJECT_LENGTH) {
          return false;
        }

        return true;
      }),
    rawMessage: Yup.string()
      .test('rawMessage', 'message.required', function (value) {
        const { subject } = this.parent;

        if (!subject && !value) {
          return false;
        }

        return true;
      })
      .test('rawMessage', 'message.max', function (value) {
        if (value && value.length > MAX_MESSAGE_LENGTH) {
          return false;
        }

        return true;
      })
      .test('rawMessage', 'message.min', function (value) {
        if (value && value.length < MIN_MESSAGE_LENGTH) {
          return false;
        }

        return true;
      }),
  },
  [['subject', 'rawMessage']],
);

export const sendSchema = Yup.object().shape(
  {
    subject: Yup.string()
      .max(MAX_SUBJECT_LENGTH, 'subject.max')
      .min(MIN_SUBJECT_LENGTH, 'subject.min')
      .required('subject.required'),
    rawMessage: saveDraftSchema.fields.rawMessage,
    contacts: Yup.array().min(1, 'contacts.required'),
    due_at: Yup.date().required('due_date.required'),
  },
  //to fix cyclic dependencies issue
  [['subject', 'message']],
);
