import { colors, opacity } from '@components/theme/gen2';
import { Components, Palette, PaletteColor, Theme } from '@mui/material';
import { rem, rgba } from 'polished';

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
    disableFocusRipple: true,
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const currentColor: PaletteColor = theme.palette[
        ownerState.color as keyof Palette
      ] as PaletteColor;

      const isIcon = ownerState.startIcon || ownerState.endIcon;

      const styles = {
        borderRadius: rem(999),
        padding:
          ownerState.size === 'medium'
            ? `${rem(8)} ${rem(24)}`
            : `${rem(4)} ${rem(8)}`,
        lineHeight: ownerState.size === 'medium' ? rem(20) : rem(19),
        fontSize: ownerState.size === 'medium' ? rem(14) : rem(12),
        minHeight: ownerState.size === 'medium' ? rem(36) : rem(28),
        // if is medium with icon, then min-width is 116, small size with icon is 80,
        // if no icon and medium, then min-width is 96, small is 64
        minWidth: isIcon
          ? ownerState.size === 'small'
            ? rem(80)
            : rem(116)
          : ownerState.size === 'small'
          ? rem(64)
          : rem(96),
      };

      if (ownerState.variant === 'outlined') {
        return {
          ...styles,
          textTransform: 'none',
          color: currentColor.contrastText,
          border: `1px solid ${currentColor.light}`,
          '&.Mui-focusVisible': {
            outline: `2px solid ${colors.primaryLight2}`,
            transition: 'unset',
          },
          '&:hover': {
            backgroundColor: currentColor.dark,
            color: currentColor.darker,
            border: `1px solid ${currentColor.light}`,
          },
          '&.Mui-disabled': {
            color: currentColor.contrastText,
            opacity: opacity._40,
            border: `1px solid ${currentColor.light}`,
          },
        };
      }

      if (ownerState.variant === 'contained') {
        return {
          ...styles,
          textTransform: 'none',
          '&.Mui-focusVisible': {
            outline: `2px solid ${colors.primaryLight2}`,
            transition: 'unset',
          },
          '&:hover': {
            backgroundColor: currentColor.dark,
          },
          '&.Mui-disabled': {
            color: currentColor.contrastText,
            opacity: opacity._40,
          },
        };
      }

      return {
        ...styles,
      };
    },
    startIcon: ({ ownerState }) => ({
      '& .svg-inline--fa': {
        fontSize: ownerState.size === 'small' ? rem(12) : rem(14),
      },
    }),
    endIcon: ({ ownerState }) => ({
      '& .svg-inline--fa': {
        fontSize: ownerState.size === 'small' ? rem(12) : rem(14),
      },
    }),
  },
  variants: [
    {
      props: { size: 'medium' },
      style: () => ({
        '& .MuiButton-startIcon': {
          marginRight: rem(8),
        },
        '& .MuiButton-endIcon': {
          marginLeft: rem(8),
        },
      }),
    },
    {
      props: { size: 'small' },
      style: () => {
        return {
          '& .MuiButton-startIcon': {
            marginRight: rem(4),
          },
          '& .MuiButton-endIcon': {
            marginLeft: rem(4),
          },
        };
      },
    },
    {
      props: { color: 'primary', variant: 'contained' },
      style: () => ({
        '&.Mui-disabled': {
          backgroundColor: colors.primaryDark1,
        },
      }),
    },
    {
      props: { color: 'secondary', variant: 'contained' },
      style: () => ({
        '&.Mui-disabled': {
          backgroundColor: colors.secondaryLight3,
        },
      }),
    },
    {
      props: { color: 'danger', variant: 'contained' },
      style: () => ({
        '&.Mui-disabled': {
          backgroundColor: colors.redLight2,
        },
      }),
    },
    {
      props: { color: 'ghost', variant: 'outlined' },
      style: () => ({
        '&.Mui-disabled': {
          borderColor: colors.grayDark1,
        },
      }),
    },
    {
      props: { color: 'approve', variant: 'contained' },
      style: () => ({
        '&.Mui-disabled': {
          backgroundColor: colors.greenLight3,
        },
      }),
    },
    {
      props: { color: 'tertiary', variant: 'outlined' },
      style: () => ({
          backgroundColor: rgba(colors.white, opacity._70),
      }),
    },
    {
      props: { color: 'ghost', variant: 'outlined' },
      style: () => ({
          backgroundColor: rgba(colors.white, opacity._50),
      }),
    },
  ],
};
