

import { Alert } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import ProfileSettings from './profile-settings/profile-settings';
import { useChangePasswordStore } from './security-settings/change-password-modal/store';
import SecuritySettings from './security-settings/security-settings';
import { ComponentsStyledBox, ContainerStyledBox, MessageBox } from './user-settings.styled';

export type TUserSettingsProps = {
  setIsShow: Dispatch<SetStateAction<boolean>>;
  setMessages: Dispatch<SetStateAction<string[] | ReactNode[]>>;
};

const UserSettings = () => {
  const [messages, setMessages] = useState<ReactNode[] | string[]>([]);
  const [isShow, setIsShow] = useState(false);

  const { isSuccessShow, message, setIsSuccessShow } = useChangePasswordStore();
  const [close, setClose] = useState(false);

  const handleClick = () => {
    setClose(true);
  };

  useEffect(() => {
    return () => {
      setIsSuccessShow(false);
      setIsShow(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContainerStyledBox>
      {isShow && (
        <ComponentsStyledBox>
          <MessageBox>
            <Alert icon={false} severity="error" data-cy="alert">
              {messages &&
                messages.map((message, idx) => {
                  return <div key={idx}>{message}</div>;
                })}
            </Alert>
          </MessageBox>
        </ComponentsStyledBox>
      )}

      {(isSuccessShow && !close) && (
        <ComponentsStyledBox>
          <MessageBox>
            <Alert icon={false} severity="success" data-cy="success" onClose={handleClick}>
              {message}
            </Alert>
          </MessageBox>
        </ComponentsStyledBox>
      )}
      <ComponentsStyledBox>
        <ProfileSettings setIsShow={setIsShow} setMessages={setMessages} />
        <SecuritySettings />
      </ComponentsStyledBox>
    </ContainerStyledBox>
  );
};

export default UserSettings;
