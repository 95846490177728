import { allowedMimeTypes, pdfMimeTypes } from '@nx-fe/components';
import * as Yup from 'yup';

export const MAX_FILE_SIZE_IN_MB = 100 * 1024 * 1024;
export const MAX_FILE_COUNT = 10;

export const fileValidationSchema = Yup.object().shape({
  file: Yup.mixed()
    .test('fileType', 'request.fileUpload.file.type', (value) => {
      const file = value as File;
      if (!file) return true; // Allow empty value
      return allowedMimeTypes.includes(file.type);
    })
    .test('fileSize', 'request.fileUpload.file.size', (value) => {
      const file = value as File;
      if (!file) return true; // Allow empty value
      return file.size <= MAX_FILE_SIZE_IN_MB;
    }),
});

export const formValidationSchema = Yup.object().shape({
  file: Yup.mixed()
    .test('fileType', 'request.liveForm.file.type', (value) => {
      const file = value as File;
      if (!file) return true; // Allow empty value
      return pdfMimeTypes.includes(file.type);
    })
    .test('fileSize', 'request.fileUpload.file.size', (value) => {
      const file = value as File;
      if (!file) return true; // Allow empty value
      return file.size <= MAX_FILE_SIZE_IN_MB;
    }),
});
