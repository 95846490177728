import { colors, textSizes, typography, weights } from '@components/theme/gen2';
import { opacity } from '@components/theme/gen2/opacity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { Box, darken, FormControl, Stack, styled } from '@mui/material';
import { rem } from 'polished';

interface TMessageContentsStyleProps {
  hasMessages: boolean;
}

type AccentColorStyleProps = {
  accentcolor?: string | undefined;
  isenabled?: boolean | string | undefined;
} & LoadingButtonProps;

export const stringToBoolean = (value: boolean | string | undefined) => {
  if (typeof value === 'string' && value.toUpperCase() === 'TRUE') {
    return true;
  }
  return false;
};
export const MessageBox = styled('div')<TMessageContentsStyleProps>(
  ({ hasMessages }) => ({
    padding: hasMessages ? `0 ${rem(30)} ${rem(30)} ${rem(30)}` : rem(30),
    display: 'flex',
    flexDirection: 'column-reverse',
    overflowY: 'auto',
    height: hasMessages ? 'inherit' : '100%',
    overscrollBehavior: 'contain',
    backgroundColor: colors.grayLight3,
    '&::-webkit-scrollbar': {
      width: rem(10),
      backgroundColor: colors.grayLight2,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: rem(5),
      backgroundColor: colors.grayLight1,
    },
  }),
);

export const MessageLoading = styled('div')({
  textAlign: 'center',
  fontSize: rem(textSizes.base),
});

export const EndMessage = styled('p')({
  textAlign: 'center',
  fontWeight: weights.bold,
});

export const EmptyMessageContainer = styled('div')({
  textAlign: 'center',
  fontSize: rem(textSizes.lg),
  padding: rem(30),
  backgroundColor: colors.grayLight2,
  borderRadius: rem(5),
});

export const EmptyMessage = styled('div')({
  padding: rem(20),
  background: colors.white,
  borderRadius: rem(20),
  boxShadow: '3px 3px 12px -10px black',
});

export const StyledSubmitMessage = styled(LoadingButton)<AccentColorStyleProps>(
  ({ accentcolor, isenabled }) => ({
    padding: `${rem(10)} ${rem(20)}`,
    borderRadius: rem(20),
    display: 'flex',
    alignSelf: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    backgroundColor:
      accentcolor && stringToBoolean(isenabled)
        ? accentcolor
        : colors.primary.toString(),
    ':disabled': {
      opacity: opacity._50,
      backgroundColor:
        accentcolor && stringToBoolean(isenabled)
          ? accentcolor
          : colors.primary,
    },
    '&:hover': {
      backgroundColor:
        accentcolor && stringToBoolean(isenabled)
          ? darken(accentcolor, 0.15)
          : darken(colors.primary, opacity._15),
    },
  }),
);

export const StyledMessageBoxContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  '> span': {
    display: 'inline-block',
    marginRight: rem(5),
  },
  '> p': {
    position: 'absolute',
    right: rem(-20),
  },
  width: '100%',
});

export const StyledMessageFormControl = styled(FormControl)({
  margin: 0,
  minWidth: 203,
  width: 'inherit',
});

export const MessageCounter = styled('span')({
  color: colors.grayDark2,
  fontFamily: typography.fontFamily,
  fontSize: rem(textSizes.sm),
  fontStyle: 'normal',
  fontWeight: weights.normal,
  lineHeight: 'normal',
});

export const MessageCounterError = styled('span')({
  color: colors.danger,
  fontFamily: typography.fontFamily,
  fontSize: rem(textSizes.sm),
  fontStyle: 'normal',
  fontWeight: weights.normal,
  lineHeight: 'normal',
});

export const MessageCompose = styled('div')({
  padding: rem(30),
  backgroundColor: colors.white,
  boxShadow: '5px -5px 20px 0px rgba(0, 0, 0, 0.05)',
  border: `1px solid ${colors.grayLight1}`,
});

export const MessageDetailsContainer = styled('div')({
  display: 'flex',
  marginTop: rem(5),
  justifyContent: 'space-between',
});

export const StyledStack = styled(Stack)({
  flexWrap: 'wrap',
});

export const StyledMessageBox = styled(Box)({
  width: '100%',
});

export const SubmitButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: rem(20),
});

export const MessageButtonIcon = styled(FontAwesomeIcon)({
  marginLeft: rem(8),
});

export const MessageButtonText = styled('span')({
  display: 'inline-block',
});
