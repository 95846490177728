import { Box, Button, styled } from '@mui/material';
import { rem } from 'polished';

export const StyledConfirmButton = styled(Button)({
  minWidth: rem(88),
});

export const StyledContentContainer = styled(Box)({
  maxWidth: rem(550),
});
