import { useSafeQuery } from '@gen2/hooks';
import { useMutation, UseQueryOptions } from '@tanstack/react-query';
import {
  createInviteRequest,
  deleteInviteRequests,
  getInviteRequests,
  TInviteRequestQueryParams,
  TInviteRequests,
  updateInviteRequest,
} from './api';

export const InviteRequestsKeys = {
  getInviteRequests: 'getInviteRequests',
  updateInviteRequest: 'updateInviteRequest',
};

export const useCreateInviteRequestMutation = () =>
  useMutation({ mutationFn: createInviteRequest });

export const useInviteRequestsQuery = (
  params: TInviteRequestQueryParams,
  options?: UseQueryOptions<TInviteRequests['data']['requests']>,
) => {
  return useSafeQuery<TInviteRequests['data']['requests']>(
    [InviteRequestsKeys.getInviteRequests, Object.values(params).toString()],
    async () => {
      const res = await getInviteRequests(params);

      return res?.data.data.requests;
    },
    options,
  );
};

export const useDeleteInviteRequestMutation = () =>
  useMutation({ mutationFn: deleteInviteRequests });

export const useUpdateInviteRequestMutation = () =>
  useMutation({
    mutationKey: [InviteRequestsKeys.updateInviteRequest],
    mutationFn: updateInviteRequest,
  });
