import { colors } from '@components/theme/gen2';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { rem } from 'polished';

export const TemplatesModal = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: rem(1000),
  },
});

export const TemplatesHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    paddingRight: rem(30),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export const TemplatesHeaderTop = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: rem(20),
  paddingRight: rem(20),

  [theme.breakpoints.up('md')]: {
    marginBottom: 0,
  },

  '.MuiDialogTitle-root': {
    paddingLeft: rem(20),

    [theme.breakpoints.up('md')]: {
      paddingLeft: rem(30),
    },
  },
}));

export const TemplatesHeaderActions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(10),
  paddingLeft: rem(20),
  paddingRight: rem(20),
  paddingBottom: rem(20),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 0,
    gap: rem(20),
    paddingRight: 0,
  },

  button: {
    flexShrink: 0,
  },
}));

export const TemplateCards = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
  gap: rem(10),

  [theme.breakpoints.up('md')]: {
    gap: rem(20),
  },
}));

export const TemplateCard = styled('div')<{ $active: boolean }>(
  ({ $active, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    aspectRatio: '1 / 1',
    backgroundColor: colors.grayLight2,
    borderRadius: rem(10),
    overflow: 'hidden',
    border: `${rem(2)} solid ${colors.grayLight2}`,
    cursor: 'pointer',

    ...($active && {
      border: `${rem(2)} solid ${colors.primary}`,
    }),

    [theme.breakpoints.up('md')]: {
      '&:hover': {
        border: `${rem(2)} solid ${colors.secondary}`,
      },
    },
  }),
);

export const TemplatesMobileClose = styled(IconButton)({
  width: rem(30),
  height: rem(30),
  fontSize: rem(12),
  border: `1px solid ${colors.gray}`,
});

export const TemplateContent = styled(DialogContent)<{ $loading?: boolean }>(
  ({ $loading }) => ({
    minHeight: rem(500),

    ...($loading && {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  }),
);

export const TemplatePaginationArrows = styled(IconButton)<{
  $right?: boolean;
}>(({ $right }) => ({
  position: 'absolute',
  top: '50%',
  left: rem(10),
  transform: 'translateY(-50%)',
  zIndex: 5,
  backgroundColor: colors.white,
  width: rem(40),
  height: rem(40),
  border: `${rem(1)} solid ${colors.gray}`,
  fontSize: rem(16),

  '&:hover': {
    backgroundColor: colors.grayLight2,
  },

  ...($right && {
    left: 'auto',
    right: rem(10),
  }),
}));
