import { AlertColor } from '@mui/material';
import { Toast } from '@nx-fe/components';
import { createContext, useState, useCallback } from 'react';

export type ToastContextValue = {
  open: boolean;
  show: (message: TToastMessage) => void;
  close: () => void;
};

const noop = () => null;

export const ToastContext = createContext<ToastContextValue>({
  open: false,
  show: noop,
  close: noop,
});

export type TToastContextProviderProps = {
  children: React.ReactNode;
};

export type TToastMessage = {
  text: string | React.ReactNode;
  variant: AlertColor;
};

export const ToastContextProvider = ({
  children,
}: TToastContextProviderProps) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<TToastMessage>({
    text: '',
    variant: 'info',
  });

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const show = useCallback(
    (message: TToastMessage) => {
      setMessage(message);
      setOpen(true);
    },
    [setMessage],
  );

  return (
    <ToastContext.Provider value={{ open, close, show }}>
      <>
        <Toast open={open} onClose={close} severity={message.variant}>
          {message.text}
        </Toast>
        {children}
      </>
    </ToastContext.Provider>
  );
};
