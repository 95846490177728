export const disabledElementsForWebViewer = [
  'viewControlsButton',
  'leftPanelButton',
  'menuButton',
  'panToolButton',
  'selectToolButton',
  'searchButton',
  'notesPanel',
  'notesPanelButton',
  'toolbarGroup-Shapes',
  'toolbarGroup-Edit',
  'toolbarGroup-View',
  'toolbarGroup-Annotate',
  'toolbarGroup-FillAndSign',
  'toggleNotesButton',
  'freeHandToolGroupButton',
  'textToolGroupButton',
  'shapeToolGroupButton',
  'eraserToolButton',
  'stickyToolGroupButton',
  'miscToolGroupButton',
  'freeTextToolButton',
  'freeTextToolButton2',
  'freeTextToolButton3',
  'freeTextToolButton4',
  'highlightToolGroupButton',
  'underlineToolButton',
  'squigglyToolButton',
  'strikeoutToolButton',
  'stampToolButton',
  'fileAttachmentToolButton',
  'calloutToolGroupButton',
  'rubberStampToolGroupButton',
  'underlineToolGroupButton',
  'freeTextToolGroupButton',
  'freeHandHighlightToolGroupButton',
  'squigglyToolGroupButton',
  'strikeoutToolGroupButton',
  'markInsertTextGroupButton',
  'markReplaceTextGroupButton',
  'undoButton',
  'redoButton',
  'signatureFieldToolGroupButton',
];
