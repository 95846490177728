import SearchBar from '@components/lib/searchbar/searchbar';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeleteInvitesMutation } from '@gen2/api/invite-delete/hooks';
import {
  InviteListingKeys,
} from '@gen2/api/invite-listing/hooks';
import { ConfirmationModal } from '@gen2/app/components/confirmation-modal/confirmation-modal';
import { useConfirmationModalStore } from '@gen2/app/components/confirmation-modal/hooks/confirmation-modal-store';
import { queryClient } from '@gen2/config';
import { useRouter, useToast } from '@gen2/hooks';
import { Invite } from '@gen2/types/invite';
import { Checkbox, IconButton } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { useInviteListing } from '../hook/useInviteListing';
import { Content } from './content';
import {
  DeleteAllContainer,
  DraftListingToolbar,
  DraftSearchBarContainer,
} from './draft-listing-tab.styled';
import {
  Container,
  EmptyMessage,
  InviteItem,
  InvitesBox,
  StyledCheckbox,
} from './invite-listing-tab.styled';
import { InviteLoading } from './invite-loading';

const DraftListingTab = () => {
  const { t } = useTranslation('inviteListing');
  const router = useRouter();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const { fetchMoreData, invites, hasNext, isLoading, searchState } =
    useInviteListing();

  const [, setSearchCondition] = searchState;

  useEffect(() => {
    setSearchCondition((prev) => ({
      ...prev,
      'filter[subject]': debouncedSearch,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const [selectedInvites, setSelectedInvites] = useState<Invite[]>([]);
  const [currentInvites, setCurrentInvites] = useState<Invite[]>([]);
  const { setIsConfirmationModalOpen, setConfirmationModalProps } =
    useConfirmationModalStore();

  const toast = useToast();
  const { mutate, isLoading: deleteIsLoading } = useDeleteInvitesMutation();
  const isOnlyOneSelected = useMemo(
    () => selectedInvites.length === 1,
    [selectedInvites],
  );

  const dataLength = useMemo(() => {
    return (
      invites?.pages?.reduce((acc, curr) => {
        return acc + (curr?.data?.length || 0);
      }, 0) || 0
    );
  }, [invites]);

  useEffect(() => {
    if (invites) {
      const queriedInvites = invites.pages?.reduce((acc, curr) => {
        return acc.concat(curr?.data || []);
      }, [] as Invite[]);

      setCurrentInvites(queriedInvites);
    }
  }, [invites]);

  const handleSelectInvite = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    invite: Invite,
  ) => {
    if (checked) {
      setSelectedInvites([...selectedInvites, invite]);
    } else {
      setSelectedInvites((prev) =>
        prev.filter((selectedInvite) => selectedInvite.id !== invite.id),
      );
    }
  };

  const handleSelectAllInvites = () => {
    if (!currentInvites) return;

    if (selectedInvites.length === currentInvites.length) {
      setSelectedInvites([]);
    } else {
      setSelectedInvites(currentInvites);
    }
  };

  const handleDeleteInvite = (invite: Invite) => {
    setSelectedInvites([invite]);
    setIsConfirmationModalOpen(true);
  };

  const handleSuccessClose = useCallback(() => {
    const isInviteSelected = (invite: Invite) =>
      selectedInvites.some((selectedInvite) => selectedInvite.id === invite.id);

    setCurrentInvites((prev: Invite[]) =>
      prev.filter((invite) => !isInviteSelected(invite)),
    );

    queryClient.invalidateQueries([InviteListingKeys.queryInviteList]);

    router.navigate(`/draft-listing`);

    setIsConfirmationModalOpen(false);
  }, [router, setIsConfirmationModalOpen, selectedInvites]);

  const isActive = useCallback(
    (invite: Invite) => {
      return router.query['id'] && router.query['id'] === invite.id;
    },
    [router.query],
  );

  const handleDelete = useCallback(() => {
    mutate(
      selectedInvites.map((invite) => invite.id),
      {
        onSuccess: () => {
          toast.show({
            text: isOnlyOneSelected
              ? t('delete.success')
              : t('delete.success_multiple', { count: selectedInvites.length }),
            variant: 'success',
          });
          setSelectedInvites([]);
          handleSuccessClose();
        },
        onError: () => {
          toast.show({
            text: isOnlyOneSelected
              ? t('delete.error')
              : t('delete.error_multiple'),
            variant: 'error',
          });
          setIsConfirmationModalOpen(false);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleSuccessClose,
    mutate,
    selectedInvites,
    setIsConfirmationModalOpen,
    setSelectedInvites,
    isOnlyOneSelected,
    toast,
  ]);

  const inviteDeleteMessage = useMemo(() => {
    if (!isOnlyOneSelected) {
      return t('delete.message_multiple', { count: selectedInvites.length });
    }

    if (selectedInvites[0]?.subject) {
      return t('delete.message', { inviteName: selectedInvites[0]?.subject });
    }

    return t('delete.message_no_subject');
  }, [selectedInvites, isOnlyOneSelected, t]);

  useEffect(() => {
    if (!selectedInvites) return;

    setConfirmationModalProps({
      onClose: () => setIsConfirmationModalOpen(false),
      onSubmit: handleDelete,
      isLoading: deleteIsLoading,
      title: isOnlyOneSelected ? t('delete.title_multiple') : t('delete.title'),
      submitLabel: t('delete.buttonLabel'),
      submitColor: 'danger',
      message: inviteDeleteMessage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deleteIsLoading,
    handleDelete,
    selectedInvites,
    setConfirmationModalProps,
    setIsConfirmationModalOpen,
  ]);

  return (
    <Container data-cy="draft-listing-tab">
      <DraftListingToolbar
        hasdelete={currentInvites.length > 0}
        data-cy="search-toolbar"
      >
        <DraftSearchBarContainer>
          <SearchBar
            id="search-bar"
            value={search}
            placeholder="Search by invite name"
            onChange={(e) => setSearch(e.target.value)}
            onReset={() => setSearch('')}
          />
        </DraftSearchBarContainer>
        {currentInvites.length > 0 && (
          <DeleteAllContainer>
            <Checkbox
              checked={
                selectedInvites.length > 0 &&
                selectedInvites.length === currentInvites.length
              }
              indeterminate={
                selectedInvites.length > 0 &&
                currentInvites?.length !== selectedInvites.length
              }
              onChange={handleSelectAllInvites}
              data-cy="select-all-invites-checkbox"
            />
            <IconButton
              disabled={selectedInvites.length === 0}
              onClick={() => setIsConfirmationModalOpen(true)}
              color="tonalLight"
              size="small"
            >
              <FontAwesomeIcon icon={regular('trash-can')} size="sm" />
            </IconButton>
          </DeleteAllContainer>
        )}
      </DraftListingToolbar>
      <InvitesBox data-cy="invites-box" id="scrollableDiv">
        {isLoading ? (
          <InviteLoading />
        ) : (
          <InfiniteScroll
            dataLength={dataLength}
            next={fetchMoreData}
            hasMore={!!hasNext}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {currentInvites.length > 0 ? (
              currentInvites.map((invite: Invite, key: number) => (
                <InviteItem
                  data-cy="invite-item"
                  key={`${invite.id}-${key}`}
                  isActive={isActive(invite)}
                >
                  <StyledCheckbox
                    checked={selectedInvites.some(
                      (selectedInvite) => selectedInvite.id === invite.id,
                    )}
                    indeterminate={false}
                    onChange={(
                      e: ChangeEvent<HTMLInputElement>,
                      checked: boolean,
                    ) => handleSelectInvite(e, checked, invite)}
                    data-cy={`invite-checkbox-${invite.id}-${key}`}
                  />

                  <Link to={`/draft-listing/${invite.id}`}>
                    <Content
                      id={invite.id}
                      subject={invite.subject}
                      due_at={invite.due_at}
                      contacts={invite.contacts}
                      status={invite.status}
                      indicator={invite.indicator}
                    />
                  </Link>

                  <IconButton
                    color="tonalLight"
                    onClick={() => handleDeleteInvite(invite)}
                    data-cy={`invite-delete-${invite.id}-${key}`}
                    size="small"
                  >
                    <FontAwesomeIcon icon={regular('trash-can')} size="sm" />
                  </IconButton>
                </InviteItem>
              ))
            ) : (
              <EmptyMessage data-cy="empty">
                {
                  search
                    ? t('noResult')
                    : t('emptyMessage', {
                      status: 'Draft',
                      action: 'create',
                    })
                }
              </EmptyMessage>
            )}
          </InfiniteScroll>
        )}
      </InvitesBox>

      <ConfirmationModal />
    </Container>
  );
};

export default DraftListingTab;
