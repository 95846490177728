import { colors } from '@components/theme/gen2';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledLogo = styled.div`
  width: ${rem(132)};
  height: ${rem(28)};
  background-image: url('/assets/logo-sm.svg');

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: ${rem(31)};
    height: ${rem(28)};
    background-image: url('/assets/logo-icon.svg');
  }
`;

export const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colors.grayDark4};
  text-align: center;
  height: var(--sidebar-header-height);
  z-index: var(--sidebar-header-z);

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: grid;
    width: auto;
    background-color: transparent;

    &::before {
      content: '';
      position: absolute;
      width: ${rem(70)};
      height: 1px;
      background-color: ${colors.grayDark3};
      bottom: 0;
      left: 0;
      z-index: 3;
    }
  }
`;

export const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    display: grid;
    grid-template-columns: 19px 1fr 1fr;
    width: ${rem(70)};
    justify-content: flex-start;
    background-color: ${colors.grayDark4};
    transition: width 300ms linear;
    overflow: hidden;
    border-bottom-right-radius: ${rem(30)};
    z-index: 2;

    &::before {
      background-color: ${colors.grayDark4};
      content: '';
      width: ${rem(70 - 19)};
      height: 100%;
      grid-column: 2 / -1;
      grid-row: 1 / -1;
    }

    ${StyledLogo} {
      grid-column: 2 / -1;
      grid-row: 1 / -1;
    }

    &:hover {
      width: ${rem(170)};
      padding-right: ${rem(19)};
      border-bottom-right-radius: ${rem(30)};

      ${StyledLogo} {
        width: ${rem(132)};
        height: ${rem(28)};
        background-image: url('/assets/logo-sm.svg');
      }
    }
  }
`;

export const StyledBackground = styled.div`
  display: none;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: block;
    background-color: ${colors.grayDark4};
    width: ${rem(70)};
    height: 100%;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    z-index: 1;
  }
`;
