import { colors, textSizes, weights } from '@components/theme/gen2';
import { FormControlLabel, FormLabel, RadioGroup, styled } from '@mui/material';
import { rem } from 'polished';

export const StyledReminderPopup = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  columnGap: rem(30),
  borderRadius: rem(10),
  border: `${rem(1)} solid ${colors.grayLight1}`,
  boxShadow: `0 ${rem(4)} ${rem(6)} 0 rgba(0,0,0,0.15)`,
  backgroundColor: colors.white,
  padding: rem(10),
  marginLeft: rem(10),
  marginRight: rem(10),
});

export const StyledFormLabel = styled(FormLabel)({
  color: colors.grayDark3,
  fontSize: rem(textSizes.base),
  fontWeight: weights.medium,
  marginBottom: rem(10),

  '&.Mui-focused': {
    color: colors.grayDark3,
  },
});

export const StyledFormControlLabel = styled(FormControlLabel)({
  marginRight: 0,
  marginLeft: 0,

  '.MuiRadio-root': {
    padding: 0,
    marginRight: rem(8),
  },
});

export const StyledInput = styled('input')({
  display: 'inline-block',
  paddingLeft: rem(8),
  width: rem(35),
  height: rem(32),
  borderRadius: rem(4),
  border: `${rem(1)} solid ${colors.grayLight1}`,

  // remove arrow buttons
  '&[type=number]': {
    '-webkit-appearance': 'textfield',
    '-moz-appearance': 'textfield',
    appearance: 'textfield',
  },

  '::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
});

export const StyledOverdueInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: rem(8),

  '.icon-question-circle': {
    width: rem(20),
    height: rem(20),
  },
});

export const StyledRadioGroup = styled(RadioGroup)({
  rowGap: rem(8),
});
