import axios from '@gen2/api/axios.request';
import { config } from '@gen2/config';
import { ErrorResponse } from '@gen2/utils/formatMessage';
import { AxiosPromise, AxiosResponse } from "axios";
import { TFunction } from 'i18next';
import { has } from 'lodash';

export interface IForgotPasswordForm {
  email: string;
}

export const forgotPassword = (data: IForgotPasswordForm): AxiosPromise<unknown> => axios.request({
  url: `${config.apiVersion.v1}/forgot-password`,
  data,
  method: 'post',
  headers: {
    "Accept": "application/json",
    "Authorization": '',
  },
});

export const handleForgotPasswordError = (
  error: unknown,
  t: TFunction,
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>,
  setMessage: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const axiosError = error as AxiosResponse;
  if (axiosError) {
    const { status, data } = axiosError;
    setIsShow(true);
    if (status === 422) {
      const { errors } = data as ErrorResponse;

      if (errors && has(errors, 'email')) {
        setMessage(
          [t('email.invalid')]
        );
      }
    }
    if (status === 429) {
      setMessage([data.message]);
    }
    if (status >= 500) {
      setMessage([t('error.500', { ns: 'common' })]);
    }
  }
}
