import { baseType, colors, weights } from '@components/theme/gen2';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
  styled,
} from '@mui/material';
import { rem } from 'polished';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  maxHeight: 'unset',
  minWidth: rem(700),

  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
  },
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  maxWidth: rem(620),
  overflow: 'hidden',
  gap: rem(30),
  padding: rem(30),
  paddingTop: `${rem(30)} !important`,

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(1,minmax(0,1fr))',
    overflow: 'auto',
    height: '40vh',
  },
}));

export const StyledRowBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: rem(30),

  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

export const StyledColumnRight = styled(Box)(({ theme }) => ({
  gap: rem(20),
  textAlign: 'left',

  [theme.breakpoints.down('sm')]: {
    display: 'block',
    width: '100%',
    borderBottom: `1px solid ${colors.grayLight1}`,
    paddingBottom: rem(20),

    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0,
    },
  },
}));

export const StyledColumnLeft = styled(Box)(({ theme }) => ({
  flexDirection: 'column',
  gap: rem(20),
  textAlign: 'left',
  maxWidth: rem(240),

  [theme.breakpoints.down('sm')]: {
    display: 'block',
    width: '100%',
    borderBottom: `1px solid ${colors.grayLight1}`,
    paddingBottom: rem(20),
    maxWidth: rem(400),

    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0,
    },
  },
}));

export const StyledDialogTitle = styled(DialogTitle)({
  paddingTop: rem(20),
});

export const Title = styled(Typography)({
  fontWeight: weights.semiBold,
  fontSize: rem(20),
});

export const StyledDialogActions = styled(DialogActions)({
  padding: rem(20),
});

export const StyledCancelButton = styled(Button)({
  // backgroundColor: colors.grayLight1,
  // color: colors.grayDark3,
  // ':disabled': {
  //   backgroundColor: colors.grayLight1,
  // },
});

// export const StyledEnableButton = styled(Button)({
//   color: colors.grayDark3,
//     backgroundColor: colors.primary,
//     width: 'auto',
// }
// );

export const StyledFormGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: rem(20),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const StyledSubmit = styled(Button)({});

export const StyledHintBox = styled(Box)({
  padding: `${rem(6)} ${rem(15)}`,
  backgroundColor: colors.grayLight3,
  fontWeight: weights.medium,
  fontFamily: baseType,
});

export const StyledHintBoxTitle = styled(Box)({
  padding: `${rem(6)} ${rem(15)}`,
  backgroundColor: colors.grayLight3,
  fontWeight: weights.medium,
  fontFamily: baseType,
});

export const StyledHintBoxList = styled(List)({
  border: 0,
});

export const StyledHintBoxListItem = styled(ListItem)({
  backgroundColor: colors.grayLight3,
  fontWeight: weights.normal,
  fontFamily: baseType,
  paddingTop: rem(5),
  paddingBottom: rem(3),
});
