export const opacity = {
  _5: 0.05,
  _10: 0.1,
  _15: 0.15,
  _20: 0.2,
  _30: 0.3,
  _35: 0.35,
  _40: 0.4,
  _50: 0.5,
  _55: 0.55,
  _60: 0.6,
  _70: 0.7,
  _80: 0.8,
  _85: 0.85,
  _90: 0.9,
};
