import {
  colors,
  headingSizes,
  headingType,
  weights,
} from '@components/theme/gen2';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material';
import { rem } from 'polished';

export const Title = styled('div')({
  fontSize: rem(headingSizes.h1),
  fontWeight: weights.semiBold,
  fontFamily: headingType,
  color: colors.grayDark2,
});

export const Subtitle = styled('div')({
  color: colors.grayDark2,
});

export const StyledSubmit = styled(LoadingButton)({
  alignSelf: 'flex-start',
});

export const Email = styled('span')({
  fontWeight: weights.semiBold,
});
