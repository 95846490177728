import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GlobalRegion,
  useGlobalRegionsQuery,
} from '@gen2/api/global-region/hooks';
import { ISignupForm } from '@gen2/api/signup/api';
import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormReset,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  Container,
  StyledBox,
  StyledFormControl,
  StyledTypography,
} from './region.styled';

interface RegionProps {
  initialFormValues: ISignupForm;
  control: Control<ISignupForm>;
  errors: FieldErrors<ISignupForm>;
  reset: UseFormReset<ISignupForm>;
  setRegions?: Dispatch<SetStateAction<GlobalRegion[] | undefined>>;
}

export const Region = ({ control, errors, setRegions }: RegionProps) => {
  const { t } = useTranslation('validation');
  const [searchParams] = useSearchParams();
  const { data } = useGlobalRegionsQuery(
    searchParams.get('country') ?? '',
  );
  const regions = useMemo(() => {
    return data ?? [];
  }, [data]);

  useEffect(() => {
    if(setRegions) setRegions(regions)
  },[regions, setRegions]);

  return (
    <Container>
      <StyledBox>
        <Controller
          name="region"
          control={control}
          render={({ field }) => (
            <StyledFormControl
              required
              color="info"
              error={Boolean(errors['region'])}
            >
              <>
                <InputLabel id="region-label">{t('region.label')}</InputLabel>
                <Select
                  {...field}
                  inputProps={{
                    'data-cy': 'region-select',
                  }}
                  labelId="region-label"
                  label="Region"
                  displayEmpty
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e);
                    const region = regions?.find(
                      (r) => r.name === e.target.value,
                    );
                    if (region) {
                      localStorage.setItem('regionEndpoint', region.endpoint);
                    }
                  }}
                >
                  {regions &&
                    regions.map((opt: GlobalRegion) => (
                      <MenuItem
                        data-cy={opt.name}
                        key={opt.name}
                        value={opt.name}
                      >
                        {opt.name.toUpperCase()}
                      </MenuItem>
                    ))}
                </Select>
              </>
              <FormHelperText>
                {errors['region']?.message &&
                  t(errors['region']?.message as string)}
              </FormHelperText>
            </StyledFormControl>
          )}
        />
        <Tooltip title={t('signup.tooltip', { ns: 'page' })} placement="top">
          <p data-cy="question-circle">
            <FontAwesomeIcon size="lg" icon={solid('question-circle')} />
          </p>
        </Tooltip>
      </StyledBox>
      <StyledTypography>
        {t('signup.region_title', { ns: 'page' })}
      </StyledTypography>
    </Container>
  );
};
