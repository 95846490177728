export const config = {
  baseUrl: {
    local: 'http://localhost:3030', // local proxy server
    // local: 'https://82bea879-f495-48f7-8f86-b7c776c62c41.mock.pstmn.io',  // mock server
    dev: '//api.global.dev.fileinvite.info', // for actual dev env
    preprod: '//api.global.preprod.fileinvite.info/', // for QA preprod
    prod: '//global.fileinvite.app', // for production
  },
  apiVersion: {
    v1: '/2023-02',
  },
  clientId: 2,
  redirectUri: `${globalThis.location.host}/2023-02/callback`,
};
