import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { namespaces } from './i18n.constants';

i18n
  .use(initReactI18next)
  .use(detector)
  .use(backend)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: process.env['NODE_ENV'] === 'development',
    ns: namespaces,
    defaultNS: 'common', // default namespace (needs no prefix on calling t), missing Key will lookup from defaultNS
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default, react already safes from xss
      skipOnVariables: false, // Passing nesting to interpolated
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      crossDomain: false,
    },
  });

export default i18n;
