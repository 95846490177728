import { NavigationClient, NavigationOptions } from "@azure/msal-browser";
import { NavigateFunction } from "react-router-dom";

/**
 * overriding the default function MSAL uses to navigate to other urls in your webpage
 */
export class CustomNavigationClient extends NavigationClient {
    private navigate: NavigateFunction;

    constructor(navigate: NavigateFunction) {
      super();
      this.navigate = navigate;
    }

  override async navigateExternal(url: string, options: NavigationOptions): Promise<boolean> {
    
    const params = new URLSearchParams(url);
    
    if (!params.has('response_type') && !url.includes('/authorize')) {
      return super.navigateExternal(url, options);
    }

    //modified authorization code url to allow the possibility of auth code exchange with no SPA e.g. backend applications
    params.delete('code_challenge_method');
    params.delete('code_challenge');
    params.delete('client-request-id');
    params.set('response_mode', 'query');

    return super.navigateExternal(decodeURIComponent(params.toString()), options);
  }
}
