import axios from '@gen2/api/axios.request';
import { config } from '@gen2/config';
import { TUser } from '@gen2/types/user';
import { AxiosPromise } from 'axios';

export interface IAuthTokenResponse {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
}

export interface IUseMeResponse {
  data: TUser;
  message: string;
}

export const authToken = (
  data: URLSearchParams,
): AxiosPromise<IAuthTokenResponse> =>
  axios.request({
    url: `${config.apiVersion.v1}/pkce`,
    data,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
      Authorization: '', // In the scenario that a request does not need an accessToken.
    },
  });

export const authRefreshToken = (
  data: URLSearchParams,
): AxiosPromise<IAuthTokenResponse> =>
  axios.request({
    url: `${config.apiVersion.v1}/token`,
    data,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
      Authorization: '', // In the scenario that a request does not need an accessToken.
    },
  });

export const getUserMe = () =>
  axios.request({
    url: `${config.apiVersion.v1}/user-me`,
    method: 'get',
  });

export const logoutUser = () =>
  axios.request({
    url: `${config.apiVersion.v1}/logout`,
    method: 'get',
  });
