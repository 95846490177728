import { Link } from 'react-router-dom';
import {
  StyledBackground,
  StyledHeader,
  StyledLogo,
  StyledLogoContainer,
} from './header.styled';

export const PreloadLogo = () => {
  return (
    <div
      style={{
        visibility: 'hidden',
        width: '1px',
        height: '1px',
        overflow: 'hidden',
        position: 'absolute',
        top: '-9999px',
        left: '-9999px',
      }}
    >
      {/* <img src="/images/logo.png" alt="logo" /> */}
      <img src="/assets/logo-icon.svg" alt="logo-large" />
    </div>
  );
};

export const SidebarHeader = () => {
  return (
    <StyledHeader>
      <PreloadLogo />
      <StyledLogoContainer as={Link} to="/invite-listing">
        <StyledLogo />
      </StyledLogoContainer>
      <StyledBackground />
    </StyledHeader>
  );
};
