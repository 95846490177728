import { colors, textSizes } from '@components/theme/gen2';
import { Components, Theme, PaperProps } from '@mui/material';
import { rem } from 'polished';

export const MuiSelect: Components<Theme>['MuiSelect'] = {
  defaultProps: {
    fullWidth: true,
    MenuProps: {
      PaperProps: {
        style: {
          marginTop: rem(10),
        },
      },
    },
  },
  styleOverrides: {
    icon: {
      color: colors.grayDark2,
      fontSize: rem(14),
      '&.MuiSelect-iconOutlined': {
        fontSize: rem(20),
      },
      '&:hover': {
        color: colors.grayDark2,
      },
    },
    select: {
      fontSize: rem(14),
      color: colors.grayDark3,
      padding: `${rem(8.5)} ${rem(14)}`,
      '&.MuiInputBase-inputSizeSmall': {
        padding: `${rem(6)} ${rem(10)}`,
      },
      '&:hover': {
        backgroundColor: colors.grayLight3,
      },
      '&.Mui-disabled': {
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
    },
  },
};

export const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  styleOverrides: {
    root: {
      fontSize: rem(textSizes.base),
      color: colors.grayDark2,
      top: rem(-6),
    },
    shrink: {
      top: `0px`,
    },
    sizeSmall: {
      top: rem(-1),
    },
  },
};

export const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.grayDark1,
      },
      '&:hover:not(.Mui-disabled)': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: colors.grayDark1,
        },
      },
      '&.Mui-focused': {
        '&:hover': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: colors.info,
          },
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
        },
      },
      '&.Mui-error': {
        '&:hover': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: colors.danger,
          },
        },
      },
    },
  },
};

export const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      fontSize: rem(textSizes.base),
      color: colors.grayDark3,
      '&:hover': {
        backgroundColor: colors.secondaryLight4,
        color: colors.secondaryDark2,
        '.MuiListItemIcon-root': {
          color: colors.secondaryDark2,
        },
      },
      '&.Mui-focusVisible': {
        backgroundColor: colors.secondaryLight4,
        color: colors.secondaryDark2,
      },
      '&.Mui-selected': {
        backgroundColor: colors.secondaryLight4,
        color: colors.secondaryDark2,
        '&:hover': {
          backgroundColor: colors.secondaryLight4,
        },
        '&.Mui-selected.Mui-focusVisible': {
          backgroundColor: colors.secondaryLight4,
        },
      },
    },
  },
};

export const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  styleOverrides: {
    root: {
      marginLeft: '0',
      marginRight: '0',
      fontSize: rem(textSizes.base),
    },
  },
};

export const paperStyle = {
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)',
  marginTop: rem(10),
};

export const rounded = {
  borderRadius: '100px',
};

export const paperPropsWithBorder: Partial<PaperProps> = {
  style: {
    ...paperStyle,
    border: `1px solid ${colors.info}`,
  },
};

export const paperPropsaWithoutBorder: Partial<PaperProps> = {
  style: {
    ...paperStyle,
  },
};
