import {
  grayDark1,
  white,
  grayLight1,
} from '@components/theme/gen2/new-colors';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { styled } from '@mui/material';
import { rem } from 'polished';

export const StyledContainer = styled('div')({
  position: 'relative',
  borderRadius: rem(5),
  border: `1px solid ${grayLight1}`,
  backgroundColor: white,
  overflow: 'hidden',

  '&:focus-within': {
    borderColor: grayDark1,
  },
});

export const StyledInputContainer = styled('div')({
  position: 'relative',
});

export const StyledPlaceholder = styled('div')({
  position: 'absolute',
  top: rem(12),
  left: rem(14),
  color: grayDark1,
  pointerEvents: 'none',
  userSelect: 'none',
});

export const StyledContentEditable = styled(ContentEditable)({
  outline: 'none',
  paddingTop: rem(12),
  paddingBottom: rem(12),
  paddingLeft: rem(14),
  paddingRight: rem(14),
  minHeight: rem(100),
});

export const StyledEditorLabel = styled('div')({
  fontSize: rem(14),
  fontWeight: 600,
  marginBottom: rem(10),
});
