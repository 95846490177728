import {
  IVerifyTwoFactorAuthRequest,
  getQrCode,
} from '@gen2/api/user-settings/api';
import {
  TQrCodeResponse,
  useVerifyTwoFactorAuthMutation,
} from '@gen2/api/user-settings/hooks';
import { MfaCodeField } from '@gen2/app/components/mfa-code/mfa-code';
import { useAuth } from '@gen2/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { Loader, ModalCloseButton } from '@nx-fe/components';
import { AxiosResponse } from 'axios';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useChangePasswordStore } from '../change-password-modal/store';
import MfaBackupCodeModal from '../mfa-backup-code-modal/mfa-backup-code-modal';
import {
  MfaImage,
  StyledBackupButton,
  StyledCancelButton,
  StyledColumnBox,
  StyledDescriptionText,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledEnableButton,
  StyledQrText,
  StyledRowBox,
  Title,
} from './mfa-modal.styles';

export type TMfaModalProps = {
  open: boolean;
  onClose: () => void;
};

const initialFormValues: IVerifyTwoFactorAuthRequest = {
  code: '',
};

const schema = Yup.object().shape({
  code: Yup.string().min(4).max(6).required('twoFactorCode.required'),
});

const MfaModal = ({ open, onClose }: TMfaModalProps) => {
  const { t } = useTranslation('userSettings');
  const [qrData, setQrData] = useState<TQrCodeResponse>(null);
  const [qrCodeSvg, setQrCodeSvg] = useState<string>('');
  const [isQrLoading, setIsQrLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showBackupCode, setShowBackupCode] = useState(false);
  const { mutate } = useVerifyTwoFactorAuthMutation();
  const { initialize } = useAuth();
  const { setIsSuccessShow, setIsSuccessShowMessage } =
    useChangePasswordStore();

  useEffect(() => {
    if (qrData) {
      const sanitizeSvg = DOMPurify.sanitize(qrData.data.svg);

      setIsQrLoading(false);
      setQrCodeSvg(sanitizeSvg);
    }
  }, [qrData]);

  useEffect(() => {
    if (open) {
      setIsQrLoading(true);

      getQrCode()
        .then((result) => {
          setQrData(result.data);
        })
        .catch((error) => {
          console.error(error);
          setIsQrLoading(false);
        });
    }
  }, [open]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm<IVerifyTwoFactorAuthRequest>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IVerifyTwoFactorAuthRequest> = (form) => {
    setIsLoading(true);

    const code = form.code;

    const data = {
      code,
    } as IVerifyTwoFactorAuthRequest;

    mutate(data, {
      onSuccess: () => {
        setIsLoading(false);
        onClose();
        initialize();
        setIsSuccessShow(true);
        setIsSuccessShowMessage(t('securitySettings.twoFactorAuth.success'));
      },
      onError: (err: unknown) => {
        const error = err as AxiosResponse;
        setIsLoading(false);

        setError('code', {
          type: 'manual',
          message: error?.data?.errors?.code[0],
        });
      },
    });
  };

  const handleOnClose = () => {
    if (isLoading) {
      return;
    }

    reset();
    onClose();
  };

  return (
    <>
      <MfaBackupCodeModal
        backupCodes={qrData?.data?.backup_codes}
        open={showBackupCode}
        onClose={() => setShowBackupCode(false)}
      />

      <StyledDialog
        open={open}
        onClose={() => handleOnClose()}
        aria-labelledby="mfa-modal-title"
        aria-describedby="mfa-modal-description"
        data-cy="mfa-modal"
        disableEscapeKeyDown={isLoading}
      >
        <ModalCloseButton
          data-cy="close-btn"
          aria-label="close"
          onClick={() => handleOnClose()}
        />

        <StyledDialogTitle data-cy="title" id="mfa-modal-title">
          <Title>{t('securitySettings.twoFactorAuth.modal.title')}</Title>
        </StyledDialogTitle>

        <StyledDialogContent data-cy="content" dividers>
          <StyledRowBox>
            <StyledColumnBox>
              <StyledDescriptionText>
                {t('securitySettings.twoFactorAuth.modal.stepOne')}
              </StyledDescriptionText>
              <MfaImage src="/assets/mfa-sample.png" alt="MFA Sample Image" />
            </StyledColumnBox>
            <StyledColumnBox>
              <StyledDescriptionText>
                {t('securitySettings.twoFactorAuth.modal.stepTwo')}
              </StyledDescriptionText>
              {isQrLoading ? (
                <Loader
                  props={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                />
              ) : (
                <Box dangerouslySetInnerHTML={{ __html: qrCodeSvg }} />
              )}
              <StyledQrText>
                Unable to scan the QR Code? <br /> Click{' '}
                <a href={qrData?.data?.url} target="_blank" rel="noreferrer">
                  HERE
                </a>{' '}
                to get the code.
              </StyledQrText>

              <StyledBackupButton
                onClick={() => setShowBackupCode(true)}
                color="secondary"
                variant="contained"
                data-cy="mfa-backup-code-show-btn"
              >
                {!showBackupCode
                  ? t('securitySettings.twoFactorAuth.modal.showBackupModal')
                  : t('securitySettings.twoFactorAuth.modal.hideBackupModal')}
              </StyledBackupButton>
            </StyledColumnBox>
            <StyledColumnBox>
              <StyledDescriptionText>
                {t('securitySettings.twoFactorAuth.modal.stepThree')}
              </StyledDescriptionText>
              <MfaCodeField
                control={control}
                errors={errors}
                handleSubmit={() => handleSubmit(onSubmit)()}
              />
            </StyledColumnBox>
          </StyledRowBox>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledCancelButton
            onClick={() => handleOnClose()}
            variant="outlined"
            color="tertiary"
            data-cy="mfa-modal-close-btn"
            disabled={isLoading}
          >
            Cancel
          </StyledCancelButton>
          <StyledEnableButton
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
            data-cy="mfa-modal-submit-btn"
            disabled={isLoading}
            isLoading={isLoading}
          >
            {isLoading ? (
              <Loader
                props={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: 'inherit',
                }}
              />
            ) : (
              'Complete Setup'
            )}
          </StyledEnableButton>
        </StyledDialogActions>
      </StyledDialog>
    </>
  );
};

export default MfaModal;
