import { useDeleteFilesMutation } from '@gen2/api/files/hooks';
import { TInviteRequest } from '@gen2/api/invite-requests/api';
import {
  InviteRequestsKeys,
  useCreateInviteRequestMutation,
  useDeleteInviteRequestMutation,
  useInviteRequestsQuery,
} from '@gen2/api/invite-requests/hooks';
import { ConfirmationModal } from '@gen2/app/components/confirmation-modal/confirmation-modal';
import { useConfirmationModalStore } from '@gen2/app/components/confirmation-modal/hooks/confirmation-modal-store';
import { CreateRequest } from '@gen2/app/components/invite-request/create-request/create-request';
import { useFileStore } from '@gen2/app/components/invite-request/hooks/file-store';
import { InviteRequest } from '@gen2/app/components/invite-request/invite-request';
import { queryClient } from '@gen2/config';
import { useToast } from '@gen2/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'usehooks-ts';
import { REQUEST_TITLE } from '../hooks';
import { useSendInviteStore } from '../store';
import {
  StyledContainer,
  StyledRequests,
  StyledRequestsHead,
  StyledTitle,
} from './requests.styled';
import { inviteRequestsSchema, MAX_REQUESTS } from './schema';
import { useRequestsStore } from './store';

export type TInviteRequestsForm = {
  requests: Array<TInviteRequest>;
};

export const defaultRequests: TInviteRequestsForm = {
  requests: [
    {
      id: '0',
      title: 'New Request',
      type: 'file-upload',
      description: '',
      meta: '',
      status: 'draft',
      order: 0,
      files: [],
      pdf_redactions: [],
      created_at: '',
      indicator: 'draft',
    },
  ],
};

export const InviteRequestsList = React.memo(() => {
  const { mutate: createInviteRequestMutation, isLoading: isCreating } =
    useCreateInviteRequestMutation();
  const { mutate: deleteInviteRequestMutation, isLoading: isRequestDeleting } =
    useDeleteInviteRequestMutation();
  const { toDeleteRequest, setToDeleteRequest, setActiveRequest } =
    useRequestsStore();
  const { t } = useTranslation('sendInvite');
  const toast = useToast();
  const { file: selectedFile, setFile: setSelectedFile } = useFileStore();
  const { setIsConfirmationModalOpen, setConfirmationModalProps } =
    useConfirmationModalStore();
  const { mutate: deleteFilesMutation, isLoading: isFileDeleting } =
    useDeleteFilesMutation();
  const { fields: requests } = useFieldArray({
    name: 'requests',
  });

  const siStore = useSendInviteStore();
  const isInviteReady = !!siStore.invite.id;
  const { data, refetch } = useInviteRequestsQuery(
    {
      inviteId: siStore.invite.id,
      'sort[order]': 'asc',
      'sort[column]': 'order',
      per_page: 50,
    },
    {
      enabled: !!siStore.invite.id,
    },
  );

  const { reset } = useFormContext<TInviteRequestsForm>();

  useEffect(() => {
    if (!data || !data.length) return;

    // convert data to form values
    /* if (data.length <= 1) {
      setActiveRequest(data[0]);
    } */

    reset({
      requests: data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siStore.invite.id, data, reset]);

  useUnmount(() => {
    // Cleanup logic here
    setActiveRequest(defaultRequests.requests[0]);
    reset(defaultRequests);
  });

  const requestTitle = useMemo(() => {
    if (requests.length > 0) {
      return toDeleteRequest?.title ?? '';
    }

    return '';
  }, [toDeleteRequest, requests]);

  const onDelete = useCallback(() => {
    if (toDeleteRequest) {
      deleteInviteRequestMutation(
        {
          inviteId: siStore.invite.id,
          requestIds: [toDeleteRequest.id],
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries([
              InviteRequestsKeys.getInviteRequests,
            ]);

            toast.show({
              text: t('request.delete.success'),
              variant: 'success',
            });

            return null;
          },
          onError: () => {
            toast.show({
              text: t('request.delete.error'),
              variant: 'error',
            });
          },
          onSettled: () => {
            setToDeleteRequest(undefined);
            setIsConfirmationModalOpen(false);
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deleteInviteRequestMutation,
    toDeleteRequest,
    setIsConfirmationModalOpen,
    toast,
  ]);

  useEffect(() => {
    if (!requestTitle) return;

    setConfirmationModalProps({
      onClose: () => setIsConfirmationModalOpen(false),
      onSubmit: onDelete,
      isLoading: isRequestDeleting,
      title: t('request.delete.title'),
      submitLabel: t('request.delete.buttonLabel'),
      submitColor: 'danger',
      message: siStore.isEditSendInvite
        ? t('editSendInvite.request.delete.message')
        : requestTitle
          ? t('request.delete.message', {
            requestName: requestTitle,
          })
          : t('request.delete.message_no_subject'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isRequestDeleting,
    onDelete,
    requestTitle,
    setConfirmationModalProps,
    setIsConfirmationModalOpen,
  ]);

  const onCreate = () => {
    if ((requests.length || 0) >= MAX_REQUESTS) {
      siStore.setUpgradeModal({
        isOpen: true,
        title: t('request.upgrade.title') ?? '',
        description: t('request.upgrade.description') ?? '',
      });

      return;
    }

    createInviteRequestMutation(
      {
        inviteId: siStore.invite.id,
        title: REQUEST_TITLE,
        type: 'file-upload',
      },
      {
        onSuccess: async () => {
          // after successful creation, refetch the data
          // and set the last request as active
          const res = await refetch();
          const newRequests = res.data ?? [];
          const lastRequest = newRequests[newRequests.length - 1];

          if (!lastRequest) return null;

          setActiveRequest(lastRequest);

          return null;
        },
      },
    );
  };

  const handleFileDelete = useCallback(() => {
    if (selectedFile) {
      deleteFilesMutation(
        {
          type: 'request',
          typeId: selectedFile.request.id,
          files: [{ id: selectedFile.id }],
        },
        {
          onSuccess: () => {
            siStore.setBannerMessage({
              severity: 'success',
              message: t('request.fileUpload.message.deleted') ?? '',
            });

            queryClient.invalidateQueries([
              InviteRequestsKeys.getInviteRequests,
            ]);

            toast.show({
              text: t('file.delete.success'),
              variant: 'success',
            });
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: (err: any) => {
            siStore.setBannerMessage({
              severity: 'error',
              message: err.data.message,
            });

            toast.show({
              text: t('file.delete.error'),
              variant: 'error',
            });
          },
          onSettled: () => {
            setSelectedFile(undefined);
            setIsConfirmationModalOpen(false);
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile, siStore, toast]);

  useEffect(() => {
    if (!selectedFile) return;

    const isDeleteFileFromFormType = selectedFile.request.type === 'live-form';

    setConfirmationModalProps({
      onClose: () => setIsConfirmationModalOpen(false),
      onSubmit: handleFileDelete,
      isLoading: isFileDeleting,
      title: isDeleteFileFromFormType
        ? t('editSendInvite.file.delete.title')
        : t('file.delete.title'),
      submitLabel: t('file.delete.buttonLabel'),
      submitColor: 'danger',
      message:
        isDeleteFileFromFormType && siStore.isEditSendInvite
          ? t('editSendInvite.file.delete.message')
          : t('file.delete.message', {
            fileName: selectedFile.original_name,
          }),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFileDeleting, selectedFile]);

  if (!requests.length) return null;

  // NOTE:
  // react doesn't have support for the inert attribute
  // so we have to use a ref to set the attribute
  return (
    <StyledContainer
      $opaque={!isInviteReady}
      ref={(node) =>
        node &&
        (!isInviteReady
          ? node.setAttribute('inert', '')
          : node.removeAttribute('inert'))
      }
    >
      <StyledRequests>
        <StyledRequestsHead>
          <StyledTitle>Requests</StyledTitle>
        </StyledRequestsHead>
        <div>
          {requests.map((request, index) => (
            <InviteRequest
              key={request.id}
              id={index}
              hasDelete={requests.length > 1}
              isDeleteDisabled={isRequestDeleting}
            />
          ))}
        </div>
        {!siStore.isEditSendInvite && (
          <CreateRequest onClick={onCreate} disabled={isCreating} />
        )}
      </StyledRequests>
    </StyledContainer>
  );
});

export const InviteRequests = React.memo(() => {
  const methods = useForm<TInviteRequestsForm>({
    defaultValues: defaultRequests,
    resolver: yupResolver(inviteRequestsSchema),
  });

  return (
    <FormProvider {...methods}>
      <InviteRequestsList />
      <ConfirmationModal />
    </FormProvider>
  );
});
