import { TInviteRequest, TInviteRequestFile } from '@gen2/api/invite-requests/api';
import { create } from 'zustand';

interface ISelectedFile extends TInviteRequestFile {
  request: TInviteRequest;
}

export interface File {
  file?: ISelectedFile|null;
  setFile: (file?: ISelectedFile|null) => void;
}

export const useFileStore = create<File>()(
  (set) => ({
    file: null,
    setFile: (file?: ISelectedFile|null) => set({ file })
  }),
);
