import { useSafeQuery } from '@gen2/hooks';
import { TContact } from '@gen2/types/contact';
import { useMutation, UseQueryOptions } from '@tanstack/react-query';
import { addContact, editContact, getContacts, TContactParams } from './api';

export enum ContactKeys {
  getContacts = 'getContacts',
}

export type TContactResponse = {
  data: {
    contacts: TContact[];
  };
} | null;

export const useContactsQuery = (
  params: TContactParams,
  options?: UseQueryOptions<TContactResponse>,
) => {
  return useSafeQuery<TContactResponse>(
    [ContactKeys.getContacts, params['filter[full_name]'] ?? ''],
    async () => {
      try {
        const res = await getContacts(params);

        return res?.data;
      } catch (error) {
        return null;
      }
    },
    options,
  );
};

export const useAddContactMutation = () =>
  useMutation({ mutationFn: addContact });

export const useEditContactMutation = () =>
  useMutation({ mutationFn: editContact });
