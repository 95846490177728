import { colors, weights } from '@components/theme/gen2';
import { styled } from '@mui/material';
import { rem } from 'polished';

export const StyledCreateRequest = styled('button')({
  display: 'flex',
  alignItems: 'center',
  padding: rem(20),
  width: '100%',
  margin: 0,
  gap: rem(20),
  background: colors.white,
  border: `1px solid ${colors.grayLight1}`,
  fontFamily: 'inherit',
  fontSize: rem(14),
  fontWeight: weights.medium,

  '& svg': {
    color: colors.secondary,
    fontSize: rem(17),
  },

  '&:hover': {
    cursor: 'pointer',
    borderColor: colors.gray,
  },

  '&:disabled': {
    cursor: 'not-allowed',
    borderColor: colors.grayLight1,
    color: colors.grayLight1,

    '& svg': {
      color: colors.grayLight1,
    },
  },
});
