import { config } from '@gen2/config';
import { AxiosPromise } from 'axios';
import axios from '../axios.request';

export type TSaveTemplateRequest = {
  inviteId: string;
  name: string;
};

export type TSaveTemplateResponse = {
  message: string;
};

export const saveTemplate = ({
  inviteId: id,
  name,
}: TSaveTemplateRequest): AxiosPromise<TSaveTemplateResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates`,
    data: {
      invite_id: id,
      name,
    },
    method: 'post',
  });
};

export type TTemplate = {
  id: string;
  name: string;
  status: string;
  created_at: string;
};

export type TGetTemplatesResponse = {
  data: {
    templates: TTemplate[];
  };
  meta: {
    to: number;
    per_page: number;
    current_page: number;
    from: number;
    path: string;
  };
  message: string;
};

export type TGetTemplatesRequest = {
  'filter[name]'?: string;
  page?: number;
  per_page?: number;
};

export const getTemplates = (
  params?: TGetTemplatesRequest,
): AxiosPromise<TGetTemplatesResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates`,
    params: {
      'sort[column]': 'created_at',
      'sort[order]': 'desc',
      page: 1,
      per_page: 25, // NOTE: update this when pagination is implemented
      ...params,
    },
    method: 'get',
  });
};

export type TSelectTemplate = {
  inviteId: string;
  templateId: string;
};

export const selectTemplate = ({
  inviteId,
  templateId,
}: TSelectTemplate): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/use-template`,
    data: {
      template_id: templateId,
    },
    method: 'put',
  });
};

export type TRenameTemplate = {
  templateId: string;
  name: string;
};

export const renameTemplate = ({
  templateId,
  name,
}: TRenameTemplate): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates/${templateId}/rename`,
    data: {
      name,
    },
    method: 'put',
  });
};

export type TDeleteTemplates = {
  templateIds: string[];
};

export const deleteTemplates = ({
  templateIds,
}: TDeleteTemplates): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates`,
    data: {
      template_ids: templateIds,
    },
    method: 'delete',
  });
};
