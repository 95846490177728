import { colors, opacity, textSizes } from '@components/theme/gen2';
import { Components, Theme } from '@mui/material';
import { opacify, rem } from 'polished';

export const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
  defaultProps: {
    noOptionsText: 'There are no results',
  },
  styleOverrides: {
    root: {
      '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
        padding: 0,
      },
      '& .MuiOutlinedInput-root': {
        paddingLeft: rem(14),
        paddingRight: rem(14),
        paddingTop: rem(10),
        paddingBottom: rem(10),
      },
      '& .MuiAutocomplete-endAdornment': {
        top: 'unset',
      },
    },
    listbox: {
      paddingTop: 0,
      paddingBottom: 0,
      borderTop: 'unset',
      '& .MuiAutocomplete-option[aria-selected=true]': {
        backgroundColor: `${opacify(
          opacity._50,
          colors.grayLight2,
        )} !important`,
      },
      '& .MuiAutocomplete-option': {
        margin: rem(4),
        fontSize: rem(textSizes.base),
        color: colors.grayDark3,

        '&[aria-selected=true], &:hover': {
          backgroundColor: colors.secondaryLight4,
          color: colors.secondaryDark2,
        },
      },
      // ====================================
      // Scrollbar
      // ====================================
      '&::-webkit-scrollbar': {
        width: rem(10),
        backgroundColor: colors.grayLight2,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: rem(5),
        backgroundColor: colors.gray,
      },
    },
    noOptions: {
      borderRadius: rem(5),
      borderTop: 'unset',
    },
    popper: {
      border: `${rem(1)} solid ${colors.info}`,
      borderRadius: rem(5),
      overflow: 'hidden',
    },
    popupIndicator: {
      transform: 'unset !important',
    },
    clearIndicator: {
      '& ~ .MuiAutocomplete-popupIndicator': {
        display: 'none !important',
      },
    },
  },
};
