import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButtonProps } from '@mui/material';
import { StyledModalCloseButton } from './modal.styles';

export type TModalCloseButtonProps = IconButtonProps;

export const ModalCloseButton = (props: TModalCloseButtonProps) => (
  <StyledModalCloseButton aria-label="close" {...props}>
    <FontAwesomeIcon icon={regular('close')} />
  </StyledModalCloseButton>
);
