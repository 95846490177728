import { colors, weights } from '@components/theme/gen2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSInterpolation } from '@mui/material';
import { styled } from '@mui/system';
import { rem } from 'polished';

export const StyledEditableInput = styled('div')<{
  $hasError?: boolean;
  $required?: boolean;
}>(({ $hasError, $required }) => {
  let styles: CSSInterpolation = {
    display: 'flex',
    alignItems: 'center',
    gap: rem(10),
    fontWeight: weights.medium,
    border: `${rem(1)} solid ${colors.grayLight2}`,
  };

  if ($hasError) {
    styles = {
      ...styles,
      '.editableInput__label': {
        '&::before': {
          content: $required ? '"*"' : '""',
        },
        color: colors.danger,
      },
      '.editableInput__counter': {
        color: colors.danger,
      },
      '&:has(.editableInput__input:focus)': {
        '.editableInput__input': {
          opacity: 1,
        },
        '.editableInput__text': {
          opacity: 0,
        },
        '.editableInput__label::before': {
          content: '""',
        },
        '.editableInput__error': {
          opacity: 0,
        },
      },
      '.editableInput__input': {
        opacity: 0,
      },
      div: {
        color: colors.danger,
      },
    };
  }

  return styles;
});

// convert to a object styled component

export const StyledLabel = styled('label')({
  color: colors.grayDark2,
  [`${StyledEditableInput}:hover &`]: {
    color: colors.secondary,
  },
  [`${StyledEditableInput}:has(input:focus) &`]: {
    color: colors.grayDark2,
  },
});

export const StyledError = styled('span')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});

export const StyledInputContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  // overflow: 'hidden',
  height: rem(45),
  '--right-buffer': rem(24), // icon + space between
  '--font-size': rem(14),
  minWidth: rem(130),

  [theme.breakpoints.up('md')]: {
    '--font-size': rem(16),
  },

  // make scrollbar not mess with the layout
  '&::-webkit-scrollbar': {
    width: 0,
    height: 0,
    background: 'transparent',
  },

  [`${StyledEditableInput}:hover &`]: {
    color: colors.secondary,
  },

  [`${StyledEditableInput}:has(input:focus) &`]: {
    color: 'inherit',
  },
}));

export const StyledInput = styled('input')({
  position: 'absolute',
  top: 0,
  left: 0,
  padding: 0,
  background: 'none',
  border: 'none',
  paddingTop: rem(10),
  paddingBottom: rem(10),
  fontWeight: weights.semiBold,
  fontSize: 'var(--font-size)',
  height: '100%',
  width: `calc(100% - var(--right-buffer))`,
  zIndex: 10,
  '&:focus': {
    outline: 'none',
  },
  '&::placeholder': {
    color: colors.gray,
  },
});

export const StyledPseudoValue = styled('span')({
  whiteSpace: 'break-spaces',
  fontWeight: weights.semiBold,
  fontSize: 'var(--font-size)',
  marginRight: rem(10),
  visibility: 'hidden',
  display: 'inline-block',
  pointerEvents: 'none',
  userSelect: 'none',
  maxWidth: `calc(100% - var(--right-buffer))`,
});

interface PseudoContainerProps {
  hasVal: boolean;
}

export const StyledPseudoContainer = styled('div')<PseudoContainerProps>(
  ({ hasVal }) => ({
    display: 'flex',
    alignItems: 'center',
    width: hasVal ? 'unset' : `calc(100% - var(--right-buffer))`,
    maxWidth: hasVal ? 'unset' : rem(130),
    // justifyContent: !hasVal ? 'flex-end' : 'unset',

    '& > span': {
      minWidth: hasVal ? 'unset' : '100%',
    },
  }),
);

export const StyledIcon = styled(FontAwesomeIcon)({
  color: colors.grayDark1,
  [`${StyledInputContainer}:hover &`]: {
    opacity: 1,
    color: 'inherit',
  },
  [`${StyledInput}:focus + &`]: {
    opacity: 0,
  },
});

export const StyledMaxLength = styled('span')(({ theme }) => ({
  color: colors.grayDark2,
  fontSize: rem(12),
  [theme.breakpoints.up('md')]: {
    fontSize: rem(14),
  },
}));
