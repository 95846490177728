import { colors } from '@components/theme/gen2';
import { styled } from '@mui/material';
import { rem } from 'polished';
import { ToolbarProps } from './archived-listing-tab.styled';

export const DeleteAllContainer = styled('div')(() => {
  return {
    '--draft-item-padding-right': rem(16),
    '--scrollbar-width': rem(10),

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: colors.white,
    width: '100%',
    paddingLeft: rem(16),
    paddingRight: `calc(var(--draft-item-padding-right) + var(--scrollbar-width))`,

    '.MuiCheckbox-root': {
      padding: rem(0),
    },
  };
});

export const DraftSearchBarContainer = styled('div')({
  paddingLeft: rem(16),
  paddingRight: rem(16),
  width: '100%',
});

export const DraftListingToolbar = styled('div')<ToolbarProps>(({ hasdelete }) => ({
  display: 'flex',
  height: hasdelete ? rem(123) : rem(70),
  flexDirection: 'column',
  alignItems: 'center',
  background: colors.white,
  width: '100%',
  paddingTop: rem(12),
  paddingBottom: rem(12),
  gap: rem(12),
  borderBottom: `1px solid ${colors.grayLight1}`,
}));
