import { PaletteOptions } from '@mui/material';
import { rgba } from 'polished';
import * as colors from './new-colors';
import { opacity } from './opacity';

// const { palette: muiPalette } = createTheme();
// const { augmentColor } = muiPalette;

// const createColor = (mainColor: string) =>
//   augmentColor({ color: { main: mainColor } });

export const palette: PaletteOptions = {
  primary: {
    main: colors.primary, // default color
    dark: colors.primaryDark1, // hover color and disabled color
    contrastText: colors.primaryDark3, // text color
  },
  secondary: {
    main: colors.secondaryLight3, // default color and disabled color
    dark: colors.secondaryLight2, // hover color
    contrastText: colors.secondaryDark3, // text color
  },
  tertiary: {
    main: rgba(colors.white, opacity._70), // default color or disabled color
    light: colors.secondary, //  border color
    dark: colors.secondaryLight4, // hover color
    contrastText: colors.secondaryDark2, //text color
    darker: colors.secondaryDark3, // hover text color
  },
  danger: {
    main: colors.redLight2, // default color and disabled color
    dark: colors.redLight1, // hover color
    contrastText: colors.redDark3, // text color
  },
  // only for ghost button -  not applied to the ghost icon button
  // ghost icon button has a sepsarate style file
  ghost: {
    main: rgba(colors.white, opacity._50), // default color or disabled color
    light: colors.grayLight1, //  border color
    dark: colors.grayLight1, // hover color
    contrastText: colors.grayDark3, //text color
    darker: colors.grayDark3, // hover text color
  },
  approve: {
    main: colors.greenLight3, // default color or disabled color
    dark: colors.greenLight2, // hover color
    contrastText: colors.greenDark3, // text color
  },
  blue: {
    main: colors.blueLight3, // bg color for filled chip
    light: colors.blue, // border color for filled chip
    dark: colors.blueDark2, // border color for outlined chip
    contrastText: colors.blueDark2, // text color
  },
  green: {
    main: colors.greenLight3, // bg color for filled chip
    light: colors.green, // border color for filled chip
    dark: colors.greenDark2, // border color for outlined chip
    contrastText: colors.greenDark2, // text color
  },
  orange: {
    main: colors.orangeLight3, // bg color for filled chip
    light: colors.orange, // border color for filled chip
    dark: colors.orangeDark3, // border color for outlined chip
    contrastText: colors.orangeDark3, // text color
  },
  red: {
    main: colors.redLight3, // bg color for filled chip
    light: colors.red, // border color for filled chip
    dark: colors.redDark3, // border color for outlined chip
    contrastText: colors.redDark3, // text color
  },
  mustard: {
    main: rgba(colors.primaryLight3, opacity._60), // bg color for filled chip
    light: colors.primary, // border color for filled chip
    dark: colors.primaryDark2, // border color for outlined chip
    contrastText: colors.primaryDark2, // text color
  },
  navy: {
    main: colors.secondaryDark1, // bg color for filled chip
    light: colors.secondaryDark1, // border color for filled chip
    dark: colors.secondaryDark2, // border color for outlined chip
    contrastText: colors.secondaryLight3, // text color
  },
  contact: {
    main: colors.grayLight2, // bg color for filled chip
    light: colors.grayLight2, // border color for filled chip
    dark: colors.grayDark3, // border color for outlined chip
    contrastText: colors.grayDark3, // text color
  },
  info: {
    main: colors.blue,
  },
  success: {
    main: colors.green,
  },
  warning: {
    main: colors.orange,
  },
  error: {
    main: colors.red, // change the error color to fileinvite red color
  },
};
