import { TInvite } from '@gen2/api/invites/api';
import { EMPTY_EDITOR_STATE } from '@gen2/app/components/rich-text-editor/rich-text-editor';
import { TContact } from '@gen2/types/contact';
import { AlertColor } from '@mui/material';
import { create } from 'zustand';

export type BannerMessage = {
  severity: AlertColor;
  message: string;
};

export type UpgradeModal = {
  isOpen: boolean;
  title?: string;
  description?: string;
};

export type LiveFormType = 'individual' | 'multi';
export interface ISendInviteState {
  isContactListOpen: boolean;
  isContactOpen: boolean;
  isReminderOpen: boolean;
  isTemplatesOpen: boolean;
  isSendFromLoading: boolean;
  contactPopupAnchorEl: HTMLElement | null;
  invite: TInvite;
  activeTabIndex: number;
  bannerMessage: BannerMessage;
  upgradeModal: UpgradeModal;
  contextInviteIdForNewInvite: string;
  mode: 'add' | 'edit';
  currnetContact: TContact | null;
  liveFormType: LiveFormType;
  isEditSendInvite: boolean;
}

export interface ISendInviteStore extends ISendInviteState {
  setIsContactListOpen: (isContactListOpen: boolean) => void;
  setisContactOpen: (isContactOpen: boolean) => void;
  setIsReminderOpen: (isReminderOpen: boolean) => void;
  setIsTemplatesOpen: (isTemplatesOpen: boolean) => void;
  setContactPopupAnchorEl: (contactPopupAnchorEl: HTMLElement | null) => void;
  setInvite: (invite: TInvite) => void;
  setActiveTab: (activeTabIndex: number) => void;
  setBannerMessage: (bannerMessage: BannerMessage) => void;
  setUpgradeModal: (upgradeModal: UpgradeModal) => void;
  setContextInviteIdForNewInvite: (id: string) => void;
  setMode: (mode: 'add' | 'edit') => void;
  setCurrentContact: (contact: TContact | null) => void;
  setLiveFormType: (liveFormType: LiveFormType) => void;
  setIsEditSendInvite: (isEditSendInvite: boolean) => void;
  set: (state: Partial<ISendInviteState>) => void;
  reset: () => void;
}

export const defaultInvite: TInvite = {
  id: '',
  subject: '',
  message: '',
  contacts: [],
  due_at: '',
  created_at: '',
  meta: EMPTY_EDITOR_STATE,
  overdue_reminder: '4',
  almost_due_reminder: '1',
  status: '',
};

export const defaultSendInviteState: ISendInviteState = {
  isContactListOpen: false,
  isContactOpen: false,
  isReminderOpen: false,
  isSendFromLoading: false,
  isTemplatesOpen: false,
  contactPopupAnchorEl: null,
  invite: defaultInvite,
  activeTabIndex: 0,
  bannerMessage: {
    severity: 'info',
    message: '',
  },
  upgradeModal: {
    isOpen: false,
    title: '',
    description: '',
  },
  contextInviteIdForNewInvite: '',
  mode: 'add',
  currnetContact: null,
  liveFormType: 'individual',
  isEditSendInvite: false,
};

export const useSendInviteStore = create<ISendInviteStore>()((set) => ({
  // state
  ...defaultSendInviteState,

  // set methods
  setIsContactListOpen: (isContactListOpen: boolean) =>
    set({ isContactListOpen }),
  setisContactOpen: (isContactOpen: boolean) => set({ isContactOpen }),
  setIsReminderOpen: (isAlmostDueOpen: boolean) =>
    set({ isReminderOpen: isAlmostDueOpen }),
  setIsTemplatesOpen: (isTemplatesOpen: boolean) => set({ isTemplatesOpen }),
  setContactPopupAnchorEl: (contactPopupAnchorEl: HTMLElement | null) =>
    set({ contactPopupAnchorEl }),
  setInvite: (invite: TInvite) => set({ invite }),
  setActiveTab: (activeTabIndex: number) => set({ activeTabIndex }),
  setBannerMessage: (bannerMessage: BannerMessage) =>
    set({
      bannerMessage,
    }),
  setUpgradeModal: (upgradeModal: UpgradeModal) => {
    set((state) => ({
      ...state,
      upgradeModal: {
        ...state.upgradeModal,
        ...upgradeModal,
      },
    }));
  },

  setContextInviteIdForNewInvite: (contextInviteIdForNewInvite: string) =>
    set({ contextInviteIdForNewInvite }),
  setMode: (mode: 'add' | 'edit') => set({ mode }),
  setCurrentContact: (contact: TContact | null) =>
    set({ currnetContact: contact }),
  set: (state: Partial<ISendInviteState>) => set(state),
  setLiveFormType: (liveFormType: LiveFormType) => set({ liveFormType }),
  setIsEditSendInvite: (isEditSendInvite: boolean) => set({ isEditSendInvite }),
  reset: () => {
    return set(defaultSendInviteState);
  },
}));
