import { colors, opacity } from '@components/theme/gen2';
import {
  Components,
  CSSObject,
  Theme,
} from '@mui/material';
import { rgba } from 'polished';

export const MuiLoadingButton: Components<Theme>['MuiLoadingButton'] = {
  styleOverrides: {
    root: () => {

      const styles: CSSObject = {
        '.MuiLoadingButton-loadingIndicator': {
          color: rgba(colors.secondaryDark2, opacity._40),
        },
      };

      return styles;
    },
  },
  variants: [
    {
      props: { variant: 'contained', color: 'primary' },
      style: {
        '.MuiLoadingButton-loadingIndicator': {
          color: rgba(colors.primaryDark3, opacity._40),
        },
      },
    },
  ],
};
