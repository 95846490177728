import { Label, LabelProps } from './indicators.styles';

export interface IndicatorProps extends LabelProps {
  text: string;
  customClass?: string;
}

export const Indicator = ({
  status = 'noProgress',
  text,
  isShowAdornment = true,
  borderRounded = true,
  single = false,
}: IndicatorProps) => {
  return (
    <Label
      status={status}
      isShowAdornment={isShowAdornment}
      borderRounded={borderRounded}
      single={single}
    >
      {text}
    </Label>
  );
};

export default Indicator;
