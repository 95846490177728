import {
  colors,
  headingSizes,
  headingType,
  weights,
} from '@components/theme/gen2';
import { Box, keyframes, styled } from '@mui/material';
import { rem } from 'polished';

export const Form = styled('form')({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  flexGrow: 1,
  flexWrap: 'wrap',
  rowGap: rem(25),
});

export const Title = styled('div')({
  fontSize: rem(headingSizes.h1),
  fontWeight: weights.semiBold,
  fontFamily: headingType,
  color: colors.grayDark2,
});

export const Subtitle = styled('div')({
  color: colors.grayDark2,
});

export const Hint = styled('div')({
  color: colors.grayDark2,
  '> a': {
    textDecoration: 'underline',
  },
});

export const EmailIdentifier = styled('div')({
  color: colors.grayDark2,
  border: `${rem(1)} solid ${colors.gray}`,
  borderRadius: '100px',
  height: rem(35),
  textAlign: 'center',
  lineHeight: rem(35),
  cursor: 'pointer',
});

// export const StyledGoogleBtn = styled(Button)({
//   marginTop: rem(30),
//   color: colors.tertiary,
//   borderColor: colors.grayDark1,
//   ':hover': {
//     color: colors.tertiary,
//     borderColor: colors.gray,
//     backgroundColor: colors.grayLight3,
//   },
// });

export const StyledGoogleIcon = styled('img')({
  maxWidth: rem(20),
});

// export const StyledUseBackupBtn = styled(Button)({
//   color: colors.grayDark2,
//   backgroundColor: 'transparent',
//   width: 'fit-content',
//   padding: 0,
//   minWidth: 'unset',
//   borderRadius: 0,
//   justifyContent: 'start',
//   fontSize: textSizes.sm,
//   textTransform: 'none',
// });

export const StyledBackupSupportBox = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  gap: '5px',
});

export const StyledWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100vh',
  backgroundColor: colors.grayLight2,
});

export const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.35;
  }
  75% {
    opacity: 0.85;
  }
  100% {
    opacity: 1;
  }
`;

export const StyledLogo = styled('div')`
  width: 250px;
  height: 53px;
  background: url('/assets/loading-logo.svg') no-repeat center center;
  animation: ${blinkAnimation} 3s linear infinite;
`;
