import {
  Container,
  LeftWrapper,
  ImgWrapper,
  LogoImg,
  Content,
  OnboardingImg,
  Main,
} from './AuthLayout.styled';

interface AuthLayoutProps {
  children: React.ReactNode;
  isShowAlert?: boolean;
  login?: boolean;
}

const AuthLayout = ({
  children,
  isShowAlert = false,
  login = true,
}: AuthLayoutProps) => {
  return (
      <Container login={login}>
        <LeftWrapper>
          <ImgWrapper>
            <LogoImg src="/assets/logo-lg.svg" alt="logo" />
            <OnboardingImg
              src={
                login
                  ? '/assets/onboarding-image.svg'
                  : '/assets/signup-image.png'
              }
              alt="onboarding-image"
            />
          </ImgWrapper>
        </LeftWrapper>
        <Content>
          <Main isShowAlert={isShowAlert}>{children}</Main>
        </Content>
      </Container>
  );
};

export default AuthLayout;
