import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, Radio, Tooltip } from '@mui/material';
import Popper from '@mui/material/Popper';
import dayjs from 'dayjs';
import { useRef, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOnClickOutside } from 'usehooks-ts';
import { useSendInvite } from '../hooks';
import { MAX_ALMOST_DUE_VALUE } from '../schema';
import { TDraftInviteForm } from '../send-invites';
import { useSendInviteStore } from '../store';
import {
  StyledFormControlLabel,
  StyledFormLabel,
  StyledInput,
  StyledOverdueInfo,
  StyledRadioGroup,
  StyledReminderPopup,
} from './reminder.styled';

export type ReminderProps = {
  anchorEl: null | HTMLElement;
};

export default function ReminderPopup({ anchorEl }: ReminderProps) {
  const store = useSendInviteStore();
  const ref = useRef(null);
  const { watch, register, setValue } = useFormContext<TDraftInviteForm>();
  const { onSave } = useSendInvite();

  const rOverdue = register('overdue_reminder');
  const rCustomOverdue = register('customOverdueValue');

  const rAlmostDue = register('almost_due_reminder');
  const rCustomAlmostDue = register('customAlmostDueValue');

  const wDueDate = watch('due_at');

  const wOverdue = watch('overdue_reminder', '4');
  const wCustomOverdue = watch('customOverdueValue', '');

  const wAlmostDue = watch('almost_due_reminder', '1');
  const wCustomAlmostDue = watch('customAlmostDueValue', '');

  useOnClickOutside(ref, () => {
    store.setIsReminderOpen(false);
    onSave();
  });

  const maxAlmostDue = useMemo(() => {
    if (!wDueDate) return MAX_ALMOST_DUE_VALUE;

    const diffInDays = wDueDate.diff(dayjs().startOf('day'), 'day');

    return diffInDays;
  }, [wDueDate]);

  const onAlmostDueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== 'custom') {
      setValue('customAlmostDueValue', '');
    }

    rAlmostDue.onChange(event);
  };

  const onCustomAlmostDueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // prevent negative numbers
    if (event.target.value.startsWith('-')) {
      setValue('customOverdueValue', '');
    } // prevent user from entering a number higher than 30
    else if (Number(event.target.value) >= maxAlmostDue) {
      setValue('customAlmostDueValue', '');
    } else {
      rCustomAlmostDue.onChange(event);
    }

    if (wAlmostDue !== 'custom') {
      setValue('almost_due_reminder', 'custom');
    }
  };

  const onOverdueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== 'custom') {
      setValue('customOverdueValue', '');
    }

    rOverdue.onChange(event);
  };

  // eslint-disable-next-line unused-imports/no-unused-vars
  const onCustomOverdueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // prevent negative numbers
    if (event.target.value.startsWith('-')) {
      setValue('customOverdueValue', '');
    }

    // prevent user from entering a number higher than 7
    else if (Number(event.target.value) > 7) {
      setValue('customOverdueValue', '');
    } else {
      rCustomAlmostDue.onChange(event);
    }

    if (wOverdue !== 'custom') {
      setValue('overdue_reminder', 'custom');
    }
  };

  return (
    <Popper
      ref={ref}
      id="reminder"
      open={store.isReminderOpen}
      anchorEl={anchorEl}
      placement="bottom-start"
      data-cy="reminder-popup"
    >
      <StyledReminderPopup>
        <FormControl>
          <StyledFormLabel data-cy="almost-due-label" id="almost-due">
            Almost Due Reminder
          </StyledFormLabel>
          <StyledRadioGroup
            {...rAlmostDue}
            aria-labelledby="almost-due-label"
            data-cy="almost-due"
            value={wAlmostDue}
            onChange={onAlmostDueChange}
          >
            <StyledFormControlLabel
              value="1"
              control={<Radio color="secondary" size="small" {...rAlmostDue} />}
              disabled={maxAlmostDue <= 1}
              label="1 day before due"
              data-cy="1-day-before-due"
            />
            <StyledFormControlLabel
              value="2"
              control={<Radio color="secondary" size="small" {...rAlmostDue} />}
              disabled={maxAlmostDue <= 2}
              label="2 days before due"
              data-cy="2-days-before-due"
            />
            <StyledFormControlLabel
              value="0"
              control={<Radio color="secondary" size="small" {...rAlmostDue} />}
              label="Never"
              data-cy="never"
            />
            <StyledFormControlLabel
              value="custom"
              disabled={maxAlmostDue <= 2}
              control={<Radio color="secondary" size="small" {...rAlmostDue} />}
              label={
                <>
                  <StyledInput
                    type="number"
                    placeholder="00"
                    data-cy="custom-almost-due"
                    {...rCustomAlmostDue}
                    value={wCustomAlmostDue}
                    onChange={onCustomAlmostDueChange}
                    disabled={maxAlmostDue <= 2}
                    onFocus={() => {
                      setValue('almost_due_reminder', 'custom');
                    }}
                  />{' '}
                  day/s before due
                </>
              }
              data-cy="custom"
            />
          </StyledRadioGroup>
        </FormControl>
        <FormControl>
          <StyledFormLabel
            data-cy="overdue-reminder-label"
            id="overdue-reminder"
          >
            <StyledOverdueInfo>
              Overdue Reminder
              <Tooltip title="Reminders run for 2 weeks" placement="top">
                <FontAwesomeIcon
                  className="icon-question-circle"
                  icon={solid('question-circle')}
                />
              </Tooltip>
            </StyledOverdueInfo>
          </StyledFormLabel>
          <StyledRadioGroup
            aria-labelledby="overdue-reminder-label"
            data-cy="overdue-reminder"
            {...rOverdue}
            value={wOverdue}
            onChange={onOverdueChange}
          >
            <StyledFormControlLabel
              value="2"
              control={<Radio color="secondary" size="small" {...rOverdue} />}
              label="Every 2 days"
              data-cy="every-2-days"
            />
            <StyledFormControlLabel
              value="4"
              control={<Radio color="secondary" size="small" {...rOverdue} />}
              label="Every 4 days"
              data-cy="every-4-days"
            />
            <StyledFormControlLabel
              value="0"
              control={<Radio color="secondary" size="small" {...rOverdue} />}
              label="Never"
              data-cy="never"
            />
            <StyledFormControlLabel
              value="custom"
              control={<Radio color="secondary" size="small" {...rOverdue} />}
              label={
                <div>
                  Every{' '}
                  <StyledInput
                    type="number"
                    placeholder="00"
                    data-cy="custom-overdue"
                    {...rCustomOverdue}
                    value={wCustomOverdue}
                    onChange={onCustomOverdueChange}
                    onFocus={() => {
                      setValue('overdue_reminder', 'custom');
                    }}
                  />
                  days
                </div>
              }
              data-cy="custom"
            />
          </StyledRadioGroup>
        </FormControl>
      </StyledReminderPopup>
    </Popper>
  );
}
