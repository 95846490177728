import { useSendInviteStore } from "./store";
import { TabPanel } from "./tab-panel/tab-panel";

export const Portal = () => {
  const store = useSendInviteStore();

  return (
    <TabPanel value={store.activeTabIndex} index={1}>
      THIS IS THE PORTAL PREVIEW
    </TabPanel>
  );
}
