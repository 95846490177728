import { Box, Button } from '@mui/material';
import { FC } from 'react';

const AuthorizationRequired: FC = () => {
  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        display: 'flex',
        flexGrow: 1,
        py: '80px',
      }}
    >
      Authorization Required
      <Button variant="text" href="/login">
        Go To Login
      </Button>
    </Box>
  );
};

export default AuthorizationRequired;
