import { colors } from '@components/theme/gen2';
import { Button, hexToRgb, IconButton } from '@mui/material';
import { opacify, rem } from 'polished';
import styled from 'styled-components';

export const StyledFileUpload = styled.div<{
  $progress: number;
  $uploading: boolean;
  $tablet: boolean;
}>`
  border: ${rem(1)} solid ${colors.gray};
  width: 100%;
  display: grid;
  grid-column-gap: ${rem(20)};
  grid-template-columns: repeat(2, auto);

  ${(props) => {
    if (!props?.$uploading || props?.$tablet) {
      return `background-color: white;`;
    }

    return `
      background-image: linear-gradient(
        to right,
        ${hexToRgb(colors.secondary + '0f')},
        ${hexToRgb(colors.secondary + '0f')}
      );
      background-position: left;
      background-size: ${Math.round(props.$progress)}% 100%;
      background-repeat: no-repeat;
    `;
  }}
`;

export const StyledFileUploadInfo = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${rem(10)};
  padding-top: ${rem(15)};
  padding-bottom: ${rem(15)};
  padding-left: ${rem(20)};
  padding-right: ${rem(20)};
  height: fit-content;
  margin: ${rem(2)} 0;
  word-break: break-all;
`;

export const StyledFileUploadFileSize = styled.span`
  color: ${colors.grayDark1};
  font-style: italic;
`;

export const StyledFileUploadActions = styled.div<{ $tablet?: boolean }>`
  ${(props) => {
    if (props?.$tablet) {
      return `
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        height: fit-content;
        padding: ${rem(10)};
        gap: ${rem(8)};
      `;
    } else {
      return `
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: fit-content;
      `;
    }
  }}
`;

export const StyledFileUploadCancel = styled.button`
  background: none;
  border: none;
  width: ${rem(36)};
  height: ${rem(36)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${rem(5)};
  color: ${colors.grayDark2};
  cursor: pointer;

  &:active {
    background-color: ${opacify(0.5)(colors.grayLight2)};
  }
`;

export const StyledFileUploadMore = styled.button`
  background: none;
  border: none;
  height: calc(100% - 2px);
  width: ${rem(30)};
  margin: ${rem(2)};
  color: ${colors.grayDark2};

  &:active {
    background-color: ${opacify(0.5)(colors.grayLight2)};
  }
`;

export const StyledFileUploadIconButton = styled(IconButton)`
  font-size: ${rem(18)};
  width: ${rem(36)};
  height: ${rem(36)};
`;

export const StyledIconButton = styled(Button)({
  display: 'flex',
  fontSize: rem(12),
  gap: rem(7),
  paddingTop: rem(5),
  paddingBottom: rem(5),
  whiteSpace: 'nowrap',
});

export const StyledFileUploadIconButtonCancel = styled(
  StyledFileUploadIconButton,
)<{ $progress: number }>`
  position: relative;
  overflow: hidden;
  z-index: 3;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: ${colors.secondary};
    transform: translateY(
      ${(props) => Math.min(props.$progress * 2, 100) - 100}%
    );
    z-index: 2;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: ${colors.secondary};
    transform: translateY(
      ${(props) => -Math.max(props.$progress * 2, 100) + 200}%
    );
    z-index: 2;
  }
`;

export const StyledFileUploadIconButtonCancelElement = styled.div`
  --offset: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - var(--offset));
  height: calc(100% - var(--offset));
  position: absolute;
  top: calc(50% - var(--offset));
  left: calc(50% - var(--offset));
  transform: translate(calc(-50% + var(--offset)), calc(-50% + var(--offset)));
  font-size: 18px;
  background-color: white;
  border: ${rem(3)} solid transparent;
  border-radius: 50%;
  z-index: 3;
`;
