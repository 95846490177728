import { useSaveTemplateMutation } from '@gen2/api/templates/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, TextField } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { schema } from './schema';
import {
  StyledButtonContainer,
  StyledContainer,
  StyledHeader,
  StyledHeadline,
  StyledTemplateContent,
} from './success.styled';

type TSaveTemplateForm = {
  name: string;
};

export const SendInviteSuccess = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<TSaveTemplateForm>({
    resolver: yupResolver(schema),
  });
  const name = register('name');
  const { t } = useTranslation('templates');
  const { id } = useParams();
  const navigate = useNavigate();
  const [isDone, setIsDone] = useState(false);

  const { mutate } = useSaveTemplateMutation();

  const onSubmit = (data: TSaveTemplateForm) => {
    if (!id) return;

    mutate(
      {
        inviteId: id,
        name: data.name,
      },
      {
        onSuccess: () => {
          setIsDone(true);
        },
        onError: (err: unknown) => {
          const mutateErr = err as AxiosResponse;

          setError('name', {
            message: mutateErr.data?.message,
            type: 'manual',
          });
        },
      },
    );
  };

  const goToInvite = () => {
    navigate(`/invite-listing/${id}`);
  };

  return (
    <StyledContainer data-cy="invite-sent">
      <StyledHeader>
        <img src="/assets/invite-send-art.svg" alt="Success" />
        <StyledHeadline data-cy="invite-sent-headline">
          {t('afterSent.title')}
        </StyledHeadline>
        <p>{t('afterSent.desc')}</p>
        <Button
          data-cy="view-invite-btn"
          onClick={goToInvite}
          variant="contained"
          color="primary"
        >
          View Your Invite
        </Button>
      </StyledHeader>
      {isDone ? (
        <Alert data-cy="invite-sent-success" icon={false} severity="success">
          <p>{t('success')}</p>
        </Alert>
      ) : (
        <StyledTemplateContent>
          <StyledHeadline>{t('afterSent.saveTitle')}</StyledHeadline>
          <TextField
            fullWidth
            label={t('name.label')}
            inputProps={{ 'data-cy': 'template-name-input' }}
            {...name}
            required
            error={!!errors?.name}
            helperText={t(errors?.name?.message ?? '')}
          />
          <StyledButtonContainer>
            <Button
              data-cy="no-thanks-btn"
              onClick={goToInvite}
              variant="outlined"
              color="tertiary"
            >
              No Thanks
            </Button>
            <Button
              variant="contained"
              data-cy="save-template-btn"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Save Template
            </Button>
          </StyledButtonContainer>
        </StyledTemplateContent>
      )}
    </StyledContainer>
  );
};
