import { getFileUrl } from '@gen2/api/files/api';
import { getLiveForm } from '@gen2/api/live-form/api';
import { useToast } from '@gen2/hooks';
import { Button, Dialog } from '@mui/material';
import { customDecode } from '@nx-fe/components';
import WebViewer from '@pdftron/webviewer';
import { AxiosError } from 'axios';
import { useRef } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import {
  HeaderTitle,
  StyledHeader,
  StyledHeaderActions,
} from './peview-web-viewer-modal.styled';
import { disabledElementsForPreviewViewer } from './preview-webviewer-config';
export type TWebViewerModalProps = {
  isOpen: boolean;
  fileId: string;
  fileName: string;
  onClose: () => void;
};
export const PreviewWebViewerModal = ({
  isOpen,
  fileId,
  fileName,
  onClose,
}: TWebViewerModalProps) => {
  const viewer2 = useRef(null);
  const beenInitialised = useRef<boolean>(false);
  const toast = useToast();
  useEffectOnce(() => {
    const handle = (el?: HTMLElement) => {
      if (fileId && el && !beenInitialised.current) {
        beenInitialised.current = true;
        WebViewer(
          {
            path: '/assets/lib/pdftron',
            licenseKey: customDecode(window.$zfeg48),
            disabledElements: disabledElementsForPreviewViewer,
          },
          el,
        ).then(async (instance) => {
          const { documentViewer, annotationManager } = instance.Core;
          if (!isOpen) {
            return;
          }
          let signed_url = null;
          instance.UI.openElements(['loadingModal']);
          try {
            const res = await getFileUrl({ fileId: fileId });
            signed_url = res?.data.data.signed_url;
            instance.UI.loadDocument(`${signed_url}`);
            instance.UI.disableFeatures([instance.UI.Feature.Measurement]);

            // Fetching the XFDF data
            const documentLoadedListener = async () => {
              // API request to get the annotation XFDF string
              try {
                instance.UI.openElements(['loadingModal']);
                const { data } = await getLiveForm(fileId);
                const xfdfString = data.data?.liveform?.fields?.xfdf_data;
                await annotationManager.importAnnotations(`${xfdfString}`);
              } catch (e) {
                console.error(e);
                if ((e as AxiosError).status === 500) {
                  toast.show({
                    text: 'Internal Server Error',
                    variant: 'error',
                  });
                } else if ((e as AxiosError).status === 422) {
                  toast.show({
                    text: 'The selected type is invalid',
                    variant: 'error',
                  });
                }
              } finally {
                instance.UI.closeElements(['loadingModal']);
              }
            };
            documentViewer.addEventListener(
              'documentLoaded',
              documentLoadedListener,
            );
          } catch (e) {
            console.error(e);
          }
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setTimeout(() => handle(viewer2.current!)); //dont chanage this
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    handle(viewer2.current!);
  });

  return (
    <Dialog id="preview-modal" fullScreen open={isOpen} onClose={onClose}>
      <StyledHeader>
        <HeaderTitle>{fileName}</HeaderTitle>
        <StyledHeaderActions spacing={1} direction="row">
          <Button variant="outlined" color="tertiary" onClick={onClose}>
            Close
          </Button>
        </StyledHeaderActions>
      </StyledHeader>
      <div ref={viewer2} style={{ height: '100vh' }} />
    </Dialog>
  );
};
