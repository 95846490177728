import { TIntegrationType } from '@gen2/api/integrations/api';
import { ActionModal } from '@gen2/app/components/action-modal/action-modal';
import { useActionModalStore } from '@gen2/app/components/action-modal/store';
import { useAuth } from '@gen2/hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CustomTooltip,
  StyledContent,
  StyledIntCard,
  StyledIntCardConnect,
  StyledIntCardConnectSwitch,
  StyledIntCardContent,
  StyledIntCardHeader,
  StyledIntCardImg,
  StyledIntCards,
  StyledIntegrations,
  StyledTitle,
} from './integrations.styled';
import { useIntegrationContext } from './useIntegrationContext';

interface IIntegration {
  provider: TIntegrationType;
  title: string;
  img: string;
  link: string;
  connected: boolean;
  disabled: boolean;
  cardDataCy: string;
  buttonDataCy: string;
  buttonValue: boolean;
  buttonChecked: boolean;
}

const Integrations = () => {
  const { disabled, connect, disconnect, isLoading, isConnected } =
    useIntegrationContext();
  const { user, featureFlags } = useAuth();
  const { t } = useTranslation('integrations');

  const { showModal } = useActionModalStore();

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleSwitchChange = async (type: TIntegrationType) => {
    if (!isConnected(type)) {
      connect(type);
    } else {
      const provider = type === 'google' ? 'Google Drive' : 'OneDrive';
      const { isConfirmed } = await showModal({
        header: t('disconnect.modal.header', { provider }),
        message: 'disconnect.modal.message',
        messageParams: { provider },
        translationNamespace: 'integrations',
        closeButtonLabel: t('disconnect.modal.cancel_btn') ?? '',
        submitButtonLabel: t('disconnect.modal.confirm_btn') ?? '',
      });

      if (!isConfirmed) {
        return;
      }

      disconnect(type);
    }
  };

  const integrations = useMemo<IIntegration[]>(
    () => [
      {
        provider: 'google',
        title: t('google.thumbnail.title'),
        img: '/assets/int-google.png',
        link: '/integrations/google',
        connected: !!user?.integrations?.drive?.is_connected,
        disabled: disabled.google,
        cardDataCy: 'gdrive-link',
        buttonDataCy: 'connect-google-drive',
        buttonValue: !!user?.integrations?.drive?.is_connected,
        buttonChecked: !!user?.integrations?.drive?.is_connected,
      },
      {
        provider: 'microsoft',
        title: t('microsoft.thumbnail.title'),
        img: '/assets/int-microsoft.png',
        link: '/integrations/microsoft',
        connected: !!user?.integrations?.onedrive?.is_connected,
        disabled: disabled.microsoft,
        cardDataCy: 'onedrive-link',
        buttonDataCy: 'connect-one-drive',
        buttonValue: !!user?.integrations?.onedrive?.is_connected,
        buttonChecked: !!user?.integrations?.onedrive?.is_connected,
      },
    ],
    [
      disabled.google,
      disabled.microsoft,
      t,
      user?.integrations?.drive?.is_connected,
      user?.integrations?.onedrive?.is_connected,
    ],
  );

  const computedIntegrations = useMemo(() => {
    return featureFlags?.['one_drive_business']
      ? integrations
      : integrations.slice(0, 1);
  }, [featureFlags, integrations]);

  return (
    <StyledIntegrations>
      <StyledContent>
        <StyledTitle>Cloud Storage</StyledTitle>
        <StyledIntCards>
          {computedIntegrations.map((integration, index) => (
            <StyledIntCard
              key={index}
              data-cy={integration.cardDataCy}
              to={integration.link}
            >
              <StyledIntCardHeader>
                <StyledIntCardImg src={integration.img} alt="" />
              </StyledIntCardHeader>
              <StyledIntCardContent>
                <p>{integration.title}</p>
                <CustomTooltip
                  title={t('connect.tooltip')}
                  placement="top"
                  disableHoverListener={!integration.disabled}
                >
                  <StyledIntCardConnect
                    data-cy={integration.buttonDataCy}
                    control={
                      <StyledIntCardConnectSwitch
                        onClick={stopPropagation}
                        onChange={() =>
                          handleSwitchChange(integration.provider)
                        }
                        value={integration.buttonValue}
                        checked={integration.buttonChecked}
                      />
                    }
                    label="Connect"
                    onClick={(e) => e.stopPropagation()}
                    disabled={isLoading || integration.disabled}
                  />
                </CustomTooltip>
              </StyledIntCardContent>
            </StyledIntCard>
          ))}
        </StyledIntCards>
      </StyledContent>

      <ActionModal />
    </StyledIntegrations>
  );
};

export default Integrations;
