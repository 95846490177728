import { colors } from '@components/theme/gen2';
import {
  MenuProps,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { rem } from 'polished';

export const LabelContainer = styled('span')({
  marginLeft: rem(4),
  color: colors.grayDark3,
  fontSize: rem(14),
});

export const StyledBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledItemBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: rem(16),
});

export const menuProps: Partial<MenuProps> = {
  PaperProps: {
    style: {
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)',
      marginTop: rem(10),
      border: `1px solid ${colors.info}`,
      maxHeight: 300,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

export const TooltipContainer = styled('div')({
  position: 'absolute',
  top: rem(10),
  right: rem(9),
  zIndex: 10,
  '&:hover': {
    color: colors.primary,
  },
});

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 107,
  },
});

export const Mobile = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  '& > div': {
    '&:nth-of-type(2)': {
      flexGrow: 1,
    },
  },
  '& .MuiFormControl-root:first-of-type': {
    width: rem(84),
    minWidth: rem(84),
    '.MuiInputBase-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  '& .MuiFormControl-root:nth-of-type(2)': {
    marginLeft: rem(-1),
    '.MuiInputBase-root': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    '.MuiInputBase-input': {
      paddingRight: rem(28),
    },
  },
});
