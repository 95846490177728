import FormPhone from '@components/lib/form-phone/form-phone';
import { useAuth } from '@gen2/hooks';
import { TContact } from '@gen2/types/contact';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import Popper from '@mui/material/Popper';
import { debounce } from 'lodash';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { SubmitHandler, useForm, UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { StyledContactPopper, StyledContainer } from '../contact-popup.styled';
import {
  StyledAddContactFooter,
  StyledAddContactForm,
  StyledAddContactHeader,
} from './add-contact.styled';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('email.invalid')
    .required('email.required')
    .max(150, 'email.max'),
  first_name: Yup.string()
    .required('firstName.required')
    .max(150, 'firstName.max'),
  last_name: Yup.string()
    .required('lastName.required')
    .max(150, 'lastName.max'),
  country_iso2_code: Yup.string(),

  // number should have minimum of 10 and maximum of 20 characters
  // should be a string but only accept numbers
  // should be optional
  number: Yup.string().test({
    name: 'mobile-validation',
    message: 'number.invalid',
    test: function (this, value) {
      if (!value) return true; // not required

      const pattern = new RegExp(/(^$)|^[0-9]{6,20}$/, 'g');

      return !!value.match(pattern) && value.length >= 6;
    },
  }),
});

export type TAddContactForm = Yup.InferType<typeof schema>;

export type AddContactPopupProps = {
  open: boolean;
  id?: string;
  anchorEl: null | HTMLElement;
  onCancel: () => void;
  onCreate: ({
    contact,
    form,
  }: {
    contact: TAddContactForm;
    form: { setError: UseFormSetError<TAddContactForm> };
  }) => void;
  onEdit: ({
    contact,
    form,
  }: {
    contact: TAddContactForm;
    form: { setError: UseFormSetError<TAddContactForm> };
  }) => void;
  onClickAway: () => void;
  isAddContactLoading?: boolean;
  mode: 'add' | 'edit'; // Add or Edit mode
  currentContact?: TContact | null; // Optional contact data for edit mode
} & React.ComponentProps<typeof Popper>;

export default function AddContactPopup({
  open,
  id,
  anchorEl,
  onCancel,
  onCreate,
  onEdit,
  onClickAway,
  isAddContactLoading,
  mode,
  currentContact,
  ...rest
}: AddContactPopupProps) {
  const ref = useRef(null);
  const { t } = useTranslation('validation');
  const { user: userMe } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    setValue,
  } = useForm<TAddContactForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      country_iso2_code: 'AU',
      number: '',
    },
  });

  const phoneNumberField = register('number');
  const countryCodeField = register('country_iso2_code');
  const [countryIso2Code, phoneNumber] = watch(['country_iso2_code', 'number']);

  useEffect(() => {
    if (mode === 'edit' && currentContact) {
      const { first_name, last_name, email, phone } = currentContact;
      setValue('first_name', first_name || '');
      setValue('last_name', last_name || '');
      setValue('email', email || '');

      // Set phone details or defaults
      setValue('country_iso2_code', phone?.country_iso2_code ?? 'AU');
      setValue('number', phone?.number ?? '');
    }
  }, [currentContact, mode, setValue, open]);

  useEffect(() => {
    if (userMe) {
      const userMeCountryIso2Code = userMe.organisations[0].country_iso2_code;

      if (userMeCountryIso2Code !== countryIso2Code) {
        setValue('country_iso2_code', userMeCountryIso2Code);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMe, open]);

  useLayoutEffect(() => {
    const handleClickOutside = debounce((event: MouseEvent) => {
      if (event.target === document.body) return;

      onClickAway();
    }, 50);

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [onClickAway]);

  // reset form on close
  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [reset, open]);

  const onSubmit: SubmitHandler<TAddContactForm> = (data: TAddContactForm) => {
    if (mode === 'edit') {
      return onEdit({
        contact: data,
        form: { setError },
      });
    }
    onCreate({
      contact: data,
      form: { setError },
    });
  };

  return (
    <StyledContactPopper
      ref={ref}
      onClick={(e) => e.stopPropagation()}
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      data-cy="add-contact-popup"
      {...rest}
    >
      <StyledContainer>
        <StyledAddContactHeader data-cy="add-contact-header">
          {mode === 'add' ? 'Add Contact' : 'Edit Contact'}
        </StyledAddContactHeader>
        <StyledAddContactForm
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          data-cy="add-contact-form"
        >
          <TextField
            className="firstName"
            label={t('firstName.label')}
            variant="outlined"
            data-cy="firstName-input"
            fullWidth
            error={Boolean(errors.first_name)}
            helperText={
              <span data-cy="first_name-error">
                {errors.first_name?.message && t(errors.first_name.message)}
              </span>
            }
            required
            {...register('first_name')}
          />
          <TextField
            className="lastName"
            label={t('lastName.label')}
            variant="outlined"
            fullWidth
            error={Boolean(errors.last_name)}
            helperText={
              <span data-cy="last_name-error">
                {errors.last_name?.message && t(errors.last_name.message)}
              </span>
            }
            data-cy="last_name-input"
            required
            {...register('last_name')}
          />
          <TextField
            label={t('email.label')}
            type="email"
            variant="outlined"
            fullWidth
            error={Boolean(errors.email)}
            data-cy="email-input"
            helperText={
              <span data-cy="email-error">
                {errors.email?.message && t(errors.email.message)}
              </span>
            }
            required
            {...register('email')}
          />
          <FormPhone
            mobNumber={phoneNumber}
            codeFieldProps={{
              ...countryCodeField,
              defaultValue: countryIso2Code,
              value: countryIso2Code,
              onClick: (e) => e.stopPropagation(),
            }}
            phoneFieldProps={phoneNumberField}
            cypressId="phoneNumber"
            error={Boolean(errors.number)}
            errorMessage={
              <span data-cy="phoneNumber-error">
                {errors.number?.message && t(errors.number.message)}
              </span>
            }
          />
          <StyledAddContactFooter>
            <Button
              variant="outlined"
              color="tertiary"
              onClick={onCancel}
              data-cy="cancel-btn"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              data-cy="submit-btn"
              disabled={isAddContactLoading}
            >
              {mode === 'add' ? 'Create' : 'Save'}
            </Button>
          </StyledAddContactFooter>
        </StyledAddContactForm>
      </StyledContainer>
    </StyledContactPopper>
  );
}
