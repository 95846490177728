import { StyledSnackbar } from '@components/lib/toast/toast.styles';
import { Alert, SnackbarProps } from '@mui/material';
import { useSendInviteStore } from './store';

export const BannerMessage = () => {
  const store = useSendInviteStore();

  const onCloseBanner: SnackbarProps['onClose'] = (_, reason) => {
    if (reason !== 'timeout') {
      return;
    }

    store.setBannerMessage({ ...store.bannerMessage, message: '' });
  };

  return (
    <StyledSnackbar
      open={!!store.bannerMessage.message}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      data-cy="snackbar"
      disableWindowBlurListener
      onClose={onCloseBanner}
      autoHideDuration={5000}
    >
      {store.bannerMessage.message ? (
        <Alert severity={store.bannerMessage.severity} data-cy="alert">
          <div data-cy="toast-message">{store.bannerMessage.message}</div>
        </Alert>
      ) : (
        <div />
      )}
    </StyledSnackbar>
  );
};
