import { grayLight2 } from '@components/theme/gen2/new-colors';
import { styled } from '@mui/material';
import { rem } from 'polished';

export const StyledTabPanel = styled('div')({
  backgroundColor: grayLight2,
  flexGrow: 1,
  paddingTop: rem(40),
  paddingBottom: rem(40),
});

export const StyledTabPanelBody = styled('div')({
  marginLeft: 'auto',
  maxWidth: rem(840),
  marginRight: 'auto',
  paddingRight: rem(20),
  paddingLeft: rem(20),
});
