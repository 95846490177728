import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';

export type TUpgradeModalProps = {
  open: boolean;
  onClose: () => void;
  title: string | React.ReactNode;
  children?: React.ReactNode;
};

const UpgradeModal = ({
  open,
  title,
  onClose,
  children,
}: TUpgradeModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="upgrade-modal-title"
      aria-describedby="upgrade-modal-description"
      data-cy="upgrade-modal"
    >
      <ModalCloseButton
        data-cy="close-btn"
        aria-label="close"
        onClick={onClose}
      />
      <DialogTitle data-cy="title" id="upgrade-modal-title">
        {title}
      </DialogTitle>
      <DialogContent data-cy="content" dividers sx={{ maxWidth: 400 }}>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="tertiary"
          variant="outlined"
          data-cy="cancel-btn"
        >
          Close
        </Button>

        {/* Upgrade feature is not yet available */}
        {/*<Button*/}
        {/*  onClick={onClose}*/}
        {/*  color="primary"*/}
        {/*  variant="contained"*/}
        {/*  data-cy="upgrade-btn"*/}
        {/*>*/}
        {/*  Upgrade*/}
        {/*</Button>*/}
      </DialogActions>
    </Dialog>
  );
};

export default UpgradeModal;
