import { TextField } from '@mui/material';
import {
  InputHTMLAttributes,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  StyledEditableInput,
  StyledError,
  StyledInputContainer,
  StyledLabel,
  StyledMaxLength,
} from './editable-input.styles';

/* eslint-disable-next-line */
export type EditableInputNewProps = {
  name: string;
  label?: string;
  value?: string;
  className?: string;
  error?: string | React.ReactNode;
  required?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  maxLength?: number;
  placeholder?: string;
};

export function EditableInputNew({
  name,
  label,
  value,
  className,
  inputProps,
  error,
  required,
  maxLength,
  placeholder,
  ...rest
}: Readonly<EditableInputNewProps>) {
  const val = value ?? placeholder ?? '';

  const inputRef = useRef<HTMLInputElement>(null);

  const [staticValue, setStaticValue] = useState<string | null>(null);

  useEffect(() => {
    // Set the static value only once when the component mounts
    if (!staticValue || /New\s(File|Form)\sRequest/g.test(value || '')) {
      setStaticValue(value as string);
    }
  }, [value, staticValue]);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (inputProps?.onChange) {
      if (e.target.value === staticValue) {
        return;
      }

      inputProps.onChange(e as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleOnBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (inputProps?.onBlur) {
      if (e.target.value === staticValue || e.target.value === '') {
        return;
      }

      inputProps.onBlur(e as React.FocusEvent<HTMLInputElement>);
    }
  };

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();

    if (inputRef.current?.value === staticValue) {
      inputRef.current.value = '';
    }

    inputRef.current?.focus();
  };

  const handleOnKeypress = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>,
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      inputRef.current?.blur();
    }
  };

  return (
    <StyledEditableInput
      className={className}
      $hasError={!!error}
      $required={required}
      onClick={handleOnClick}
      data-cy={`${name}-container`}
      {...rest}
    >
      {label && (
        <StyledLabel className="editableInput__label" htmlFor={name}>
          {label}:
        </StyledLabel>
      )}
      <StyledInputContainer>
        <TextField
          required={required}
          fullWidth
          variant="filled"
          size="small"
          hiddenLabel={true}
          data-cy={`${name}-input-wrapper`}
          data-testid="editableInput__input"
          inputProps={{
            'data-cy': `${name}-input`,
          }}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onKeyDown={(e) => handleOnKeypress(e)}
          placeholder={placeholder}
          ref={inputRef}
          id={name}
          value={value}
          name={name}
          error={!!error}
          helperText={
            <StyledError
              data-cy="errorMessage"
              className="editableInput__error"
            >
              {error}
            </StyledError>
          }
        />
      </StyledInputContainer>
      {maxLength && (
        <StyledMaxLength data-cy="counter" className="editableInput__counter">
          {val.length}/{maxLength} characters
        </StyledMaxLength>
      )}
    </StyledEditableInput>
  );
}

export default EditableInputNew;
