import { create } from 'zustand';

export interface InviteRequestLibrary {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const useInviteRequestLibraryStore = create<InviteRequestLibrary>()(
  (set) => ({
    open: false,
    setOpen: (open: boolean) => set({ open }),
  }),
);
