import { IProfileSettingsForm } from '@gen2/api/user-settings/api';
import { useUpdateProfileSettingsMutation } from '@gen2/api/user-settings/hooks';
import { useAuth, useToast } from '@gen2/hooks';
import { ErrorResponse } from '@gen2/utils/formatMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField } from '@mui/material';
import { AxiosResponse } from 'axios';
import { has } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useChangePasswordStore } from '../security-settings/change-password-modal/store';
import { TUserSettingsProps } from '../user-settings';
import Avatar from './avatar/avatar';
import {
  ProfileContainerStyledBox,
  ProfileDetailsStyledBox,
  ProfilePictureStyledBox,
  StyledFormGroup,
  StyledSubmit,
  StyleFormHeader,
  Title,
} from './profile-settings.styled';

interface IProfileSettingsError {
  email?: [];
  first_name?: [];
  last_name?: [];
}

const schema = Yup.object().shape({
  email: Yup.string()
    .email('profileSettings.form.email.invalid')
    .required('profileSettings.form.email.required'),
  first_name: Yup.string().required('profileSettings.form.firstName.required'),
  last_name: Yup.string().required('profileSettings.form.lastName.required'),
});

// Dispatch<SetStateAction<boolean>>
// Dispatch<SetStateAction<string[] | ReactNode[]>>
const ProfileSettings = ({ setIsShow, setMessages }: TUserSettingsProps) => {
  const { t } = useTranslation('userSettings');
  const toast = useToast();
  const { user, initialize } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { setIsSuccessShow, } = useChangePasswordStore();

  const [initialFormValues, setInitialFormValues] =
    useState<IProfileSettingsForm>({
      email: '',
      first_name: '',
      last_name: '',
    });

  const { mutate } = useUpdateProfileSettingsMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm<IProfileSettingsForm>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (user) {
      const defaultValues = {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
      };

      setInitialFormValues(defaultValues);
      reset(defaultValues);
    }
  }, [reset, user]);

  const onSubmit: SubmitHandler<IProfileSettingsForm> = (form) => {
    setIsLoading(true);

    mutate(form, {
      onError: (error: unknown) => {
        const axiosError = error as AxiosResponse;
        if (axiosError) {
          const { status, data } = axiosError;

          if (status === 422) {
            const responseData = data as ErrorResponse;
            const errors = responseData.errors as IProfileSettingsError;

            if (errors) {
              if (has(errors, 'email')) {
                setError('email', {
                  type: 'server',
                  message: errors.email
                    ? errors.email.at(0)
                    : 'profileSettings.form.email.invalid',
                });
              }

              if (has(errors, 'first_name')) {
                setError('first_name', {
                  type: 'server',
                  message: errors.first_name
                    ? errors.first_name.at(0)
                    : 'profileSettings.form.firstName.invalid',
                });
              }

              if (has(errors, 'last_name')) {
                setError('last_name', {
                  type: 'server',
                  message: errors.last_name
                    ? errors.last_name.at(0)
                    : 'profileSettings.form.lastName.invalid',
                });
              }
            }
          }

          if (status >= 500 || status === 401 || status === 400) {
            setIsShow(true);

            if (status >= 500) {
              setMessages([t('error.500', { ns: 'common' })]);
            }

            if (status === 401 || status === 400) {
              setMessages([t(`error.${status}`)]);
            }
          }
        }
      },
      onSuccess: (): void => {
        initialize();
        setIsSuccessShow(false);
        toast.show({
          text: t('profileSettings.form.success'),
          variant: 'success',
        });
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
  };

  return (
    <ProfileContainerStyledBox>
      <ProfilePictureStyledBox>
        <Avatar />
        <Title>{user?.first_name + ' ' + user?.last_name}</Title>
      </ProfilePictureStyledBox>

      <ProfileDetailsStyledBox>
        <StyleFormHeader>{t('profileSettings.form.header')}</StyleFormHeader>
        <form noValidate>
          <Stack spacing={3} sx={{ flexWrap: 'wrap' }}>
            <StyledFormGroup>
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="first_name"
                    fullWidth
                    required
                    type="text"
                    label={t('profileSettings.form.firstName.label')}
                    error={Boolean(errors.first_name)}
                    helperText={
                      errors.first_name?.message && t(errors.first_name.message)
                    }
                    inputProps={{
                      'data-cy': 'first-name-input',
                    }}
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="last_name"
                    fullWidth
                    required
                    type="text"
                    label={t('profileSettings.form.lastName.label')}
                    error={Boolean(errors.last_name)}
                    helperText={
                      errors.last_name?.message && t(errors.last_name.message)
                    }
                    inputProps={{
                      'data-cy': 'last-name-input',
                    }}
                  />
                )}
              />
            </StyledFormGroup>

            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="email"
                  fullWidth
                  required
                  disabled
                  label={t('profileSettings.form.email.label')}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message && t(errors.email.message)}
                  inputProps={{
                    'data-cy': 'email-input',
                  }}
                />
              )}
            />

            <StyledSubmit
              variant="contained"
              color="primary"
              loading={isLoading}
              type="submit"
              onClick={handleSubmit(onSubmit)}
              data-cy="submit"
            >
              {t('profileSettings.form.submit')}
            </StyledSubmit>
          </Stack>
        </form>
      </ProfileDetailsStyledBox>
    </ProfileContainerStyledBox>
  );
};

export default ProfileSettings;
