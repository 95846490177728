import { colors, textSizes, weights } from '@components/theme/gen2';
import { Box, Stack, styled } from '@mui/material';
import { rem } from 'polished';

interface InviteListLayoutStyleProps {
  open: boolean;
}

export interface StyledDividerStyleProps {
  direction: 'left' | 'right';
}

export const StyledBox = styled(Box)({
  display: 'flex',
  height: '100%',
  flex: 1,
  width: '100%',
});

export const Left = styled('div')<InviteListLayoutStyleProps>(
  ({ theme, open }) => ({
    width: open ? rem(410) : rem(0),
    flex: `0 0 ${open ? rem(410) : rem(0)}`,
    transition: 'all linear .3s',
    height: 'inherit',
    '> .MuiBox-root': {
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      flex: '1 1 auto',
    },
  }),
);

export const Center = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  backgroundColor: colors.grayLight2,
  display: 'flex',
  justifyContent: 'space-around',
  position: 'relative',
  borderLeft: `1px solid ${colors.grayLight1}`,
  borderRight: `1px solid ${colors.grayLight1}`,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const Main = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledDivider = styled('span')<StyledDividerStyleProps>(
  ({ direction }) => ({
    display: 'block',
    position: 'absolute',
    height: rem(40),
    width: rem(25),
    top: '50%',
    marginTop: rem(-40),
    left: direction === 'left' ? rem(-12.5) : 'unset',
    right: direction === 'right' ? rem(-12.5) : 'unset',
    cursor: 'pointer',
    zIndex: 100,
    border: `${rem(1)} solid rgba(0,0,0,0.12)`,
    borderRadius: rem(5),
    background: colors.white,
  }),
);

export const IconContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

export const MessageBadge = styled('div')({
  position: 'absolute',
  top: rem(160),
  right: rem(-1),
  minWidth: rem(40),
  height: rem(166),
  background: colors.white,
  zIndex: 100,
  borderTopLeftRadius: rem(10),
  borderBottomLeftRadius: rem(10),
  border: `${rem(1)} solid ${colors.grayLight1}`,
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: rem(15),
  cursor: 'pointer',
  '& > h5': {
    display: 'inline-block',
    color: colors.grayDark1,
    fontSize: textSizes.sm1,
    fontWeight: weights.medium,
    writingMode: 'tb-rl',
    margin: 0,
    transform: 'rotate(180deg)',
  },
});

export const Right = styled('div')<InviteListLayoutStyleProps>(
  ({ theme, open }) => ({
    width: open ? rem(410) : rem(20),
    flex: `0 0 ${open ? rem(410) : rem(20)}`,
    transition: 'all linear .3s',

    ...(!open && {
      '> div': {
        display: 'none !important',
      },
    }),

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
);

export const DefaultView = styled(Stack)({
  justifyContent: 'center',
  alignItems: 'center',
  height: rem(217),
  width: 'fit-content',
  marginTop: rem(217),
  '> p': {
    textAlign: 'center',
    color: colors.grayDark1,
    fontWeight: weights.medium,
  },
  '> img': {
    width: rem(200),
    height: rem(155),
  },
});
