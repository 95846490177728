import { weights } from '@components/theme/gen2';
import { FormControlLabel, Stack } from '@mui/material';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledContent = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
  padding-left: ${rem(30)};
  padding-right: ${rem(30)};
  width: ${rem(340)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: ${rem(400)};
  }
`;

export const StyledHeadLine = styled.p`
  font-weight: ${weights.medium};
`;

export const StyledThreeColumn = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  align-items: center;
  grid-column-gap: ${rem(20)};
  grid-row-gap: ${rem(15)};
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: unset;
`;
