import { colors, textSizes, weights } from '@components/theme/gen2';
import { IconButton, styled } from '@mui/material';
import { rem } from 'polished';

export const StyledAvatar = styled(IconButton)({
  border: `1px solid ${colors.grayLight1}`,
  fontSize: textSizes.xl,
  fontWeight: weights.semiBold,
  width: rem(80),
  height: rem(80),
});

export const StyledImg = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '50%',
});
