import { colors, textSizes, weights } from '@components/theme/gen2';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  styled,
} from '@mui/material';
import { rem } from 'polished';

export const StyledDialogContent = styled(DialogContent)({
  maxWidth: rem(430),
  overflow: 'visible',
  paddingBottom: 0,
});

export const StyledDialogTitle = styled(DialogTitle)({
  marginBottom: rem(15),
  paddingTop: rem(20),
});

export const TermContent = styled('label')({
  fontSize: textSizes.base,
  margin: `${rem(20)} 0`,
  display: 'inline-block',
  '& > a': {
    textTransform: 'capitalize',
  },
});

export const Title = styled(Typography)({
  fontSize: rem(20),
  fontWeight: weights.semiBold,
});

export const SubTitle = styled(Typography)({
  fontSize: rem(14),
  fontWeight: weights.normal,
  color: colors.grayDark2,
});

export const StyledDialogActions = styled(DialogActions)({
  backgroundColor: 'unset',
  padding: `0 ${rem(20)}`,
  paddingTop: 0,
});

export const CompleteSetup = styled('span')({
  fontWeight: weights.medium,
});

export const StyledButton = styled(Button)({
  width: rem(157),
});
