import { useSafeQuery } from '@gen2/hooks';
import { UseQueryOptions, useMutation } from '@tanstack/react-query';
import {
  backupTwoFactorAuth,
  challengeTwoFactorAuth,
  disableTwoFactorAuth,
  enableTwoFactorAuth,
  generateBackupCode,
  getQrCode,
  updateProfileSettings,
  uploadProfileAvatar,
  verifyTwoFactorAuth,
  changePassword
} from './api';

export enum UserSettingKeys {
  getQrCode = 'getQrCode',
  getBackupCode = 'getBackupCode',
  generateBackupCode = 'generateBackupCode',
  enableTwoFactorAuth = 'enableTwoFactorAuth',
  verifyTwoFactorAuth = 'verifyTwoFactorAuth',
  disableTwoFactorAuth = 'disableTwoFactorAuth',
  challengeTwoFactorAuth = 'challengeTwoFactorAuth',
  backupTwoFactorAuth = 'backupTwoFactorAuth',
  updateProfileSettings = 'updateProfileSettings',
  uploadProfileAvatar = 'uploadProfileAvatar',
  changePassword = 'changePassword',
}

export type TQrCodeData = {
  svg: string | Node;
  url: string;
  backup_codes: string[];
};

export type TQrCodeResponse = {
  data: TQrCodeData;
  message: string;
} | null;

export type TTwoFactorAuthResponse = {
  data: string[];
  message: string;
} | null;

export const useQrCodeQuery = (
  options?: UseQueryOptions<TQrCodeResponse>,
) => {
  return useSafeQuery<TQrCodeResponse>(
    [UserSettingKeys.getQrCode],
    async () => {
      try {
        const res = await getQrCode();

        return res?.data;
      } catch (error) {
        return null;
      }
    },
    options,
  );
};

export const useGenerateBackupCodeMutation = () =>
  useMutation({
      mutationKey: [UserSettingKeys.generateBackupCode],
      mutationFn: generateBackupCode,
  });

export const useEnableTwoFactorAuthMutation = () =>
  useMutation({
      mutationKey: [UserSettingKeys.enableTwoFactorAuth],
      mutationFn: enableTwoFactorAuth,
  });

export const useVerifyTwoFactorAuthMutation = () =>
  useMutation({
      mutationKey: [UserSettingKeys.verifyTwoFactorAuth],
      mutationFn: verifyTwoFactorAuth,
  });

export const useChallengeTwoFactorAuthMutation = () =>
  useMutation({
      mutationKey: [UserSettingKeys.challengeTwoFactorAuth],
      mutationFn: challengeTwoFactorAuth,
  });

export const useRecoverTwoFactorAuthMutation = () =>
  useMutation({
      mutationKey: [UserSettingKeys.backupTwoFactorAuth],
      mutationFn: backupTwoFactorAuth,
  });

export const useDisableTwoFactorAuthMutation = () =>
  useMutation({
      mutationKey: [UserSettingKeys.disableTwoFactorAuth],
      mutationFn: disableTwoFactorAuth,
  });

export const useUpdateProfileSettingsMutation = () =>
  useMutation({
      mutationKey: [UserSettingKeys.updateProfileSettings],
      mutationFn: updateProfileSettings,
  });

export const useUploadProfileAvatarMutation = () =>
  useMutation({
    mutationKey: [UserSettingKeys.uploadProfileAvatar],
    mutationFn: uploadProfileAvatar,
  });

  export const useChangePasswordMutation = () =>
  useMutation({
    mutationKey: [UserSettingKeys.changePassword],
    mutationFn: changePassword,
  });  
  