import { colors } from '@components/theme/gen2';
import { Components, Theme } from '@mui/material';
import { rem } from 'polished';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...(ownerState.color === 'secondary' && {
        color: colors.grayDark1,
        '& .MuiSvgIcon-root': {
          fontSize: rem(18),
        },
        '&.Mui-checked': {
          color: colors.secondary,
        },
      }),
    }),
  },
};

export const MuiFormControlLabel: Components<Theme>['MuiFormControlLabel'] = {
  styleOverrides: {
    label: {
      fontSize: rem(14),
    },
  },
};
