import axios from '@gen2/api/axios.request';
import { config } from '@gen2/config';
import { AxiosPromise } from "axios";

export interface IResetPasswordForm {
  password?: string;
  password_confirmation?: string;
}

export type TResetPasswordParams = {
  email: string | null;
  token: string | null;
  signature: string | null;
  expires: string | null;
};

export type TResetPasswordPayload = {
  data: IResetPasswordForm,
  params: TResetPasswordParams
}

export type TResetPasswordErrorResponse = {
  errors?: {
    password?: string[],
    password_confirmation?: string[]
  },
  message?: string,
}

export const resetPassword = (
  payload: TResetPasswordPayload
): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/reset-password`,
    params: payload.params,
    data: payload.data,
    method: 'put',
    headers: {
      Accept: 'application/json',
      Authorization: '',
    },
  });
};
