import { create } from 'zustand';

export type UpgradeModal = {
  isOpen: boolean;
  title?: string;
  description?: string;
};

export interface IFollowUpStore extends UpgradeModal {
  setUpgradeModal: (upgradeModal: UpgradeModal) => void;
}

export const useFollowUpStore = create<IFollowUpStore>()((set) => ({
  isOpen: false,
  title: '',
  description: '',
  setUpgradeModal: (upgradeModal) => set(upgradeModal),
}));
