import { useSafeQuery } from '@gen2/hooks';
import { useMutation, UseQueryOptions } from '@tanstack/react-query';
import {
  deleteTemplates,
  getTemplates,
  renameTemplate,
  saveTemplate,
  selectTemplate,
  TGetTemplatesRequest,
  TGetTemplatesResponse,
} from './api';

export enum TemplateKeys {
  saveTemplate = 'saveTemplate',
  getTemplates = 'getTemplates',
  selectTemplate = 'selectTemplate',
  renameTemplate = 'renameTemplate',
  deleteTemplates = 'deleteTemplates',
}

export const useSelectTemplateMutation = () =>
  useMutation({
    mutationFn: selectTemplate,
    mutationKey: [TemplateKeys.selectTemplate],
  });

export const useSaveTemplateMutation = () =>
  useMutation({
    mutationFn: saveTemplate,
    mutationKey: [TemplateKeys.saveTemplate],
  });

export const useTemplatesQuery = (
  params?: TGetTemplatesRequest,
  options?: UseQueryOptions<TGetTemplatesResponse['data']>,
) =>
  useSafeQuery(
    [TemplateKeys.getTemplates, ...(Object.values(params ?? {}) as [])],
    async (): Promise<TGetTemplatesResponse['data']> => {
      const res = await getTemplates(params);
      return res?.data?.data;
    },
    {
      ...options,
    },
  );

export const useRenameTemplateMutation = () =>
  useMutation({
    mutationFn: renameTemplate,
    mutationKey: [TemplateKeys.renameTemplate],
  });

export const useDeleteTemplatesMutation = () =>
  useMutation({
    mutationFn: deleteTemplates,
    mutationKey: [TemplateKeys.deleteTemplates],
  });
