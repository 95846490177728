import { colors, textSizes, weights } from '@components/theme/gen2';
import {
  Avatar,
  Button,
  keyframes,
  Popper,
  Stack,
  styled,
} from '@mui/material';
import { rem } from 'polished';

export const StyledContactPopper = styled(Popper)({
  zIndex: 20,
});

export const StyledContainer = styled('div')({
  borderRadius: rem(10),
  border: `${rem(1)} solid ${colors.grayLight1}`,
  boxShadow: `0 ${rem(4)} ${rem(6)} 0 rgba(0,0,0,0.15)`,
  backgroundColor: colors.white,
  marginLeft: rem(10),
  marginRight: rem(10),
  maxWidth: rem(400),
});

export const StyledContactPopupHeader = styled(Stack)({
  marginBottom: rem(30),
  paddingTop: rem(15),
  paddingLeft: rem(15),
  paddingRight: rem(15),
});

export const StyledContactAddBtn = styled(Button)({
  flexShrink: 0,
});

export const StyledContactHeading = styled('h3')({
  fontSize: rem(textSizes.sm),
  textTransform: 'uppercase',
  fontWeight: weights.medium,
  margin: 0,
  paddingLeft: rem(15),
});

export const StyledContactList = styled(Stack)({
  paddingBottom: rem(15),
  maxHeight: rem(300),
  overflowY: 'auto',

  '&::-webkit-scrollbar': {
    width: rem(8),
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: colors.grayLight2,
    borderRadius: rem(10),
  },

  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: colors.grayDark1,
  },
});

export const StyledContactListItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: rem(10),
  padding: `${rem(6)} ${rem(15)}`,
  '&:hover': {
    backgroundColor: colors.grayLight2,
  },
});

export const StyledBox = styled('div')<{ disabled: boolean }>(
  ({ disabled }) => ({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: rem(10),
    cursor: disabled ? 'not-allowed' : 'pointer',
    pointerEvents: disabled ? 'none' : 'auto',
    opacity: disabled ? 0.5 : 1,
  }),
);

export const StyledEditIcon = styled('span')({
  cursor: 'pointer',
  '&:hover': {
    color: colors.secondary,
    transform: 'scale(1.2)',
  },
});

export const StyledNoResults = styled('p')({
  color: colors.grayDark2,
  textAlign: 'center',
  margin: 0,
  padding: `${rem(10)} ${rem(15)}`,
});

export const StyledContactAvatar = styled(Avatar)({
  width: rem(28),
  height: rem(28),
  fontSize: rem(textSizes.sm),
  backgroundColor: 'transparent',
  color: colors.grayDark2,
  border: `${rem(1)} solid ${colors.grayDark2}`,
});

export const StyledContactName = styled('span')({});

export const StyledContactEmail = styled('span')({
  color: colors.grayDark2,
});

export const StyledLoadingContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: rem(300),

  '& svg': {
    fontSize: rem(30),
    color: colors.grayDark2,
    zIndex: 1,
  },
});

export const ellipsisKeyframes = keyframes({
  to: {
    width: '1.25em',
  },
});

export const StyledLoadingEllipsisContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledLoadingEllipsis = styled('div')`
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ${ellipsisKeyframes} 1s steps(4, end) infinite;
  width: 0px;
`;

export const StyledSkeletonContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '40px 1fr',
  gridColumnGap: rem(10),
  paddingLeft: rem(15),
  paddingRight: rem(15),
});
