import axios from '@gen2/api/axios.request';
import { config } from '@gen2/config';
import { AxiosPromise } from "axios";

export interface ISetPasswordForm {
  password?: string;
  password_confirmation?: string;
}

export type TSetURLParams = {
  uuid?: string;
  hash?: string;
};

export type TSetPasswordParams = {
  expires: string | null;
  signature: string | null;
};

export type TSetPasswordPayload = {
  data: ISetPasswordForm,
  params: TSetPasswordParams,
  urlParams: TSetURLParams,

}

export type TSetPasswordErrorResponse = {
  errors?: {
    password?: string[],
    password_confirmation?: string[]
  },
  message?: string,
}

export const setPassword = (
  payload: TSetPasswordPayload
): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/signup-invite/${payload.urlParams.uuid}/${payload.urlParams.hash}`,
    params: payload.params,
    data: payload.data,
    method: 'put',
    headers: {
      Accept: 'application/json',
      Authorization: '',
    },
  });
};
