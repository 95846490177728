import {
  QueryFunctionContext,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosPromise } from 'axios';
import { useToast } from './useToast';

export type QUERY_KEYS = string[];

export declare type API_REQUEST<
  T = unknown,
  TQueryKey extends QueryKey = QueryKey,
> = (
  context: QueryFunctionContext<TQueryKey>,
) => T | Promise<T> | AxiosPromise<T>;

export function useSafeQuery<TResponse>(
  keys: QUERY_KEYS,
  apiRequest: API_REQUEST<TResponse, QueryKey>,
  options?: UseQueryOptions<TResponse>,
) {
  const toast = useToast();

  const query = useQuery<TResponse>({
    queryKey: keys,
    queryFn: apiRequest as unknown as (
      context: QueryFunctionContext,
    ) => TResponse,
    ...options,
    retry: 3,
    retryDelay: (attempt: number) =>
      Math.min(attempt > 1 ? 2 ** attempt * 1000 : 6000, 3 * 1000),
    onError: (error) => {
      // TODO: Do error handling here later in accordance with the api response
      const newError: Error = error as Error;

      toast.show({
        text: newError.message,
        variant: 'error',
      });
    },
  });

  return query;
}
