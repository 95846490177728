import { colors, headingType, weights } from '@components/theme/gen2';
import { Box, IconButton, styled } from '@mui/material';
import { TagsLine } from '@nx-fe/components';
import { rem } from 'polished';
import { SendToLine } from '../send-to-line/send-to-line';

export const StyledLayout = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    height: `calc(100% - var(--sidebar-header-height) - var(--footer-height))`,
  },
}));

export const StyledBox = styled('div')({
  position: 'relative',
  height: '100%',
  flex: 1,
});

export const StyledMessageBoxContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'calc(100vh - var(--header-height))',
  position: 'absolute',
  backgroundColor: 'lightgray',
  zIndex: 200,
  left: 0,
  top: 0,
  display: 'block',
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - var(--header-height) - var(--sidebar-header-height))',
  },
}));

export const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  top: rem(10),
  right: rem(15),
  width: rem(30),
  height: rem(30),
  backgroundColor: colors.grayDark4,
  color: colors.white,
  '&:hover': {
    backgroundColor: colors.grayDark3,
  },
});

export const StyledMain = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'inherit',
  flexGrow: 'inherit',
  [theme.breakpoints.down('sm')]: {
    height: `calc(100vh - var(--header-height) - var(--footer-height) - var(--sidebar-header-height))`,
  },
}));

export const StyledHeader = styled('div')({
  flex: '0 0 auto',
  minHeight: rem(154),
  background: colors.white,
  borderBottom: `1px solid ${colors.grayLight1}`,
});

export const StyledBody = styled('div')(({ theme }) => ({
  padding: rem(50),
  flex: '1 1 auto',
  background: colors.grayLight2,
  maxHeight: 'calc(100vh - 154px - 60px)',
  overflowY: 'auto',
  [theme.breakpoints.down('xl')]: {
    padding: rem(35),
  },
  [theme.breakpoints.down('md')]: {
    padding: rem(20),
    maxHeight: 'fit-content',
  },
}));

export const ActionBar = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: `${rem(10)} ${rem(30)} ${rem(10)} ${rem(50)}`,
  height: rem(60),
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${colors.grayLight1}`,
  [theme.breakpoints.down('md')]: {
    borderRight: `1px solid ${colors.grayLight1}`,
    padding: `${rem(10)} ${rem(50)}`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${rem(10)} ${rem(16)}`,
  },
  '> section': {
    display: 'flex',
    alignItems: 'center',
    columnGap: rem(10),
    '> a': {
      color: colors.grayDark3,
    },
    '> h4': {
      margin: 0,
      fontSize: rem(18),
      fontWeight: weights.semiBold,
      color: colors.grayDark3,
      fontFamily: headingType,
    },
  },
  //placeholder
  '> div': {
    width: rem(477),
  },
}));

export const Details = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: rem(88),
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const FlexBox = styled('div')({
  flex: `1 1 auto`,
  borderRight: `1px solid ${colors.grayLight1}`,
});

export const SendTo = styled(SendToLine)({
  minHeight: rem(45),
  width: '100%',
  borderBottom: `1px solid ${colors.grayLight1}`,
});

export const SendFrom = styled(TagsLine)(({ theme }) => ({
  width: '100%',
  minHeight: rem(45),
  [theme.breakpoints.down('md')]: {
    borderBottom: `1px solid ${colors.grayLight1}`,
  },
}));

export const Reminders = styled('div')(({ theme }) => ({
  flex: `0 0 ${rem(300)}`,
  alignItems: 'center',
  justifyContent: 'space-around',
  display: 'flex',
  padding: `0 ${rem(10)}`,
  '&:has(div:nth-child(3))': {
    flexGrow: 1,
    maxWidth: rem(400),
  },
  [theme.breakpoints.down('xl')]: {
    flexGrow: 0,
    maxWidth: rem(200),
    flexBasis: 'auto',
    flexDirection: 'column',
    textAlign: 'left',
    '&:has(div:nth-child(3))': {
      '& *': {
        display: 'inline-flex',
        justifyContent: 'start',
        alignItems: 'center',
        alignSelf: 'start',
        flewWrap: 'wrap',
      },

      '& div': {
        width: '100%',
      },

      '& div p': {
        alignSelf: 'center',
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    flex: `1 1 auto`,
    flexDirection: 'row',
    maxWidth: 'unset',
    borderRight: `1px solid ${colors.grayLight1}`,
    height: rem(45),
    justifyContent: 'flex-start',
    padding: `0 ${rem(50)}`,
    columnGap: rem(50),
    '&:has(div:nth-child(3))': {
      width: '100%',
      maxWidth: 'unset',
      flexDirection: 'row',
      columnGap: rem(50),
      '& *': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        flexWrap: 'unset',
        whiteSpace: 'nowrap',
      },

      '& div': {
        width: 'unset',
      },

      '& div p': {
        alignSelf: 'unset',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    padding: `0 ${rem(16)}`,
    '&:has(div:nth-child(3))': {
      flexWrap: 'wrap',
      height: 'auto',
    },
  },
}));

export const DateFrame = styled('div')(({ theme }) => ({
  '> h6': {
    margin: rem(3),
    fontSize: rem(15),
    fontWeight: weights.semiBold,
    color: colors.grayDark2,
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const LoadingBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    height: rem(300),
  },
}));
