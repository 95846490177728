import { colors, weights } from '@components/theme/gen2';
import { Autocomplete } from '@mui/material';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledAutoComplete = styled(Autocomplete)`
  .MuiAutocomplete-tag {
    margin: 0;
    height: ${rem(26)};
    font-weight: ${weights.semiBold};
    color: ${colors.grayDark3};
    gap: ${rem(4)};
  }

  &.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root {
    padding-top: ${rem(7)};
    padding-bottom: ${rem(7)};
  }

  .MuiAutocomplete-inputRoot {
    gap: ${rem(4)};
  }

  .MuiChip-deleteIcon {
    margin: 0;
  }
` as typeof Autocomplete;
