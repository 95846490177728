import { Player } from '@lottiefiles/react-lottie-player';
import { FC } from 'react';
import { StyledWrapper } from '../login/Login.styled';

const SplashScreen: FC = () => {
  return (
    <StyledWrapper>
      <Player
        autoplay
        loop
        src="/assets/initializing.json"
        style={{ height: '53px', width: '250px' }}
      ></Player>
    </StyledWrapper>
  );
};

export default SplashScreen;
