import { noop } from 'lodash';
import { create } from 'zustand';

export interface ConfirmationModalContent {
  title: string;
  message: string;
  submitLabel: string;
  submitColor: 'primary' | 'secondary' | 'danger';
}

export type ConfirmationModalProps = ConfirmationModalContent & {
  isConfirmationModalOpen?: boolean;
  isLoading: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
};

export type ConfirmationModalStore = {
  setIsConfirmationModalOpen: (open: boolean) => void;
  setConfirmationModalProps: (props: ConfirmationModalProps) => void;
  showConfirmationModal: (content: ConfirmationModalContent) => Promise<{
    isConfirmed: boolean;
  }>;
  closeConfirmationModal: () => void;
  confirm: () => void;
} & ConfirmationModalProps;

export const useConfirmationModalStore = create<ConfirmationModalStore>()(
  (set) => ({
    isConfirmationModalOpen: false,
    isLoading: false,
    title: '',
    submitLabel: '',
    submitColor: 'primary',
    message: '',
    closeConfirmationModal: noop,
    confirm: noop,
    setIsConfirmationModalOpen: (open: boolean) =>
      set({ isConfirmationModalOpen: open }),
    setConfirmationModalProps: (props: ConfirmationModalProps) =>
      set({ ...props }),
    showConfirmationModal: async (content: ConfirmationModalContent) => {
      const res = await new Promise((resolve) => {
        set({
          isConfirmationModalOpen: true,
          closeConfirmationModal: () => {
            set({
              isConfirmationModalOpen: false,
            });

            resolve({
              isConfirmed: false,
            });
          },
          confirm: () => {
            set({
              isConfirmationModalOpen: false,
            });

            resolve({
              isConfirmed: true,
            });
          },
          ...content,
        });
      });

      return res as { isConfirmed: boolean };
    },
  }),
);
