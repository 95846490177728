export const licenseKey = () => {
  return 'FileInvite Limited:OEM:FileInvite Limited::B+:AMS(20250304):C7DC3F0C074A2AF37B313AC9B24338264E6F7FC98B46E08D4215591C1C00F65AAAB6F5C7';
}

export function customEncode(key: string) {
    const modifiedKey = key.split('').map(char => char + '*').join('');
    return window.btoa(modifiedKey);
}

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $zfeg48: any;
    }
}

window.$zfeg48 = customEncode(licenseKey());

export function customDecode(encodedKey: string) {
    const decodedKey = window.atob(encodedKey);
    return decodedKey.split('').filter((_, index) => index % 2 === 0).join('');
}
