export const primary = '#EFA707';
export const primaryDark1 = '#CB8E06';
export const primaryDark2 = '#BC7400';
export const primaryDark3 = '#3D0000';
export const primaryLight1 = '#F2B733';
export const primaryLight2 = '#F5CA6A';
export const primaryLight3 = '#FCEDCD';
export const primaryLight4 = '#FEF8EB';

export const secondary = '#3E7BAD';
export const secondaryDark1 = '#256293';
export const secondaryDark2 = '#0B487A';
export const secondaryDark3 = '#001547';
export const secondaryLight1 = '#6595BD';
export const secondaryLight2 = '#8CB8DB';
export const secondaryLight3 = '#ACCDE8';
export const secondaryLight4 = '#DEEBF6';

export const tertiary = '#425B76';

export const red = '#BF4147';
export const redDark1 = '#A6282E';
export const redDark2 = '#8C0E14';
export const redDark3 = '#730000';
export const redLight1 = '#C65459';
export const redLight2 = '#F2ACAF';
export const redLight3 = '#F7C8CB';
export const redLight4 = '#FCE9EA';

export const blue = '#4098D8';
export const blueDark1 = '#277FBE';
export const blueDark2 = '#0D65A5';
export const blueDark3 = '#004C8B';
export const blueLight1 = '#66ADE0';
export const blueLight2 = '#9CCCF0';
export const blueLight3 = '#BCDEF7';
export const blueLight4 = '#E4F2FC';

export const green = '#86AF58';
export const greenDark1 = '#6D953F';
export const greenDark2 = '#537C25';
export const greenDark3 = '#204900';
export const greenLight1 = '#9EBF79';
export const greenLight2 = '#C0D7A6';
export const greenLight3 = '#D6EBBE';
export const greenLight4 = '#EEF7E5';

export const orange = '#F79119';
export const orangeDark1 = '#DD7800';
export const orangeDark2 = '#C45E00';
export const orangeDark3 = '#AB3300';
export const orangeLight1 = '#F89C30';
export const orangeLight2 = '#F9B25E';
export const orangeLight3 = '#FCD3A3';
export const orangeLight4 = '#FEEDDA';

export const gray = '#BDC6CF';
export const grayDark1 = '#97A5B4';
export const grayDark2 = '#71737A';
export const grayDark3 = '#4D4E56';
export const grayDark4 = '#2B2C34';
export const grayLight1 = '#D9DEE4';
export const grayLight2 = '#ECEFF1';
export const grayLight3 = '#F6F7F8';

export const white = '#ffffff';
export const black = '#000000';

export const info = blue;
export const success = green;
export const warning = orange;
export const danger = red;
