import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledCreateRequest } from './create-request.styled';

export const CreateRequest = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
) => {
  return (
    <StyledCreateRequest {...props}>
      <FontAwesomeIcon icon={regular('plus')} />
      <span>Create a Request</span>
    </StyledCreateRequest>
  );
};
