import { AxiosResponse } from 'axios';
import { omit } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GlobalRegion, useGlobalRegionsQuery } from '../../../api/global-region/hooks';
import { ISocialLogin } from '../../../api/login/api';
import {
  useSocialLoginExchangeCodeMutation,
  useSocialLoginMutation,
} from '../../../api/login/hooks';
import { StyledLogo, StyledWrapper } from '../../../app/login/Login.styled';
import { useAuth, useRouter } from '../../../hooks';
import { AuthCodeExchangeVerifierFactory } from '../../../services/social-login/AuthCodeExchangeVerifierFactory'
import { generateStateString, removeStateString } from '../../../utils/auth';
import RegionModal from './region-modal/region-modal';

export const SocialLoginCallback = () => {
  const { mutateAsync: exchangeCodeMutation } = useSocialLoginExchangeCodeMutation();
  const { mutateAsync: socialLoginMutation } = useSocialLoginMutation();
  const [openRegionModal, setOpenRegionModal] = useState(false);
  const [oauthSignInData, setOauthSignInData] = useState<ISocialLogin>();
  const { authCode } = useAuth();
  const stateString = generateStateString();
  const [searchParams] = useSearchParams();
  const { data: regionsData } = useGlobalRegionsQuery();
  const router = useRouter();

  const beenInitialised = useRef<boolean>(false);

  const { protocol, host, pathname } = window.location;
  const redirectUri = `${protocol}//${host}${pathname}`;

  const regions = useMemo(() => {
    return regionsData ?? [];
  }, [regionsData]);

  const { identityProvider } = router.query;

  useEffect(() => {
    const exchange = async (code: string, scope: string, state: string ) => {

      if (!code || stateString !== (state.split('|')[1] ?? state)) {
        return;
      }

      removeStateString();

      const { protocol, host, pathname } = window.location;
      const socialLoginData: ISocialLogin = {
        authorisation_code: code,
        type: identityProvider,
        redirect_uri: `${protocol}//${host}${pathname}`,
        scope: scope,
      };

      setOauthSignInData(socialLoginData);

      try {
        const exchangeResponse = await exchangeCodeMutation(socialLoginData) as AxiosResponse;

        const { region } = exchangeResponse.data.data;

        const selectedRegion = regions?.find((r: GlobalRegion) => r.name === region);

        if (selectedRegion) {
          localStorage.setItem('regionEndpoint', selectedRegion.endpoint);

          await socialLoginMutation(omit(socialLoginData, ['scope']));

          authCode();
        }
      } catch (error: unknown) {
        const err = error as AxiosResponse;
        if(err.status && err.status === 412) {
          setOpenRegionModal(true);
        } else {
          router.navigate(`/login?social_error=${true}`);
        }
      }
    };

    if (searchParams.has('error')) {
      removeStateString();

      router.navigate('/login');
    }

    if (regions.length > 0 && searchParams.has('code')
        && searchParams.has('state') && !beenInitialised.current
      ) {

        beenInitialised.current = true;

        const authCodeVerifierFactory = AuthCodeExchangeVerifierFactory.factory(identityProvider);

        const { code, state } = Object.fromEntries(searchParams.entries());
        const scope = authCodeVerifierFactory.parseScope(searchParams.get('scope'));

        exchange(code, scope, authCodeVerifierFactory.parseState(state));
    }
  }, [
        identityProvider,
        authCode,
        exchangeCodeMutation,
        redirectUri,
        regions,
        router,
        searchParams,
        socialLoginMutation,
        stateString
      ]
);

  return (
    <>
      <RegionModal
        open={openRegionModal}
        oauthSignInData={oauthSignInData}
        onClose={() => {
          setOpenRegionModal(false);
        }}
        regions={regions}
      />

      <StyledWrapper>
        <StyledLogo />
      </StyledWrapper>
    </>
  );
};
