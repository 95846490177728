import dayjs, { ManipulateType, OpUnitType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(utc);

/***
 * @param utcDate  it is an UTC time
 * **/
type UtcDate = string | number | Date | dayjs.Dayjs | null | undefined;

export const timeToUtcObject = (utcDate: UtcDate) => {
  if (!utcDate) return '-';
  return dayjs.utc(utcDate);
};

export const utcToLocal = (utcDate: UtcDate) => {
  if (!utcDate) return '-';
  return dayjs.utc(utcDate).local().format('DD MMM, YYYY h:mm A');
};

export const utcToLocalDate = (utcDate: UtcDate) => {
  if (!utcDate) return '-';
  return dayjs.utc(utcDate).local().format('DD MMM, YYYY');
};

export const utcToLocalYYMMM = (utcDate: UtcDate) => {
  if (!utcDate) return '-';
  return dayjs.utc(utcDate).local().format('MMM, YY');
};

export const isHistoryDate = (utcDate: UtcDate) => {
  return !utcDate ? '-' : dayjs.utc().isSameOrAfter(dayjs(utcDate));
};

// The result equals that utcDate plus n with unit
export const increaseTimeToLocal = (
  utcDate: UtcDate,
  n = 0,
  unit: ManipulateType = 'hour',
) =>
  !utcDate
    ? '-'
    : dayjs.utc(utcDate).local().add(n, unit).format('MMM DD, YYYY h:mm A');

export const diffFromNow = (utcDate: UtcDate) =>
  !utcDate ? '-' : dayjs.utc(utcDate).diff(dayjs().utc());

export const utcNow = (formats?: string) =>
  dayjs.utc().format(formats || 'YYYY-MM-DDTHH:mm:ss.SSS');

export const relativeUtcToNow = (utcDate: UtcDate) =>
  !utcDate ? '-' : dayjs.utc(utcDate).from(dayjs.utc());

export const utcToTimestamp = (utcDate: UtcDate) =>
  dayjs.utc(utcDate).valueOf();

export const utcToFormats = (utcDate: UtcDate, formats?: string) =>
  !utcDate ? '-' : dayjs.utc(utcDate).format(formats ?? 'MMM DD, YYYY');

export const timeToFormats = (utcDate: UtcDate, formats?: string) =>
  !utcDate ? '-' : dayjs(utcDate).format(formats ?? 'MMM DD, YYYY');

export const utcToSimpleLocal = (utcDate: UtcDate, formats?: string) => {
  if (!utcDate) return '-';
  return dayjs
    .utc(utcDate)
    .local()
    .format(formats ?? 'MMM DD, YYYY h:mm A');
};

/**
 * Parameter 4 is a string with two characters; '[' means inclusive, '(' exclusive
 * '()' excludes start and end date (default)
 * '[]' includes start and end date
 * '[)' includes the start date but excludes the stop
 */
type Containment = '[)' | '()' | '[]' | '(]';
type Unit = OpUnitType | null | undefined;
export const utcIsBetween = (
  utcDate: UtcDate,
  startDate: UtcDate,
  endDate: UtcDate,
  unit: Unit = 'day',
  containment: Containment = '[]',
) =>
  !utcDate
    ? '-'
    : dayjs(utcDate).isBetween(startDate, endDate, unit, containment);

export const getHm = (utcDate: UtcDate) => {
  if (!utcDate) return '-';
  return dayjs(utcDate).format('HH:mm:ss');
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(() => resolve(1), ms || 1000));

export const getCurrentTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

// in case 00:00:00 start of day will fail in the endpoinn
export const toEndDayOfOrgTimezone = (utcDate: UtcDate) => {
  if (!utcDate) return null;
  return dayjs(utcDate).endOf('day');
}

export const utcToOrgTimezone = (utcDate: UtcDate, timezone: string | undefined) => {
  if (!utcDate) return null;
  return dayjs(utcDate).tz(timezone).format('DD MMM, YYYY');
}