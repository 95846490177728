export type ImageMimeType = 'image/jpeg' | 'image/png' | 'image/tiff' | 'image/webp' | 'image/heic' | 'image/heif';
export type WordMimeType = 'application/msword' | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export type PPTMimeType = 'application/vnd.ms-powerpoint' | 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
export type ExcelMimeType = 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export type PDFMimeType = 'application/pdf';
export type CSVMimeType = 'text/csv';
export type ZipMimeType = 'application/zip';
export type PlainTextMimeType = 'text/plain';
export type OpenDocumentMimeType = 'application/vnd.oasis.opendocument.presentation' | 'application/vnd.oasis.opendocument.spreadsheet' | 'application/vnd.oasis.opendocument.text'
export type PagesMineType = "application/x-iwork-pages-sffpages";
export type KeynoteMineType = "application/x-iwork-keynote-sffkey";
export type NumbersMineType = "application/x-iwork-numbers-sffnumbers";

export type MimeType = PlainTextMimeType | PDFMimeType
    | ZipMimeType | CSVMimeType
    | ImageMimeType | WordMimeType | PPTMimeType | ExcelMimeType | OpenDocumentMimeType
    | PagesMineType | KeynoteMineType | NumbersMineType

// Image MIME types
export const imageMimeTypes = [
  'image/jpeg', // .jpeg, .jpg
  'image/heic', // .heic
  'image/heif',  // .heif
  'image/png'  // .png
];

// Word MIME types
export const wordMimeTypes = [
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // .docx
];

// PPT MIME types
export const pptMimeTypes = [
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation' // .pptx
];

// Excel MIME types
export const excelMimeTypes = [
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // .xlsx
];

// PDF MIME types
export const pdfMimeTypes = [
  'application/pdf' // .pdf
];

// CSV MIME types
export const csvMimeTypes = [
  'text/csv' // .csv
];

// Plain text MIME types
export const plainTextMimeTypes = [
  'text/plain' // .txt
];

// Define an array of allowed MIME types
export const allowedMimeTypes = [
    ...pdfMimeTypes,
    ...wordMimeTypes,
    ...excelMimeTypes,
    ...pptMimeTypes,
    ...plainTextMimeTypes,
    ...imageMimeTypes,
    ...csvMimeTypes,
];

/**
 * MIME types to File Extension Mapping
 * For display purpose only
 */
export const mimeToExtensionMap: Record<string, string[]> = {
  'application/pdf': ['pdf'],
  'text/csv': ['csv'],
  'application/msword': ['doc', 'docx'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['docx'],
  'application/vnd.ms-excel': ['xls', 'xlsx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['xlsx'],
  'application/vnd.ms-powerpoint': ['ppt', 'pptx'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['pptx'],
  'text/plain': ['txt'],
  'image/jpeg': ['jpeg', 'jpg'],
  'image/png': ['png'],
  'image/heic': ['heic', 'heif'],
  'image/heif': ['heic', 'heif']
};

// Define an array of MIME types that can't be previewed
export const previewUnavailableMimeTypes = [
  ...csvMimeTypes,
];

export const allowedFileTypes = () => {
  const groupedExtensions = allowedMimeTypes.reduce((acc, mimeType) => {
    if (mimeToExtensionMap[mimeType]) {
      const extensions = mimeToExtensionMap[mimeType].map(ext => `.${ext}`).join('/');

      if (acc.findIndex((item) => item.includes(extensions)) < 0) {
        acc.push(extensions);
      }
    }
    return acc;
  }, [] as string[]);

  return groupedExtensions.join(', ');
};
