import { colors, headingType, weights } from '@components/theme/gen2';
import { Components, IconButton, styled, Theme } from '@mui/material';
import { rem } from 'polished';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  styleOverrides: {
    paper: {
      overflow: 'visible',
    },
  },
};

export const MuiDialogTitle: Components<Theme>['MuiDialogTitle'] = {
  styleOverrides: {
    root: {
      fontWeight: weights.semiBold,
      fontSize: rem(20),
      fontFamily: headingType,
      color: colors.grayDark3,
      paddingTop: rem(15),
      paddingBottom: rem(15),
      paddingLeft: rem(30),
      paddingRight: rem(30),
    },
  },
};

export const MuiDialogContent: Components<Theme>['MuiDialogContent'] = {
  styleOverrides: {
    root: {
      padding: rem(30),
    },
    dividers: {
      borderBottom: 'none',
    },
  },
};

export const MuiDialogContentText: Components<Theme>['MuiDialogContentText'] = {
  styleOverrides: {
    root: {
      fontSize: rem(14),
      color: colors.grayDark2,
    },
  },
};

export const MuiDialogActions: Components<Theme>['MuiDialogActions'] = {
  styleOverrides: {
    root: {
      backgroundColor: colors.grayLight3,
      padding: rem(20),
      borderBottomLeftRadius: rem(10),
      borderBottomRightRadius: rem(10),
    },
    spacing: {
      gap: rem(15),
    },
  },
};

export const StyledModalCloseButton = styled(IconButton)({
  position: 'absolute',
  top: rem(-5),
  right: rem(-5),
  width: rem(30),
  height: rem(30),
  fontSize: rem(16),
  backgroundColor: colors.grayDark4,
  color: colors.white,

  '&:hover': {
    backgroundColor: colors.grayDark4,
  },
});
