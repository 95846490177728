import { colors, weights } from '@components/theme/gen2';
import { styled } from '@mui/material';
import { rem } from 'polished';

export const StyledHintList = styled('div')({
  padding: `${rem(20)} ${rem(25)}`,
  backgroundColor: colors.grayLight3,
  border: `${rem(1)} solid ${colors.grayLight1}`,
  borderRadius: rem(4),
});

export const StyledHintListTitle = styled('p')({
  fontSize: rem(13),
  fontWeight: weights.medium,
});

export const StyledUl = styled('ul')({
  listStyle: 'none',
  padding: 0,
});

export const StyledHintListItem = styled('li')<{ active?: boolean }>(
  ({ active }) => ({
    fontSize: rem(12),
    display: 'flex',
    alignItems: 'center',
    gap: rem(8),
    lineHeight: rem(20),
    color: active ? colors.success : 'unset',
  }),
);
