import { AuthCodeExchangeVerifier } from "./AuthCodeExchangeVerifier";
import { IAuthCodeExchangeVerifier } from "./IAuthCodeExchangeVerifier";
import * as microsoft from "./microsoft/AuthCodeExchangeVerifier";

export class AuthCodeExchangeVerifierFactory
{
  public static factory(identityProvider: string): IAuthCodeExchangeVerifier {
    if (identityProvider === 'microsoft') {
      return new microsoft.AuthCodeExchangeVerifier();
    }

    return new AuthCodeExchangeVerifier();
  }
}
