import {
  colors,
  headingSizes,
  textSizes,
  weights,
} from '@components/theme/gen2';
import { IconButton, Stack, styled } from '@mui/material';
import { rem } from 'polished';
import { NavLink } from 'react-router-dom';

export const StyledHeader = styled('header')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  backgroundColor: 'white',
  marginTop: 'var(--sidebar-header-height)',
  height: 'var(--header-height)',
  display: 'flex',
  alignItems: 'center',
  borderBottom: `${rem(1)} solid ${colors.grayLight1}`,
  borderRight: `${rem(1)} solid ${colors.grayLight1}`,
  zIndex: 'var(--header-z)',
  [theme.breakpoints.up('sm')]: {
    position: 'sticky',
    gridRow: '1 / 1',
    gridColumn: '2 / -1',
    marginTop: 0,
    justifyContent: 'space-between',
    paddingTop: rem(10),
    paddingBottom: rem(10),
    paddingLeft: rem(50),
    paddingRight: rem(50),
  },
}));

export const HeaderTitle = styled('h3')({
  fontSize: headingSizes.h3,
  fontWeight: weights.semiBold,
  margin: 0,
  lineHeight: rem(20),
});

export const StyledIconButton = styled(IconButton)({
  color: colors.grayDark2,
  width: rem(40),
  height: rem(40),
});

export const StyledHeaderActions = styled(Stack)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

export const StyledSubMenuToggle = styled('button')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  width: rem(30),
  border: 'none',
  backgroundColor: colors.grayLight2,
  marginRight: rem(15),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const SubTitle = styled('span')(({ theme }) => ({
  fontWeight: weights.normal,
  color: colors.grayDark1,
  fontSize: textSizes.base,
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'inline-block',
  },
}));

export const StyledAvatar = styled(IconButton)({
  border: `1px solid ${colors.gray}`,
  padding: 0,
  fontSize: rem(16),
  fontWeight: weights.semiBold,
  width: rem(40),
  height: rem(40),
});

export const StyledNavLink = styled(NavLink)({
  color: 'inherit',
  textDecoration: 'none',
});
