export default function randomInteger(minimum: number, maximum?: number) {
    if (maximum === undefined) {
        maximum = minimum;
        minimum = 0;
    }

    if (typeof minimum !== 'number' || typeof maximum !== 'number') {
        throw new TypeError('Arguments should be numbers');
    }

    return Math.floor(
        (Math.random() * (maximum - minimum + 1)) + minimum
    );
}