import { styled } from '@mui/system';
import { StyledTabPanel, StyledTabPanelBody } from './tab-panel.styled';

export function TabPanel(props: {
  children?: React.ReactNode;
  index: number;
  value: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <StyledTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <StyledTabPanelBody>{children}</StyledTabPanelBody>}
    </StyledTabPanel>
  );
}

export const StyledDiv = styled('div')<{ $opaque: boolean }>(
  ({ $opaque }) => ({
    ...($opaque && {
      opacity: 0.5,
      pointerEvents: 'none',
      userSelect: 'none',
    }),
  }),
);
