import { colors, weights } from '@components/theme/gen2';
import { Components, Theme } from '@mui/material';
import { rem } from 'polished';

export const MuiAlert: Components<Theme>['MuiAlert'] = {
  defaultProps: {
    variant: 'standard',
  },
  styleOverrides: {
    root: {
      borderRadius: 0,
      fontSize: rem(14),
      fontWeight: weights.medium,
    },
    icon: {
      fontSize: rem(15),
      padding: `${rem(10)} 0`,
    },
    standardSuccess: {
      backgroundColor: colors.greenLight3,
      color: colors.greenDark2,
      border: `2px solid ${colors.greenLight2}`,
      '& .MuiAlert-icon': {
        color: colors.greenDark2,
      },
    },
    standardError: {
      backgroundColor: colors.redLight3,
      color: colors.redDark1,
      border: `2px solid ${colors.redLight2}`,
      '& .MuiAlert-icon': {
        color: colors.redDark1,
      },
    },
    standardWarning: {
      backgroundColor: colors.orangeLight3,
      color: colors.orangeDark2,
      border: `2px solid ${colors.orangeLight2}`,
      '& .MuiAlert-icon': {
        color: colors.orangeDark2,
      },
    },
    standardInfo: {
      backgroundColor: colors.blueLight3,
      color: colors.blueDark2,
      border: `2px solid ${colors.blueLight2}`,
      '& .MuiAlert-icon': {
        color: colors.blueDark2,
      },
    },
    action: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 0,
    },
    filled: {
      fontWeight: weights.normal,
    },
    filledSuccess: {
      backgroundColor: colors.greenLight3,
      color: colors.greenDark3,
      border: `2px solid ${colors.greenLight2}`,
      '& .MuiAlert-icon': {
        color: colors.greenDark3,
      },
    },
    filledInfo: {
      backgroundColor: colors.blueLight3,
      color: colors.blueDark3,
      border: `2px solid ${colors.blueLight2}`,
      '& .MuiAlert-icon': {
        color: colors.blueDark3,
      },
    },
    filledWarning: {
      backgroundColor: colors.orangeLight3,
      color: colors.orangeDark3,
      border: `2px solid ${colors.orangeLight2}`,
      '& .MuiAlert-icon': {
        color: colors.orangeDark3,
      },
    },
    filledError: {
      backgroundColor: colors.redLight3,
      color: colors.redDark1,
      border: `2px solid ${colors.redLight2}`,
      '& .MuiAlert-icon': {
        color: colors.redDark1,
      },
    },
  },
};
