import axios from '@gen2/api/axios.request';
import { config } from '@gen2/config';
import { AxiosPromise } from 'axios';
import { omit } from 'lodash';

export interface IGlobalLoginEmail {
  email: string;
}

export interface ILoginPassword {
  password: string;
  email: string;
}

export interface ISocialLoginExchangeCode {
  type: string;
  authorisation_code: string;
  redirect_uri: string;
  scope?: string;
}

export interface ISocialLogin {
  authorisation_code: string;
  type: string;
  redirect_uri: string;
  scope?: string;
}

export type IGlobalLoginEmailResponse = {
  data: {
    region: string;
  };
};

export const globalLoginEmail = (
  data: IGlobalLoginEmail,
): AxiosPromise<IGlobalLoginEmailResponse> =>
  axios.request({
    url: `/login`,
    params: data,
    method: 'get',
    headers: {
      Accept: 'application/json',
      Authorization: '',
      global: true,
    },
  });

export const loginPassword = (data: ILoginPassword): AxiosPromise<unknown> =>
  axios.request({
    url: `${config.apiVersion.v1}/login`,
    data,
    method: 'post',
    headers: {
      Accept: 'application/json',
      Authorization: '',
    },
  });

// global social login
export const socialLoginExchangeCode = (
  data: ISocialLoginExchangeCode,
): AxiosPromise<unknown> =>
  axios.request({
    url: `/social-login/${data.type}`,
    params: omit(data, ['type']),
    method: 'get',
    headers: {
      Accept: 'application/json',
      Authorization: '',
      global: true,
    },
  });

export const socialLogin = (data: ISocialLogin): AxiosPromise<unknown> =>
  axios.request({
    url: `${config.apiVersion.v1}/social-auth/${data.type}`,
    data,
    method: 'post',
    headers: {
      Accept: 'application/json',
      Authorization: '',
    },
  });

