import { Box, BoxProps, styled } from '@mui/material';
import { rem } from 'polished';

interface ImageAvatarProps extends BoxProps {
  imageurl: string;
}

export const ImageAvatar = styled(Box)<ImageAvatarProps>(({ imageurl }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  backgroundImage: `url(${imageurl})`,
  backgroundSize: 'cover',
  borderRadius: rem(20),
  backgroundPosition: 'center',
}));

export const ImageIconAvatar = styled('img')(() => ({
  display: 'flex',
  width: rem(28),
  height: rem(28),
  borderRadius: '50%',
}));

export const TextAvatar = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: rem(8),
});
