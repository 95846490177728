import { colors, textSizes } from '@components/theme/gen2';
import { rem } from 'polished';
import styled from 'styled-components';
import { ProgressBarColor } from './progress-bar';

export const StyledProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const StyledProgress = styled.progress<{ $color?: ProgressBarColor }>`
  width: 100%;
  border: none;
  border-radius: ${rem(3)};
  height: ${rem(6)};

  &::-webkit-progress-bar {
    background-color: ${colors.grayLight2};
    border-radius: ${rem(3)};
  }

  &::-webkit-progress-value {
    background-color: ${(props) =>
      props.$color
        ? (colors as Record<string, string>)[props.$color]
        : colors.primary};
    border-radius: ${rem(3)};
  }

  &[value] {
    background-color: ${colors.grayLight2};
  }

  &::-moz-progress-bar {
    background-color: ${(props) =>
      props.$color
        ? (colors as Record<string, string>)[props.$color]
        : colors.primary};
  }
`;

export const StyledProgressBarLabel = styled.label`
  font-size: ${textSizes.sm};
  margin-bottom: ${rem(2)};
  color: ${colors.grayDark2};
`;
