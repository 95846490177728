import { colors, opacity } from '@components/theme/gen2';
import { Components, Theme } from '@mui/material';
import { rem } from 'polished';

export const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  defaultProps: {
    disableFocusRipple: true,
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const styles = {
        width: ownerState.size === 'small' ? rem(32) : rem(40),
        height: ownerState.size === 'small' ? rem(32) : rem(40),
        lineHeight: ownerState.size === 'small' ? rem(15) : rem(20),
        fontSize: ownerState.size === 'small' ? rem(15) : rem(20),
        '&.Mui-disabled': {
          opacity: opacity._40,
        },
        '&.Mui-focusVisible': {
          outline: `1px solid ${theme.palette.primary.light}`,
          transition: 'unset',
        },
      };
      return {
        ...styles,
      };
    },
  },
  variants: [
    {
      props: { color: 'tonalLight' },
      style: () => ({
        backgroundColor: colors.secondaryLight4,
        color: colors.secondaryDark3,
        '&:hover': {
          backgroundColor: colors.secondaryLight3,
        },
        '&.Mui-disabled': {
          backgroundColor: colors.secondaryLight4,
          color: colors.secondaryDark3,
        },
      }),
    },
    {
      props: { color: 'tonalDark' },
      style: () => ({
        backgroundColor: colors.secondaryDark3,
        color: colors.secondaryLight3,
        '&:hover': {
          backgroundColor: colors.secondaryDark2,
        },
        '&.Mui-disabled': {
          backgroundColor: colors.secondaryDark3,
          color: colors.secondaryLight3,
        },
      }),
    },
    {
      props: { color: 'ghost' },
      style: () => ({
        color: colors.secondaryDark3,
        '&:hover': {
          backgroundColor: colors.secondaryLight4,
        },
        '&.Mui-disabled': {
          color: colors.secondaryDark3,
        },
      }),
    },
  ],
};
