import { colors, textSizes, weights } from '@components/theme/gen2';
import { styled, Typography } from '@mui/material';
import { rem } from 'polished';

export const Title = styled(Typography)({
  fontSize: textSizes.base,
  fontWeight: weights.semiBold,
  color: colors.grayDark3,
  maxWidth: rem(500),
});

export const Container = styled('div')({
  display: 'flex',
  backgroundColor: colors.white,
  borderRadius: rem(8),
  overflow: 'hidden',
  color: colors.grayDark3,
});

export const Option = styled('div')({
  padding: `${rem(20)} ${rem(24)}`,
  width: rem(220),
  height: rem(206),
  display: 'flex',
  flexDirection: 'column',
  gap: rem(15),
  alignItems: 'center',
  justifyContent: 'center',
  '> span': {
    color: colors.gray,
  },
  '> p': {
    textAlign: 'center',
    '> strong': {
      fontWeight: weights.semiBold,
    },
  },
});
