import { colors } from '@components/theme/gen2';
import {
  Components,
  Theme,
} from '@mui/material';
import { rem } from 'polished';

export const MuiBadge: Components<Theme>['MuiBadge'] = {
  styleOverrides: {
    badge: {
      color: colors.white,
      fontSize: rem(11),
    }
  },
};
