export interface Breadcrumbs {
  title: string;
  path: string;
}

export interface CustomHeader {
  breadcrumbs?: Breadcrumbs[];
  headerTitle: string;
  subTitle?: string;
}

export const createInviteHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Invites',
      path: '/invites',
    },
    {
      title: 'Create Invites',
      path: '#',
    },
  ],
  headerTitle: 'Create Invite',
};

export const editInviteHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Invites',
      path: '/invites',
    },
    {
      title: 'Edit Invites',
      path: '#',
    },
  ],
  headerTitle: 'Edit Invite',
};

export const manageInviteHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Invites',
      path: '/invites',
    },
    {
      title: 'Manage Invites',
      path: '#',
    },
  ],
  headerTitle: 'Manage Invites',
  subTitle: '| keep track of all your invites',
};

export const draftListingHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Draft Invites',
      path: '#',
    },
  ],
  headerTitle: 'Draft Invites',
  subTitle: '| View, edit and send draft invites',
};

export const archivedListingHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Archived Invites',
      path: '#',
    },
  ],
  headerTitle: 'Archived Invites',
  subTitle: '| Keep a record of all closed Invites',
};

export const collationHistoryHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Invites',
      path: '/invites',
    },
    {
      title: 'Collation History',
      path: '/collation-history',
    },
    {
      title: 'Collation-History',
      path: '#',
    },
  ],
  headerTitle: 'Collation History',
  subTitle: '| view all your collation file status',
};

export const integrationsHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Integrations',
      path: '/integrations',
    },
    {
      title: 'Integrations',
      path: '#',
    },
  ],
  headerTitle: 'Integrations',
};

export const teamMembersHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Team',
      path: '/team-members',
    },
    {
      title: 'Team Members',
      path: '#',
    },
  ],
  headerTitle: 'Team Members',
  subTitle: '| manage members of your team',
};

export const viewTeamMembersHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Team',
      path: '/team-members',
    },
    {
      title: 'Team Members',
      path: '#',
    },
  ],
  headerTitle: 'View Team Members',
  subTitle: '| view information related to this member of your team',
};

export const createMembersHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Team',
      path: '/team-members',
    },
    {
      title: 'Team Members',
      path: '#',
    },
  ],
  headerTitle: 'New Team Member',
  subTitle: '| manage members of your team',
};

export const updateMembersHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Team',
      path: '/team-members',
    },
    {
      title: 'Team Members',
      path: '#',
    },
  ],
  headerTitle: 'Edit Team Member',
  subTitle: '| manage this member of your team',
};

export const dashBoardHeader: CustomHeader = {
  headerTitle: 'Dashboard',
};

export const brandingHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Branding',
      path: '#',
    },
  ],
  headerTitle: 'Branding',
  subTitle: '| customise the client portal with your own logo and colours',
};

export const templatesHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Templates',
      path: '#',
    },
  ],
  headerTitle: 'Templates',
  subTitle: '| Manage your templates',
};

export const fileOptionHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Preferences',
      path: '#',
    },
  ],
  headerTitle: 'Preferences',
};

export const accountHeader: CustomHeader = {
  breadcrumbs: [
    {
      title: 'FileInvite',
      path: '/',
    },
    {
      title: 'Settings',
      path: '#',
    },
  ],
  headerTitle: "Account Settings",
}

export const emptyHeader: CustomHeader = {
  headerTitle: '',
};
