import { GlobalRegion } from '@gen2/api/global-region/hooks';
import { ISignupForm } from '@gen2/api/signup/api';
import { useSignupMutation } from '@gen2/api/signup/hooks';
import { PasswordHintList } from '@gen2/app/components/hint-list/hint-list';
import { Region } from '@gen2/app/components/region/region';
import { SocialLogin } from '@gen2/app/components/social-login/social-login';
import { useAuth, useRouter } from '@gen2/hooks';
import {
  ErrorMessage,
  ErrorResponse,
  formatErrorMessage,
} from '@gen2/utils/formatMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  TextField,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { KeyboardEventHandler, ReactNode, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import AuthLayout from '../layout/AuthLayout/AuthLayout';
import { Title } from '../login/Login.styled';
import {
  StyledCheckbox,
  StyledSubmit,
  Subtitle,
  TermContent,
} from './signup.styled';

const schema = Yup.object().shape({
  email: Yup.string().email('email.invalid').required('email.required'),
  password: Yup.string()
    .required('password.required')
    .matches(
      /^(?=.*[!@#$%^&*'])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,99}$/,
      'password.pattern',
    ),
  terms_and_conditions: Yup.boolean()
    .oneOf([true], 'tnc.required')
    .required('tnc.required'),
  password_confirmation: Yup.string()
    .required('confirmPassword.required')
    .oneOf([Yup.ref('password'), ''], 'confirmPassword.match'),
  region: Yup.string().required('region.required'),
});

const initialFormValues: ISignupForm = {
  email: '',
  password: '',
  password_confirmation: '',
  terms_and_conditions: false,
  region: '',
};

const Signup = () => {
  const { t } = useTranslation('validation');
  const router = useRouter();
  const { authCode, user } = useAuth();

  const [regions, setRegions] = useState<GlobalRegion[]>();

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
    watch,
  } = useForm<ISignupForm>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });

  //Navigate to dashboard if the user logged in.
  useEffect(() => {
    if (user) {
      router.navigate('/dashboard');
    }
  }, [router, user]);

  const password = watch('password');

  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<ReactNode[] | string[]>([]);
  const { mutate } = useSignupMutation();

  const onSubmit: SubmitHandler<ISignupForm> = (form) => {
    // Prevent multiple button presses
    setIsLoading(true);

    mutate(form, {
      onError: (error: unknown) => {
        const axiosError = error as AxiosResponse;
        if (axiosError) {
          const { status, data } = axiosError;
          setIsShow(true);
          if (status === 422) {
            const { errors } = data as ErrorResponse;
            setMessages(
              formatErrorMessage(errors as ErrorMessage, {
                login: t('signup.login', { ns: 'page' }),
                or: t('signup.or', { ns: 'page' }),
                reset: t('signup.reset', { ns: 'page' }),
              }),
            );
          }
          if (status >= 500) {
            setMessages([t('error.500', { ns: 'common' })]);
          }
          if (status === 401 || status === 400) {
            setMessages([t(`error.${status}`, { ns: 'common' })]);
          }
        }
        setIsLoading(false);
      },
      onSuccess: async () => {
        await authCode();
      },
    });
  };

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  };

  return (
    <AuthLayout isShowAlert={isShow} login={false}>
      <header>
        <Title data-cy="title">{t('signup.title', { ns: 'page' })}</Title>
        <Subtitle data-cy="subtitle" variant="body2">
          {t('signup.subtitle_1', { ns: 'page' })}
          <Link data-cy="login-link" to="/login">
            {t('signup.subtitle_2', { ns: 'page' })}
          </Link>
        </Subtitle>
      </header>
      {isShow && (
        <Alert icon={false} severity="error" data-cy="alert">
          {messages &&
            messages.map((message, idx) => {
              return <div key={idx}>{message}</div>;
            })}
        </Alert>
      )}
      <form noValidate>
        <Stack spacing={3} sx={{ flexWrap: 'wrap' }}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="email"
                fullWidth
                required
                label={t('email.label')}
                error={Boolean(errors.email)}
                helperText={errors.email?.message && t(errors.email.message)}
                inputProps={{
                  'data-cy': 'email-input',
                }}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="password"
                fullWidth
                required
                type="password"
                label={t('password.label')}
                onKeyDown={handleKeyPress}
                error={Boolean(errors.password)}
                helperText={
                  errors.password?.message && t(errors.password.message)
                }
                inputProps={{
                  'data-cy': 'password-input',
                }}
              />
            )}
          />
          <Controller
            name="password_confirmation"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="confirmPassword"
                fullWidth
                required
                onKeyDown={handleKeyPress}
                type="password"
                label={t('confirmPassword.label')}
                error={Boolean(errors.password_confirmation)}
                helperText={
                  errors.password_confirmation?.message &&
                  t(errors.password_confirmation.message)
                }
                inputProps={{
                  'data-cy': 'confirm-password-input',
                }}
              />
            )}
          />

          {password && <PasswordHintList password={password} />}

          <Region
            initialFormValues={initialFormValues}
            control={control}
            errors={errors}
            reset={reset}
            setRegions={setRegions}
          />

          <FormGroup>
            <FormControlLabel
              control={
                <StyledCheckbox
                  size="small"
                  {...register('terms_and_conditions')}
                  data-cy="terms-and-conditions"
                />
              }
              label={
                <TermContent htmlFor="terms_and_conditions">
                  {t('signup.checkbox_label', { ns: 'page' })}{' '}
                  <a
                    target="_blank"
                    href="https://www.fileinvite.com/terms"
                    rel="noreferrer"
                  >
                    {t('signup.terms', { ns: 'page' })}
                  </a>{' '}
                  {t('signup.and', { ns: 'page' })}{' '}
                  <a
                    target="_blank"
                    href="https://www.fileinvite.com/privacy"
                    rel="noreferrer"
                  >
                    {t('signup.policy', { ns: 'page' })}
                  </a>
                </TermContent>
              }
            />
            <FormHelperText
              data-cy="terms-helper-text"
              error={!!errors.terms_and_conditions}
            >
              {errors.terms_and_conditions?.message &&
                t(errors.terms_and_conditions.message)}
            </FormHelperText>
          </FormGroup>

          <StyledSubmit
            variant="contained"
            color="primary"
            loading={isLoading}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            data-cy="submit"
          >
            {t('signup.btn', { ns: 'page' })}
          </StyledSubmit>
        </Stack>
      </form>

      <SocialLogin regions={regions} />
    </AuthLayout>
  );
};

export default Signup;
