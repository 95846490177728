import {
  regular,
  solid,
  thin,
} from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GetIntSettingsResponse,
  IntFolder,
  TIntegrationType,
} from '@gen2/api/integrations/api';
import {
  useStorageFolderMutation,
  useStorageSettings,
} from '@gen2/api/integrations/hooks';
import { ActionModal } from '@gen2/app/components/action-modal/action-modal';
import { useActionModalStore } from '@gen2/app/components/action-modal/store';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button } from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateFolderModal } from '../create-folder/create-folder';
import { IntFinder } from '../finder/finder';
import { CustomTooltip } from '../integrations.styled';
import { useIntegrationsStore } from '../store';
import { useIntegrationContext } from '../useIntegrationContext';
import {
  ConnectButtonWrapper,
  FilePath,
  StyledAlertContainer,
  StyledContainer,
  StyledIntDetails,
  StyledIntDetailsBody,
  StyledIntDetailsBodyGroup,
  StyledIntDetailsBodyGroupTitle,
  StyledIntDetailsBodySection,
  StyledIntDetailsConnectIcon,
  StyledIntDetailsFolder,
  StyledIntDetailsFolderButton,
  StyledIntDetailsFooter,
  StyledIntDetailsHeader,
  StyledIntDetailsHeaderLeft,
  StyledIntDetailsHeaderLeftImg,
  StyledIntDetailsHeaderRight,
  StyledIntDetailsHeaderRightH3,
  StyledIntDetailsHeaderRightLink,
  StyledIntDetailsHeaderRightP,
} from './storage.styled';

const CloudStorage = () => {
  const {
    isConnected: getIsConnected,
    isLoading,
    connect,
    disabled,
    disconnectAsync
  } = useIntegrationContext();
  const { mutate: saveMutation, isLoading: isSavingFolder } = useStorageFolderMutation();
  const {
    alert,
    setAlert,
    selectedFolder,
    setSelectedFolder,
    setCurrentFolder,
    isFinderOpen,
    isCreateFolderOpen,
    setFinderOpen,
    setCreateFolderOpen,
  } = useIntegrationsStore();
  const navigate = useNavigate();
  const { t } = useTranslation('integrations');
  const { vendor } = useParams<{ vendor: TIntegrationType; }>();
  const isConnected = getIsConnected(vendor || 'google');

  const { showModal } = useActionModalStore();

  const _ = useStorageSettings(vendor || 'google', {
    onSuccess: async (res) => {
      const data = res as GetIntSettingsResponse['data'];

      if (!data?.folder) return;

      const { folder } = data;

      setSelectedFolder({
        id: folder.id,
        name: folder.name,
        path: folder.path,
      });
    },
    enabled: isConnected,
  });

  useEffect(() => {
    return () => {
      setAlert({ ...alert, open: false });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAlert]);

  const handleCloseFinder = () => {
    setFinderOpen(false);
  };

  const handleSelectFolder = (folder: IntFolder) => {
    setSelectedFolder(folder);
    setFinderOpen(false);
  };

  const handleChangeFolder = () => {
    if (selectedFolder) {
      setCurrentFolder(selectedFolder);
    }

    setFinderOpen(true);
  };

  const handleDisconnect = async () => {
    const integrationName = t(`${vendor}.name`);

    const { isConfirmed } = await showModal({
      header: t('disconnect.modal.header', { provider: integrationName }),
      message: 'disconnect.modal.message',
      messageParams: { provider: integrationName },
      translationNamespace: 'integrations',
      closeButtonLabel: t('disconnect.modal.cancel_btn') ?? '',
      submitButtonLabel: t('disconnect.modal.confirm_btn') ?? '',
    });

    if (!isConfirmed) {
      return;
    }

    try {
      await disconnectAsync();

      setAlert({
        message: `${integrationName} was successfully disconnected!`,
        severity: 'warning',
        open: true,
      });
    } catch (err) {
      setAlert({
        message: `Failed to disconnect ${integrationName}. Please try again.`,
        severity: 'error',
        open: true,
      });
    }
  };

  const onSave = () => {
    if (alert.open) {
      setAlert({ ...alert, open: false });
    }

    saveMutation(
      {
        vendor: vendor || 'google',
        directory_id: selectedFolder?.id ?? '',
      },
      {
        onSuccess: async () => {
          setAlert({
            open: true,
            severity: 'success',
            message: t(`${vendor}.success`),
          });
        },
        onError: () => {
          setAlert({
            open: true,
            severity: 'error',
            message: t(`${vendor}.error`),
          });
        },
      },
    );
  };

  return (
    <>
      <StyledContainer>
        {alert.open && (
          <StyledAlertContainer>
            <Alert
              data-cy="alert"
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, open: false })}
            >
              <p>{alert.message}</p>
            </Alert>
          </StyledAlertContainer>
        )}
        <StyledIntDetails>
          <ModalCloseButton
            data-cy={`integration-${vendor}-close`}
            aria-label="close"
            onClick={() => navigate('/integrations')}
          >
            <FontAwesomeIcon icon={regular('close')} />
          </ModalCloseButton>
          <StyledIntDetailsHeader>
            <StyledIntDetailsHeaderLeft>
              <StyledIntDetailsHeaderLeftImg
                src={`/assets/int-${vendor}.png`}
                alt={t(`${vendor}.thumbnail.title`) || ''}
              />
            </StyledIntDetailsHeaderLeft>
            <StyledIntDetailsHeaderRight>
              <StyledIntDetailsHeaderRightH3>
                {t(`${vendor}.thumbnail.title`)}
              </StyledIntDetailsHeaderRightH3>
              <StyledIntDetailsHeaderRightP>
                {t(`${vendor}.thumbnail.desc`)}
              </StyledIntDetailsHeaderRightP>
              <StyledIntDetailsHeaderRightLink
                href={t(`${vendor}.url`) || '#'}
                target="_blank"
              >
                {t(`${vendor}.link`)}
              </StyledIntDetailsHeaderRightLink>
            </StyledIntDetailsHeaderRight>
          </StyledIntDetailsHeader>
          <StyledIntDetailsBody>
            {isConnected ? (
              <>
                <StyledIntDetailsBodySection>
                  <StyledIntDetailsBodyGroup>
                    <LoadingButton
                      variant="outlined"
                      color="tertiary"
                      startIcon={
                        <StyledIntDetailsConnectIcon icon={solid('unlink')} />
                      }
                      onClick={handleDisconnect}
                      loading={isLoading}
                      data-cy={`disconnect-${vendor}`}
                    >
                      Disconnect
                    </LoadingButton>
                  </StyledIntDetailsBodyGroup>
                </StyledIntDetailsBodySection>
                <StyledIntDetailsBodySection>
                  <div>
                    <StyledIntDetailsBodyGroupTitle>
                      {t(`${vendor}.title`)}
                    </StyledIntDetailsBodyGroupTitle>
                    <p>{t(`${vendor}.desc`)}</p>
                  </div>
                  <StyledIntDetailsFolder>
                    <>
                      {selectedFolder?.id && (
                        <FilePath title={selectedFolder?.path}>
                          <FontAwesomeIcon icon={thin('folder')} />
                          <span>{selectedFolder?.path}</span>
                        </FilePath>
                      )}
                      <StyledIntDetailsFolderButton
                        variant="contained"
                        color="secondary"
                        data-cy={`change-${vendor}-folder`}
                        onClick={handleChangeFolder}
                      >
                        {t(`${vendor}.choose_btn`)}
                      </StyledIntDetailsFolderButton>
                    </>
                  </StyledIntDetailsFolder>
                </StyledIntDetailsBodySection>
                <StyledIntDetailsFooter>
                  <Button
                    data-cy="back-to-integrations"
                    variant="outlined"
                    color="tertiary"
                    onClick={() => navigate('/integrations')}
                  >
                    Back
                  </Button>
                  <LoadingButton
                    data-cy={`save-${vendor}-folder`}
                    onClick={onSave}
                    variant="contained"
                    color="primary"
                    disabled={!selectedFolder?.id}
                    loading={isSavingFolder}
                  >
                    Save Changes
                  </LoadingButton>
                </StyledIntDetailsFooter>
              </>
            ) : (
              <CustomTooltip
                title={t('connect.tooltip')}
                placement="top"
                disableHoverListener={!disabled[vendor || 'google']}
              >
                <ConnectButtonWrapper>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    startIcon={<StyledIntDetailsConnectIcon icon={solid('link')} />}
                    onClick={() => connect(vendor || 'google')}
                    loading={isLoading}
                    data-cy={`connect-${vendor}`}
                    disabled={disabled[vendor || 'google']}
                  >
                    Connect
                  </LoadingButton>
                </ConnectButtonWrapper>

              </CustomTooltip>

            )}
          </StyledIntDetailsBody>
        </StyledIntDetails>
      </StyledContainer>
      <IntFinder
        open={isFinderOpen}
        onClose={handleCloseFinder}
        onSelect={handleSelectFolder}
      />
      <CreateFolderModal
        open={isCreateFolderOpen}
        onClose={() => setCreateFolderOpen(false)}
      />
      <ActionModal />
    </>
  );
};

export default CloudStorage;
