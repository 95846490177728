import { colors, headingSizes, weights } from '@components/theme/gen2';
import { styled } from '@mui/system';
import { rem } from 'polished';

export const StyledContainer = styled('div')({
  maxWidth: rem(500),
  paddingLeft: rem(30),
  paddingRight: rem(30),
  paddingTop: rem(50),
  paddingBottom: rem(50),
  marginLeft: 'auto',
  marginRight: 'auto',
});

export const StyledHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: rem(30),
  paddingBottom: rem(30),
  borderBottom: `${rem(1)} solid ${colors.gray}`,

  '& img': {
    marginBottom: rem(20),
  },

  '> p': {
    marginTop: rem(10),
    marginBottom: rem(25),
  },
});

export const StyledHeadline = styled('h2')({
  fontSize: headingSizes.h2,
  fontWeight: weights.normal,
  lineHeight: rem(32),
  margin: 0,
});

export const StyledTemplateContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  gap: rem(25),
});

export const StyledButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: rem(20),
});
