import { useSafeQuery } from '@gen2/hooks';
import { useMutation, UseQueryOptions } from '@tanstack/react-query';
import {
  addContactsToInvite,
  deleteContactFromInvite,
  draftInvite,
  editDraftInvite,
  getInvite,
  sendInvite,
  TInvite,
} from './api';

export enum InvitesKeys {
  getInvite = 'getInvite',
  editDraftInvite = 'editDraftInvite',
  createDraftInvite = 'createDraftInvite',
}

export const useDraftInviteMutation = () =>
  useMutation({
    mutationKey: [InvitesKeys.createDraftInvite],
    mutationFn: draftInvite,
  });

export const useEditDraftInviteMutation = () =>
  useMutation({
    mutationKey: [InvitesKeys.editDraftInvite],
    mutationFn: editDraftInvite,
  });

export const useAddContactsToInviteMutation = () =>
  useMutation({ mutationFn: addContactsToInvite });

export const useRemoveContactFromInviteMutation = () =>
  useMutation({ mutationFn: deleteContactFromInvite });

export const useInviteQuery = (
  id: string,
  options?: UseQueryOptions<TInvite>,
) => {
  return useSafeQuery<TInvite>(
    [InvitesKeys.getInvite, id],
    async () => {
      const res = await getInvite(id);

      return res?.data.data.invite;
    },
    options,
  );
};

export const useSendInviteMutation = () =>
  useMutation({ mutationFn: sendInvite });
