import { colors } from '@components/theme/gen2';
import { Components, Theme } from '@mui/material';
import Radio, { RadioProps } from '@mui/material/Radio';
import { styled } from '@mui/material/styles';

const RadioIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgb(70,130,180), inset 0 -1px 0 rgb(70,130,180)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#fff',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#fff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(217,222, 228, .5)',
  },
}));

const RadioCheckedIcon = styled(RadioIcon)({
  backgroundColor: '#4682B4',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#3c6f99',
  },
});

export const CustomizedRadio = (props: RadioProps) => (
  <Radio
    disableRipple
    color="default"
    checkedIcon={<RadioCheckedIcon />}
    icon={<RadioIcon />}
    {...props}
  />
);

export const MuiRadio: Components<Theme>['MuiRadio'] = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...(ownerState.color === 'secondary' && {
        '&.Mui-checked': {
          color: colors.secondary,
        },
      }),
    }),
  },
};