import { colors } from '@components/theme/gen2';
import { Alert, Box, Button, styled } from '@mui/material';
import { DatePickerLine, EditableInputNew, TagsLine } from '@nx-fe/components';
import { rem } from 'polished';

interface IsEditSendInviteProps {
  isEditSendInvite?: boolean;
}

export const StyledSendInvites = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    height: 'calc(100vh - var(--header-height))',
    overflowX: 'hidden',
  },
}));

export const StyledSendInvitesBody = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const Stickyhead = styled('div')<IsEditSendInviteProps>(
  ({ isEditSendInvite, theme }) => ({
    position: 'relative',
    minHeight: !isEditSendInvite ? rem(57) : rem(73),

    [theme.breakpoints.down('lg')]: {
      height: isEditSendInvite ? rem(90) : rem(57),
    },
    [theme.breakpoints.down('md')]: {
      height: isEditSendInvite ? rem(145) : rem(57),
    },
    [theme.breakpoints.down('sm')]: {
      height: isEditSendInvite ? rem(166) : rem(57),
    },
  }),
);

export const StyledActions = styled('div')<IsEditSendInviteProps>(
  ({ theme }) => ({
    position: 'fixed',
    left: 0,
    background: colors.white,
    zIndex: 11,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    borderBottom: `1px solid ${colors.grayLight1}`,
    paddingTop: rem(8),
    paddingBottom: rem(8),
    paddingLeft: rem(80),
    paddingRight: rem(20),
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      paddingLeft: rem(20),
      paddingRight: rem(20),
    },
  }),
);

export const AlertContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: rem(20),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const StyledAlert = styled(Alert)({
  flex: 1,
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
});

export const StyledAlertButton = styled(Button)({
  padding: `${rem(8)} ${rem(24)}`,
  height: rem(36),
});

export const StyledHead = styled('div')<{ $opaque: boolean }>(
  ({ $opaque }) => ({
    ...($opaque && {
      opacity: 0.5,
      pointerEvents: 'none',
      userSelect: 'none',
    }),
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    borderBottom: `1px solid ${colors.grayLight1}`,
  }),
);

export const StyledSendTo = styled(TagsLine)({
  gridColumn: '1 / -1',
  minHeight: rem(45),
  borderBottom: `1px solid ${colors.grayLight1}`,
});

export const StyledSendFrom = styled(TagsLine)({
  gridColumn: '1 / -1',
  minHeight: rem(45),
  borderBottom: `1px solid ${colors.grayLight1}`,
});

export const StyledAdditionalWatchers = styled(TagsLine)({
  gridColumn: '3 / -1',
  // borderLeft: `1px solid ${colors.grayLight1}`,
  borderBottom: `1px solid ${colors.grayLight1}`,
});

export const StyledSubjectLine = styled(EditableInputNew)(({ theme }) => ({
  gridColumn: '1 / -1',
  border: 'none',
  borderBottom: `1px solid ${colors.grayLight1}`,
  height: rem(80),
  paddingLeft: rem(16),
  paddingRight: rem(16),
  paddingTop: rem(12),
  paddingBottom: rem(12),

  [theme.breakpoints.up('sm')]: {
    paddingLeft: rem(50),
    paddingRight: rem(50),
  },

  [theme.breakpoints.up('lg')]: {
    gridColumn: '1 / 8',
    borderBottom: 'none',
  },
}));

export const StyledDueDate = styled(DatePickerLine)(({ theme }) => ({
  gridColumn: '1 / 10',
  border: 'none',

  [theme.breakpoints.up('lg')]: {
    gridColumn: '8 / 12',
    borderLeft: `1px solid ${colors.grayLight1}`,
  },
}));

export const StyledReminderButton = styled('button')(({ theme }) => ({
  gridColumn: '10 / -1',
  alignSelf: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: rem(40),
  padding: 0,
  height: rem(40),
  backgroundColor: 'transparent',
  border: 'none',
  color: colors.grayDark2,

  '&:hover .icon-bell': {
    color: colors.primary,
  },

  '.icon-bell': {
    fontSize: rem(20),
  },

  '.icon-caret-down': {
    fontSize: rem(14),
  },

  [theme.breakpoints.up('lg')]: {
    gridColumn: '12 / -1',
  },
}));
