import {
  AvatarContainer,
  Content,
  Main,
  MessageContent,
  MessageDateTime,
  MessageDetails,
  MessageLineContainer,
  MessageName,
  StyledAvatar,
} from './message-line.styles';

export type TMessageLineProps = {
  firstName: string;
  lastName: string;
  message: string;
  isSender: boolean;
  sentAt: string;
};

const MessageLine = ({
  isSender,
  firstName,
  lastName,
  message,
  sentAt,
}: TMessageLineProps) => {
  return (
    <MessageLineContainer>
      <Main>
        {isSender ? (
          <AvatarContainer>
            <StyledAvatar data-cy="avatar" fromMessage={true}>
              {firstName[0]}
              {lastName[0]}
            </StyledAvatar>
          </AvatarContainer>
        ) : null}

        <Content sender={isSender}>
          <MessageDetails>
            <MessageName>
              {firstName} {lastName}
            </MessageName>
            <MessageDateTime>{sentAt}</MessageDateTime>
          </MessageDetails>

          <MessageContent>{message}</MessageContent>
        </Content>

        {!isSender ? (
          <AvatarContainer>
            <StyledAvatar data-cy="avatar" fromMessage={true}>
              {firstName[0]}
              {lastName[0]}
            </StyledAvatar>
          </AvatarContainer>
        ) : null}
      </Main>
    </MessageLineContainer>
  );
};

export default MessageLine;
