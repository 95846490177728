import { colors, textSizes, weights } from '@components/theme/gen2';
import { styled } from '@mui/material';
import { rem } from 'polished';

interface MainStyleProps {
  isShowAlert?: boolean;
}

interface ContainerProps {
  login?: boolean;
}

export const Container = styled('div')<ContainerProps>(
  ({ theme, login = true }) => ({
    width: '100%',
    display: 'flex',
    height: '100vh',
    flexDirection: 'row',
    backgroundColor: login ? colors.grayDark4 : colors.tertiary,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }),
);

export const LeftWrapper = styled('div')(({ theme }) => ({
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const ImgWrapper = styled('div')(({ theme }) => ({
  width: rem(600),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: rem(40),
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    height: rem(80),
  },
}));

export const LogoImg = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: rem(223),
  },
}));

export const OnboardingImg = styled('img')(({ theme }) => ({
  width: rem(416),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const Content = styled('div')(({ theme }) => ({
  width: rem(695),
  backgroundColor: colors.white,
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    width: rem(512),
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
  },
}));

export const Main = styled('div')<MainStyleProps>(({ theme, isShowAlert }) => ({
  width: rem(350),
  transform: `translateY(${isShowAlert ? rem(40) : rem(60)})`,
  margin: '0 auto',
  fontWeight: weights.normal,
  fontSize: textSizes.base,
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  flexGrow: 1,
  flexWrap: 'wrap',
  rowGap: rem(25),
  [theme.breakpoints.down('sm')]: {
    transform: `translateY(${rem(50)})`,
  },
  [theme.breakpoints.down('xs')]: {
    width: rem(300),
  },
}));
