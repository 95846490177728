import { colors } from '@components/theme/gen2';
import { Components, Theme } from '@mui/material';
import { rem } from 'polished';

export const MuiSlider: Components<Theme>['MuiSlider'] = {
  styleOverrides: {
    rail: {
      backgroundColor: colors.grayLight2,
      opacity: 1,
    },
    track: {
      height: rem(4),
      border: 'none',
    },
    thumb: {
      height: rem(16),
      width: rem(16),
    },
  },
};
