import { colors } from '@components/theme/gen2';
import { InputBase, InputBaseProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { rem } from 'polished';

interface SearchBarStyleProps {
  open?: boolean;
  isVariant?: boolean;
}
interface WrapperProps extends SearchBarStyleProps {
  role?: string;
}

interface CustomInputProps extends InputBaseProps {
  mode: 'dark' | 'light';
}

export const Wrapper = styled('div')<WrapperProps>(
  ({ theme, isVariant, open }) => ({
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: !isVariant ? 'inherit' : open ? 'inherit' : '40px',
      cursor: !isVariant ? 'text' : open ? 'text' : 'pointer',
    },
  }),
);

Wrapper.defaultProps = {
  role: 'searchBox',
};

export const StyledInput = styled(InputBase)<CustomInputProps>(({ mode }) => ({
  '&.MuiInputBase-root': {
    borderRadius: '100px',
    paddingLeft: rem(14),
    paddingRight: rem(14),
    background: mode === 'dark' ? colors.grayLight3 : colors.white,
  },
  '& .MuiInputBase-input': {
    padding: `${rem(8.5)} ${rem(14)}`,
    color: colors.grayDark3,
  },
  '& > svg': {
    fontSize: rem(14),
    color: colors.grayDark2,
  },
  'svg.fa-xmark': {
    cursor: 'pointer',
  },
}));
