import { IForgotPasswordForm, handleForgotPasswordError } from '@gen2/api/forgot-password/api';
import { useForgotPasswordMutation } from '@gen2/api/forgot-password/hooks';
import { useRouter } from '@gen2/hooks';
import { Alert } from '@mui/material';
import { MouseEventHandler, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import AuthLayout from '../layout/AuthLayout/AuthLayout';
import { Title, Subtitle, Footnote, Email } from './forgot-password-confirmation.styled';

const schema = Yup.string().email('email.invalid');

const ForgotPasswordConfirmation = () => {
  const router = useRouter();
  const { t } = useTranslation('validation');
  const { mutate } = useForgotPasswordMutation();

  const [searchParams] = useSearchParams();

  const rawEmail = searchParams.get('email') || '';

  const sanitizeEmail: string = useMemo(() => {
    let email = rawEmail;

    try {
      schema.validateSync(email);
    } catch (error) {
      email =  '';
    }
  
    return email
  }, [rawEmail]);

  const [isShow, setIsShow] = useState(false);
  const [messages, setMessage] = useState<string[]>([]);

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();

    const payload: IForgotPasswordForm = {
      email: sanitizeEmail
    }

    mutate(payload, {
      onError: (error) => handleForgotPasswordError(error, t, setIsShow, setMessage),
      onSuccess: () => {
        router.navigate(`/forgot-password?resend=${encodeURIComponent(payload.email)}`);
      },
    });
  };

  return (
    <AuthLayout>
      <header>
        <Title data-cy="title">{t('forgot_password.confirmation.title', { ns: 'page' })}</Title>
      </header>

      {(isShow && messages.length > 0) && (
        <Alert data-cy="alert" icon={false} severity="error" color="error">
          {messages &&
            messages.map((message, idx) => {
              return <div key={idx}>{message}</div>;
            })
          }
        </Alert>
      )}
      
      <Subtitle data-cy="subtitle">
        {t('forgot_password.confirmation.subtitle', { ns: 'page' })}
        <Email>{`"${sanitizeEmail}"`}</Email>.
      </Subtitle>

      <Footnote data-cy="link">
        {t('forgot_password.confirmation.footnote_1', { ns: 'page' })}
        <Link onClick={handleClick} to={`/forgot-password?resend=${encodeURIComponent(sanitizeEmail)}`}>
          {t('forgot_password.confirmation.footnote_link', { ns: 'page' })}
        </Link>
        &nbsp;
        {t('forgot_password.confirmation.footnote_2', { ns: 'page' })}
      </Footnote>
    </AuthLayout>
  );
};

export default ForgotPasswordConfirmation;
