import { colors, weights } from '@components/theme/gen2';
import { Dialog, DialogActions, DialogTitle, styled } from '@mui/material';
import { rem } from 'polished';

export const ActionModalContainer = styled(Dialog)({
  maxWidth: rem(464),
  margin: 'auto',
  '& strong': {
    textTransform: 'capitalize',
    fontWeight: weights.normal,
  },
});

export const ActionModalTitle = styled(DialogTitle)({
  fontSize: rem(20),
  fontWeight: weights.semiBold,
  color: colors.grayDark3,
});

export const ActionModalActions = styled(DialogActions)({
  backgroundColor: colors.grayLight3,
});
