import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AttachmentItem, StyledBox } from './single-attachment.styles';
import {
  MimeType,
  csvMimeTypes,
  excelMimeTypes,
  imageMimeTypes,
  pdfMimeTypes,
  pptMimeTypes,
  wordMimeTypes,
} from './type';

export interface SingleAttachmentProps {
  type: MimeType;
  name: string;
  size: number;
  children: React.ReactNode;
}

export const renderIcon = (type: MimeType) => {
  let icon: IconDefinition;
  if (csvMimeTypes.includes(type)) {
    icon = regular('file-csv');
  } else if (pdfMimeTypes.includes(type)) {
    icon = regular('file-pdf');
  } else if (imageMimeTypes.includes(type)) {
    icon = regular('file-image');
  } else if (wordMimeTypes.includes(type)) {
    icon = regular('file-word');
  } else if (pptMimeTypes.includes(type)) {
    icon = regular('file-powerpoint');
  } else if (excelMimeTypes.includes(type)) {
    icon = regular('file-excel');
  } else {
    icon = regular('file');
  }

  return <FontAwesomeIcon size="lg" icon={icon} />;
};

export const formatSize = (size: number) => {
  const isMB = size > 1000000;
  const result = isMB ? size / 1024 / 1024 : size / 1024;
  return result.toFixed(1) + (isMB ? 'MB' : 'KB');
};

export const SingleAttachment = ({
  type,
  name,
  size,
  children,
}: SingleAttachmentProps) => {
  return (
    <AttachmentItem data-cy="attachment-item">
      <StyledBox>
        <span data-cy="file-icon">{renderIcon(type)}</span>
        <p data-cy="file-name">{name}</p>
        <span data-cy="file-size">({formatSize(size)})</span>
      </StyledBox>
      {children}
    </AttachmentItem>
  );
};
