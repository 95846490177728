import { Invite } from '@gen2/types/invite';
import { useMutation } from '@tanstack/react-query';
import { deleteInvites } from './api';

export enum Keys {
  DELETE_INVITES = 'deleteInvites',
}

export interface InvitesListResponse {
  data: { invites: Invite[] },
  links: { first: string | null; last: string | null; next: string | null; prev: string | null },
  meta: { current_page: number; from: number; path: string; per_page: number; to: number }
}

export const useDeleteInvitesMutation = () =>
    useMutation({
        mutationKey: [Keys.DELETE_INVITES],
        mutationFn: deleteInvites,
    });

