import { colors, textSizes } from '@components/theme/gen2';
import { Components, Theme } from '@mui/material';
import { rem } from 'polished';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    arrow: {
      color: colors.secondaryDark3,
    },
    tooltip: {
      fontSize: rem(textSizes.sm),
      backgroundColor: colors.secondaryDark3,
      color: colors.secondaryLight3,
      lineHeight: rem(18),
      paddingLeft: rem(10),
      paddingRight: rem(10),
      paddingTop: rem(7),
      paddingBottom: rem(7),
      borderRadius: rem(5),
    },
  },
};
