import { colors, headingType, weights } from '@components/theme/gen2';
import { Button, styled, TextField } from '@mui/material';
import { rem } from 'polished';

export const Container = styled('div')({
  backgroundColor: colors.grayLight2,
  height: '100%',
  paddingTop: rem(20),
  paddingBottom: rem(100),
});

export const Content = styled('div')({
  padding: rem(20),
  borderRadius: rem(4),
  backgroundColor: colors.grayLight3,
  display: 'flex',
  flexDirection: 'column',
});

export const ContentLayout = styled('div')({
  maxWidth: rem(840),
  margin: '0 auto',
  marginTop: rem(20),
  display: 'flex',
  flexDirection: 'column',
  gap: rem(20),
});

export const FollowUpTitle = styled('div')({
  fontSize: rem(18),
  fontFamily: headingType,
  fontWeight: weights.semiBold,
  marginBottom: rem(12),
});

export const SendFollowUpButton = styled(Button)({
  flexShrink: 0,
});

export const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: rem(40),
  marginBottom: rem(40),
});

export const FormGroup = styled('div')({
  marginBottom: rem(40),
});

export const Title = styled('div')({
  fontSize: rem(15),
  fontWeight: weights.semiBold,
});

export const NameField = styled(TextField)({
  marginTop: rem(20),

  '& .MuiOutlinedInput-input': {
    backgroundColor: colors.white,
  },
});

export const RequestList = styled('div')({
  marginTop: rem(20),
  backgroundColor: colors.white,
  marginBottom: rem(20),
});

export const BackButton = styled(Button)({
  gap: rem(4),
  backgroundColor: colors.white,
  alignSelf: 'flex-start',
});

export const StyledFollowUpSuccess = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  marginBottom: rem(20),
  backgroundColor: colors.grayLight2,

  img: {
    marginBottom: rem(50),
    width: rem(230),
  },

  p: {
    maxWidth: rem(490),
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    marginBottom: rem(30),
  },
});

export const StyledFollowUpSuccessHeading = styled('h1')({
  fontSize: rem(24),
  fontWeight: weights.medium,
  margin: 0,
  marginBottom: rem(12),
});
