import { colors, weights } from '@components/theme/gen2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/system';
import { rem } from 'polished';

interface StyledDatePickerLineStyleProps {
  isError: boolean;
}

export const StyledDatePickerLine = styled(
  'div',
)<StyledDatePickerLineStyleProps>(({ theme, isError }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: rem(10),
  padding: rem(10),
  paddingLeft: rem(16),
  paddingRight: rem(16),
  fontWeight: weights.medium,
  border: `${rem(1)} solid ${colors.grayLight2}`,
  cursor: 'pointer',
  color: isError ? colors.danger : colors.grayDark3,
  [theme.breakpoints.up('sm')]: {
    padding: `${rem(10)} ${rem(50)}`,
  },
}));

export const StyledDisplay = styled('div')({
  [`${StyledDatePickerLine}:hover &`]: {
    color: colors.secondary,
  },
});

// convert to a object styled component

export const StyledLabel = styled('label')<StyledDatePickerLineStyleProps>(
  ({ isError }) => ({
    color: isError ? colors.danger : colors.grayDark2,
    whiteSpace: 'nowrap',
    [`${StyledDatePickerLine}:hover &`]: {
      color: colors.secondary,
    },
  }),
);

export const StyledDatePickerLineContainer = styled('div')({
  position: 'relative',
  flex: 1,

  // make scrollbar not mess with the layout
  '&::-webkit-scrollbar': {
    width: 0,
    height: 0,
    background: 'transparent',
  },

  [`${StyledDatePickerLine}:hover &`]: {
    color: colors.secondary,
  },
});

export const StyledCalendarPickerContainer = styled('div')({
  background: colors.white,
  borderRadius: rem(10),
  border: `${rem(1)} solid ${colors.grayLight1}`,
  boxShadow: `0 ${rem(4)} ${rem(8)} ${rem(3)} ${colors.grayLight1}`,
});

export const StyledIcon = styled(FontAwesomeIcon)({
  color: colors.grayDark1,
  opacity: 0,

  [`${StyledDatePickerLine}:hover &`]: {
    opacity: 1,
    color: 'inherit',
  },
});
