import { useGlobalRegionsQuery } from '@gen2/api/global-region/hooks';
import { SocialLogin } from '@gen2/app/components/social-login/social-login';
import { useAuth, useRouter } from '@gen2/hooks';
import { Alert } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import AuthLayout from '../layout/AuthLayout/AuthLayout';
import EmailForm from './EmailForm';
import { Subtitle, Title } from './Login.styled';
import MfaBackupForm from './MfaBackupForm';
import MfaForm from './MfaForm';
import PasswordForm from './PasswordForm';

const Login: React.FC = () => {
  const { t } = useTranslation('validation');
  const [step, setStep] = useState(1);
  const [searchParams] = useSearchParams();
  const [currEmail, setCurrEmail] = useState('');
  const [isMfaPage, setIsMfaPage] = useState(false);
  const { user } = useAuth();
  const router = useRouter();
  const { data } = useGlobalRegionsQuery();

  const regions = useMemo(() => {
    return data ?? [];
  }, [data]);

  useEffect(() => {
    if (user) {
      router.navigate('/invite-listing');
    }
  }, [router, user]);

  const handleUpdate = useCallback((email: string) => {
    setCurrEmail(email);
    setStep(2);
  }, []);

  const handleMfaRequired = useCallback(() => {
    setStep(3);
  }, []);

  const handleBack = useCallback((step?: number) => {
    setStep(step ?? 1);
  }, []);

  useEffect(() => {
    if (searchParams.get('reset_success')) {
      setIsShowInfo(true);
      setIsShowMessage('reset_password.success');
    } else if (searchParams.get('signup_invite_success')) {
      setIsShowInfo(true);
      setIsShowMessage('set_password.success');
    } else if (searchParams.get('social_error')) {
      setIsShowError(true);
      setIsShowErrorMessage('login.social_error');
    }
  }, [searchParams]);

  useMemo(() => {
    if (step === 3 || step === 4) {
      setIsMfaPage(true);
    } else {
      setIsMfaPage(false);
    }
  }, [step]);

  const [isShowInfo, setIsShowInfo] = useState(false);
  const [isShowMessage, setIsShowMessage] = useState('');
  const [isShowError, setIsShowError] = useState(false);
  const [isShowErrorMessage, setIsShowErrorMessage] = useState('');

  return (
    <AuthLayout>
      <header>
        <Title data-cy="title">
          {!isMfaPage
            ? t('login.title', { ns: 'page' })
            : step === 3
              ? t('login.mfa.title', { ns: 'page' })
              : t('login.mfa.backup.title', { ns: 'page' })}
        </Title>
        <Subtitle data-cy="subtitle">
          {!isMfaPage
            ? t('login.subtitle', { ns: 'page' })
            : step === 3
              ? t('login.mfa.subtitle', { ns: 'page' })
              : t('login.mfa.backup.subtitle', { ns: 'page' })}
        </Subtitle>
      </header>
      {isShowInfo && (
        <Alert icon={false} severity="info" data-cy="alert">
          <div>{t(isShowMessage, { ns: 'page' })}</div>
        </Alert>
      )}
      {isShowError && (
        <Alert icon={false} severity="error" data-cy="alert-error">
          <div>{t(isShowErrorMessage, { ns: 'page' })}</div>
        </Alert>
      )}
      <EmailForm
        active={step === 1}
        handleUpdate={handleUpdate}
        regions={regions}
      />
      <PasswordForm
        active={step === 2}
        email={currEmail}
        handleBack={handleBack}
        handleMfaRequired={handleMfaRequired}
      />
      <MfaForm active={step === 3} handlePageChange={handleBack} />
      <MfaBackupForm active={step === 4} handlePageChange={handleBack} />

      {!isMfaPage && (
        <SocialLogin regions={regions} />
      )}
    </AuthLayout>
  );
};
export default Login;
