import { useMsal } from "@azure/msal-react";
import { GlobalRegion } from '@gen2/api/global-region/hooks';
import { Microsoft } from "@gen2/services/social-login/microsoft/microsoft";
import { generateStateString } from '@gen2/utils/auth';
import { Loader } from '@nx-fe/components';
import { useGoogleLogin } from '@react-oauth/google';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import {
  StyledLeftBar,
  StyledOrBox,
  StyledRightBar,
  StyledSocialLoginBtn,
  StyledSocialLoginIcon,
} from './social-login.styles';

interface SocialLoginProps {
  regions: GlobalRegion[] | undefined;
}

interface SocialLoginOptions {
  name: string;
  icon: string;
  buttonText: string;
  onClick: (() => void) | null;
}

export const SocialLogin: FC<SocialLoginProps> = () => {
  const { t } = useTranslation('socialLogin');
  const [isLoading, setIsLoading] = useState({ loading: false, option: '' });
  const stateString = generateStateString();
  const googleRedirectUri = `${window.location.origin}/auth/google/callback`;

  const googleSocialLogin = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: googleRedirectUri,
    select_account: true,
    state: stateString,
    scope: 'openid profile email',
  });

  const { instance } = useMsal();
  const navigate = useNavigate();
  const socialLogins: SocialLoginOptions[] = [
    {
      name: 'google',
      icon: '/assets/google-logo.png',
      buttonText: t('google.submit'),
      onClick: googleSocialLogin,
    },
    {
      name: 'microsoft',
      icon: '/assets/microsoft-logo.png',
      buttonText: t('microsoft.submit'),
      onClick: () => {
        Microsoft.loginRedirect(instance, navigate);
      }
    },
  ];

  useEffect(() => {
    return () => {
      setIsLoading({ loading: false, option: '' });
    };
  }, []);

  return (
    <>
      <StyledOrBox>
        <StyledLeftBar /> or <StyledRightBar />
      </StyledOrBox>

      {
        socialLogins.map((socialLogin) => (
          <StyledSocialLoginBtn
            key={socialLogin.name}
            variant="outlined"
            type="submit"
            data-cy={`${socialLogin.name}-submit-btn`}
            disabled={isLoading.loading && isLoading.option === socialLogin.name}
            color="tertiary"
            startIcon={
              isLoading.loading && isLoading.option === socialLogin.name
                ? null
                : (<StyledSocialLoginIcon src={socialLogin.icon} alt={socialLogin.icon} />)
            }
            onClick={() => {
              if (socialLogin.onClick) {
                setIsLoading({ loading: true, option: socialLogin.name });
                socialLogin.onClick();
              }
            }}
          >
            {
              isLoading.loading && isLoading.option === socialLogin.name
                ? (
                  <Loader
                    props={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />
                )
                : socialLogin.buttonText
            }
          </StyledSocialLoginBtn>
        ))
      }
    </>
  );
};
