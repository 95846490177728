import {
  baseType,
  colors,
  headingSizes,
  weights,
} from '@components/theme/gen2';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, styled, Typography } from '@mui/material';
import { rem } from 'polished';

export const ProfileContainerStyledBox = styled(Box)(({ theme }) => ({
  width: '40%',
  display: 'flex',
  flexDirection: 'column',
  gap: 0,
  boxShadow: `0px ${rem(2)} ${rem(4)} ${rem(2)} rgba(0, 0, 0, 0.10)`,
  borderRadius: rem(10),
  maxWidth: rem(600),

  [theme.breakpoints.down('md')]: {
    width: '50%',
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const ProfilePictureStyledBox = styled(Box)({
  padding: rem(20),
  backgroundColor: colors.grayLight3,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: rem(10),
});

export const ProfileDetailsStyledBox = styled(Box)({
  padding: rem(30),
  display: 'flex',
  flexDirection: 'column',
  gap: rem(20),
  backgroundColor: 'white',
});

export const Title = styled('div')({
  fontSize: rem(headingSizes.h6),
  fontWeight: weights.semiBold,
  fontFamily: baseType,
  color: colors.grayDark3,
  lineHeight: 'normal',
});

export const StyledFormGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: rem(20),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const StyledSubmit = styled(LoadingButton)({
  padding: `${rem(6)} ${rem(15)}`,
  textAlign: 'center',
  alignSelf: 'flex-start',
});

export const StyleFormHeader = styled(Typography)({
  fontSize: headingSizes.h6,
  fontWeight: weights.semiBold,
  color: colors.grayDark2,
});

export const StyledTypography = styled(Typography)({
  fontSize: rem(0.8),
  color: colors.grayDark2,
});
