import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  connectInt,
  createIntFolder,
  disconnectInt,
  getStorageFolders,
  GetIntFoldersParams,
  GetIntFoldersResponse,
  getStorageSettings,
  saveIntFolder,
  TIntegrationType,
} from './api';

export enum IntegrationKeys {
  connectInt = 'connectInt',
  disconnectInt = 'disconnectInt',
  getStorageFolders = 'getStorageFolders',
  saveIntFolder = 'saveIntFolder',
  storageSettings = 'storageSettings',
  createStorageFolder = 'createStorageFolder',
}
export const useStorageFoldersQuery = (
  vendor: TIntegrationType,
  params?: GetIntFoldersParams,
  options?: UseQueryOptions<GetIntFoldersResponse['data']>,
) => {
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [IntegrationKeys.getStorageFolders, vendor, params?.folder_id],
    queryFn: async (): Promise<GetIntFoldersResponse['data']> => {
      const response = await getStorageFolders(vendor, params);
      return response.data?.data;
    },
    ...options,
  });
};

export const useConnectIntMutation = () =>
  useMutation({
    mutationKey: [IntegrationKeys.connectInt],
    mutationFn: connectInt,
  });

export const useDisconnectIntMutation = () =>
  useMutation({
    mutationKey: [IntegrationKeys.disconnectInt],
    mutationFn: disconnectInt,
  });

export const useStorageFolderMutation = () =>
  useMutation({
    mutationKey: [IntegrationKeys.saveIntFolder],
    mutationFn: saveIntFolder,
  });

export const useStorageSettings = (
  vendor: TIntegrationType,
  options?: UseQueryOptions,
) => {
  return useQuery({
    queryKey: [IntegrationKeys.storageSettings, vendor],
    queryFn: async () => {
      const response = await getStorageSettings(vendor);
      return response.data?.data;
    },
    ...options,
  });
};

export const useCreateStorageFolderMutation = () =>
  useMutation({
    mutationKey: [IntegrationKeys.createStorageFolder],
    mutationFn: createIntFolder,
  });
