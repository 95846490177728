import { colors } from '@components/theme/gen2';
import { Components, Theme } from '@mui/material';
import { rem } from 'polished';

export const MuiList: Components<Theme>['MuiList'] = {
  styleOverrides: {
    root: {
      padding: 0,
      borderRadius: rem(5),
      '.MuiListItemIcon-root': {
        minWidth: rem(25),
      },
    },
    dense: {
      border: `${rem(1)} solid ${colors.gray}`,
    },
  },
};
