

import { IVerifyTwoFactorAuthRequest } from '@gen2/api/user-settings/api';
import {
  TextField,
} from '@mui/material';
import { KeyboardEventHandler } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';



interface RegionProps {
  control: Control<IVerifyTwoFactorAuthRequest>;
  errors: FieldErrors<IVerifyTwoFactorAuthRequest>;
  handleSubmit: () => void;
}

export const MfaCodeField = ({ control, errors, handleSubmit }: RegionProps) => {
  const { t } = useTranslation('validation');

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }

    const key = event.key;

    const isAllowedKey =
      /^\d+$/.test(key) ||
      key === 'Backspace' ||
      (event.ctrlKey && key === 'v') ||
      (event.metaKey && key === 'v') ||
      (event.ctrlKey && key === 'a') ||
      (event.metaKey && key === 'a') ||
      key.startsWith('Arrow') ||
      key === 'Delete' ||
      key === 'Home' ||
      key === 'End';

    if (!isAllowedKey) {
      event.preventDefault();
    }
  };

  return (
    <Controller
      name="code"
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          id="code"
          fullWidth
          required
          label={ t('twoFactorCode.label')}
          onKeyDown={handleKeyPress}
          error={Boolean(errors.code)}
          type="text"
          helperText={
            errors.code?.message && t(errors.code.message)
          }
          inputProps={{
            'data-cy': 'code-input',
          }}
        />
      )}
    />
  );
};
