import {
  grayDark2,
  grayLight1,
  grayLight3,
} from '@components/theme/gen2/new-colors';
import { styled } from '@mui/material';
import { rem, transparentize } from 'polished';

export const StyledToolbarPlugin = styled('div')({
  display: 'grid',
  backgroundColor: grayLight3,
  color: grayDark2,
  borderBottom: `1px solid ${grayLight1}`,
});

export const StyledFormatGroup = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledFormatButton = styled('button')<{ active?: boolean }>(
  ({ active }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: rem(12),
    border: 'none',
    backgroundColor: 'transparent',
    color: grayDark2,

    ...(active && {
      backgroundColor: transparentize(0.5, grayLight1),
    }),

    '&:hover': {
      cursor: 'pointer',
    },
  }),
);
