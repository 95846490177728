import { colors, opacity } from '@components/theme/gen2';
import { Components, Theme } from '@mui/material';
import { rem, rgba } from 'polished';

export const MuiTextField: Components<Theme>['MuiTextField'] = {
  defaultProps: {
    color: 'primary',
    variant: 'outlined',
  },
  styleOverrides: {
    root: {
      // outlined variant
      '& .MuiOutlinedInput-root': {
        ':hover': {
          backgroundColor: colors.grayLight3, // hover background color
        },
      },
      '& .MuiOutlinedInput-input': {
        paddingLeft: rem(14),
        paddingRight: rem(14),
        paddingTop: rem(10),
        paddingBottom: rem(10),
        fontSize: rem(14),
        height: rem(20),
        color: colors.grayDark3, // input text color
      },
      '& .MuiInputLabel-outlined[data-shrink=false]': {
        top: rem(-5),
        fontSize: rem(14),
        color: colors.grayDark2, // hint label color
      },
      '& .MuiInputAdornment-outlined': {
        color: colors.grayDark2,  // icon color
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.grayDark1, // border color
      },
      '& .MuiInputLabel-formControl': {
        color: colors.grayDark2,  // label color
      },
      '.Mui-focused.MuiInputLabel-formControl': {
        color: colors.info, // focused label color
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.info, // focused border color
      },
      // filled variant
      '& .MuiFilledInput-root': {
        backgroundColor: colors.grayLight3, // filled background color default
        "::before": {
          borderBottom: `1px solid ${colors.grayDark1}`, // filled border color
        },
        "::after": {
          borderBottom: `1px solid ${colors.grayDark1}`, // filled border color
        },
        ':hover': {
          backgroundColor: colors.grayLight2, // hover background color
        },
        ':hover:not(.Mui-disabled,.Mui-error):before': {
          borderBottom: `1px solid ${colors.grayDark1}`, // filled border color
        },
      },
      '& .MuiFilledInput-input': {
        color: colors.grayDark3, // filled input text color
        fontSize: rem(14),
        '::placeholder': {
          color: colors.grayDark2, // filled placeholder color
          opacity: 1
        }
      },
      '& .Mui-focused.MuiFilledInput-root': {
        backgroundColor: rgba(colors.blue, opacity._15), // focus background color
        '::after': {
          borderBottom: `2px solid ${colors.blue}`,  // focused border color
        },
        '.MuiFilledInput-input':{
          '::placeholder': {
            color: colors.gray, // focus filled placeholder color
            opacity: 1
          }
        }
      },
      '& .MuiInputAdornment-filled': {
        color: colors.grayDark2, // filled icon color
      },
    },
  },
  variants: [
    {
      props: { error: true, variant: 'outlined' },
      style: {
        '& .Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.red, // error border color
        },
        '& .MuiInputLabel-formControl, & .Mui-focused.Mui-error, &:hover .MuiInputLabel-formControl':
        {
          color: colors.red, // error label color
        },
        '& .Mui-error': {
          color: colors.red,  // error text color
          marginLeft: 0,
        },
        '& .Mui-error.MuiInputLabel-outlined[data-shrink=false] .MuiFormLabel-asterisk': {
          color: colors.grayDark2,  // error idle hint asterisk color
        },
        '& .MuiOutlinedInput-root': {
          ':hover': {
            backgroundColor: colors.grayLight3, // hover background color
          },
        },

        // asterisk set to red when hovering in the error state
        '& .MuiInputLabel-outlined[data-shrink=false] .MuiFormLabel-asterisk': {
          color: colors.red, // error hint asterisk color
        },

      },
    },
    {
      props: { disabled: true, variant: 'outlined' },
      style: {
        '& .MuiInputBase-root.Mui-disabled': {
          ':hover': {
            backgroundColor: colors.white, // disabled background color
          }
        },
        '& .MuiInputLabel-root.Mui-disabled': {
          color: colors.grayDark1, // label disabled color
        },
        '& .MuiOutlinedInput-input.Mui-disabled': {
          color: colors.grayDark3,  // input filled disabled text color
        },
        '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.gray,  //border disabled color
        },
        '& .Mui-disabled .MuiInputAdornment-outlined': {
          color: colors.gray, // icon disabled color
        },
      },
    },
    {
      props: { multiline: true, variant: 'outlined' },
      style: ({ theme }) => {
        return {
          '& .MuiInputBase-multiline .MuiInputBase-inputMultiline': {
            paddingTop: rem(8.5),
            paddingLeft: rem(14),
            paddingRight: rem(14),
            paddingBottom: rem(34.5),
            resize: 'horizontal',
          },
          '& .MuiInputBase-multiline': {
            padding: 0,
          },
          '& .MuiInputBase-multiline.Mui-disabled .MuiInputBase-inputMultiline':
          {
            resize: 'none',
          },
          [theme.breakpoints.down('sm')]: {
            '& .MuiInputBase-multiline .MuiInputBase-inputMultiline': {
              paddingTop: rem(8.5),
              paddingBottom: rem(8.5),
            },
          },
        };
      },
    },
    {
      props: { error: true, variant: 'filled' },
      style: {
        '& .Mui-error.MuiFilledInput-root': {
          backgroundColor: rgba(colors.redLight3, opacity._20), // error background color
          "::before": {
            borderBottom: `1px solid ${colors.red}`, // filled border color
          },
          "::after": {
            borderBottom: `1px solid ${colors.red}`, // filled border color
          },
          ':hover': {
            backgroundColor: colors.grayLight2, // hover background color
          },
        },
        '& .Mui-error.MuiInputLabel-filled[data-shrink=false] .MuiFormLabel-asterisk': {
          color: colors.grayDark2,  // error idle hint asterisk color
        },
        '& .Mui-error.MuiInputLabel-filled[data-shrink=true]': {
          color: colors.red,  // error label color
        },
      },
    },
    {
      props: { disabled: true, variant: 'filled' },
      style: {
        '& .MuiFilledInput-root.Mui-disabled': {
          backgroundColor: colors.white, // disabled background color
          "::before": {
            borderBottom: `1px solid ${colors.gray}`, // filled border color
          },
          "::after": {
            borderBottom: `1px solid ${colors.gray}`, // filled border color
          },
          ':hover': {
            backgroundColor: colors.white, // disabled background color
          }
        },
        '& .Mui-disabled .MuiInputAdornment-filled': {
          color: colors.grayDark1, // filled icon color
        },
        '& .Mui-disabled.MuiInputLabel-filled': {
          color: colors.grayDark1, // label disabled color
        },
      },
    },
  ],
};
