import { colors, weights } from '@components/theme/gen2';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
} from '@mui/material';
import { rem } from 'polished';

export const StyledFileUploadRequest = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(30),
});

export const StyledAttachmentsLabel = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: rem(10),

  '& > span:first-child': {
    fontSize: rem(15),
    fontWeight: weights.semiBold,
  },
});

export const StyledAttachments = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(10),
});

export const StyledRedactionField = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: rem(10),
});

export const StyledAdditionalOptions = styled(Accordion)({
  background: 'none',
  boxShadow: 'none',

  '&:before': {
    display: 'none',
  },
});

export const StyledAdditionalOptionsHead = styled(AccordionSummary)({
  padding: 0,
  minHeight: rem(40),
  border: 'unset',
  flexDirection: 'row-reverse',
  gap: rem(10),
  color: colors.secondary,

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    fontSize: rem(14),
    color: colors.secondary,
  },

  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
});

export const StyledAdditionalOptionsContent = styled(AccordionDetails)({
  paddingLeft: 0,
  paddingRight: 0,
  paddingBottom: 0,
});
