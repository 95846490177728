import { MsalProvider } from '@azure/msal-react';
import { queryClient } from '@gen2/config/queryClient';
import { Microsoft } from '@gen2/services/social-login/microsoft/microsoft';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/app';
import { CustomRouter } from './app/components/router/CustomRouter';
import history from './app/components/router/history';
import { AuthProvider } from './contexts/AuthContext';
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const clientId =
  process.env['NX_GOOGLE_CLIENT_ID'] ??
  '1021980598191-u9mrt5nokm4sr2mir9dci1h44192mbtd.apps.googleusercontent.com';

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <CustomRouter history={history}>
        <GoogleOAuthProvider clientId={clientId}>
          <MsalProvider
            instance={Microsoft.initialize(
              process.env['NX_MICROSOFT_CLIENT_ID'],
            )}
          >
            <AuthProvider>
              <Sentry.ErrorBoundary showDialog>
                <App />
              </Sentry.ErrorBoundary>
            </AuthProvider>
          </MsalProvider>
        </GoogleOAuthProvider>
      </CustomRouter>
    </QueryClientProvider>
  </StrictMode>,
);
