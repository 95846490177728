import { colors, textSizes, weights } from '@components/theme/gen2';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  styled,
} from '@mui/material';
import { rem } from 'polished';

interface SubmitButtonProps {
  isLoading?: boolean;
}

export const StyledDialog = styled(Dialog)({
  maxHeight: 'unset',
});

export const StyledDialogContent = styled(DialogContent)({
  overflow: 'hidden',
  gap: rem(30),
  padding: rem(30),
  paddingTop: `${rem(20)} !important`,
  display: 'flex',
  flexDirection: 'column',
});

export const StyledInfoBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: rem(5),
  fontSize: rem(textSizes.md),
  color: colors.black,
});

export const StyledDescriptionBox = styled('p')({
  wordWrap: 'break-word',
});

export const StyledBackupContainerBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(5),
  alignItems: 'center',
  fontSize: rem(textSizes.md),
  color: colors.black,
  textAlign: 'center',
});

export const StyledBackupCodeBox = styled(Box)({
  width: 'fit-content',
  padding: rem(5),
  minWidth: rem(275),
  backgroundColor: colors.grayLight2,
  color: colors.black,
});

export const StyledColumnBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(20),
  alignItems: 'center',
  textAlign: 'left',
});

export const StyledDialogActions = styled(DialogActions)({
  padding: rem(20),
});

export const StyledCancelButton = styled(Button)({
  // color: colors.grayDark3,
  // backgroundColor: colors.grayLight1,
  // ':disabled': {
  //   backgroundColor: colors.grayLight1,
  // }
});

export const StyledDownloadButton = styled(Button)<SubmitButtonProps>(
  ({ isLoading }) => ({
    width: isLoading ? rem(156) : 'auto',
  }),
);

export const StyledDialogTitle = styled(DialogTitle)({
  paddingTop: rem(20),
});

export const Title = styled(Typography)({
  fontSize: rem(20),
  fontWeight: weights.semiBold,
});

export const StyledGenerateButton = styled(Button)<SubmitButtonProps>(
  ({ isLoading }) => ({
    width: isLoading ? rem(156) : 'auto',
  }),
);
