import { IVerifyTwoFactorAuthRequest } from '@gen2/api/user-settings/api';
import { useChallengeTwoFactorAuthMutation } from '@gen2/api/user-settings/hooks';
import { MfaCodeField } from '@gen2/app/components/mfa-code/mfa-code';
import { useAuth } from '@gen2/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Box } from '@mui/material';
import { AxiosResponse } from 'axios';
import { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from './Login.styled';

interface MfaFormProps {
  active: boolean;
  handlePageChange: (step?: number) => void;
}

const schema = Yup.object().shape({
  code: Yup.string()
    .min(4, 'twoFactorCode.min')
    .max(6, 'twoFactorCode.max')
    .required('twoFactorCode.required'),
});

const MfaForm: FC<MfaFormProps> = ({ active, handlePageChange }) => {
  const { t } = useTranslation('validation');

  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);
  const { mutate } = useChallengeTwoFactorAuthMutation();
  const { authCode } = useAuth();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IVerifyTwoFactorAuthRequest>({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(schema),
  });


  const onSubmit: SubmitHandler<IVerifyTwoFactorAuthRequest> = (form) => {
    setIsLoading(true);

    mutate(form, {
      onError: (error: unknown) => {
        const axiosError = error as AxiosResponse;

        if (axiosError) {
          const { data } = axiosError;

          setMessages([data.message]);
          setIsShow(true);
        }

        setIsLoading(false);
      },
      onSuccess: () => {
        setIsLoading(false);
        authCode();
      },
    });
  };

  useEffect(() => {
    if (!active) {
      setIsShow(false);
      setMessages([]);
      reset();
    }
  }, [active, reset]);

  if (!active) return null;

  return (
    <Form>
      <MfaCodeField control={control} errors={errors} handleSubmit={() => handleSubmit(onSubmit)()} />

      {isShow && (
        <Alert data-cy="alert" icon={false} severity="error" color="error">
          {messages}
        </Alert>
      )}

      <LoadingButton
        variant="contained"
        color="primary"
        loading={isLoading}
        type="submit"
        onClick={handleSubmit(onSubmit)}
        data-cy="next"
      >
        {t('login.mfa.button', { ns: 'page' })}
      </LoadingButton>

      <Box>
        <p>{t('securitySettings.twoFactorAuth.loginBackupForm.useBackupCode1', {ns: 'userSettings'})}</p>
        <Link to="#" onClick={() => handlePageChange(4)}>
        {t('securitySettings.twoFactorAuth.loginBackupForm.useBackupCode2', {ns: 'userSettings'})}
        </Link>
      </Box>

      <Link to="#" onClick={() => handlePageChange(2)}>
        {t('login.mfa.back', { ns: 'page' })}
      </Link>
    </Form>
  );
};
export default MfaForm;
