import SearchBar from '@components/lib/searchbar/searchbar';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContactsQuery } from '@gen2/api/contacts/hooks';
import { TContact } from '@gen2/types/contact';
import { stringAvatar } from '@gen2/utils/name';
import { Skeleton, Stack, Tooltip } from '@mui/material';
import Popper from '@mui/material/Popper';
import { useMemo, useRef, useState } from 'react';
import { useDebounce, useOnClickOutside } from 'usehooks-ts';
import {
  StyledContactAddBtn,
  StyledContainer,
  StyledContactPopupHeader,
  StyledLoadingEllipsis,
  StyledLoadingContainer,
  StyledContactHeading,
  StyledContactList,
  StyledContactListItem,
  StyledContactAvatar,
  StyledContactName,
  StyledContactEmail,
  StyledNoResults,
  StyledContactPopper,
  StyledSkeletonContainer,
  StyledEditIcon,
  StyledBox,
} from './contact-popup.styled';

export type ContactPopupProps = {
  open: boolean;
  id?: string;
  anchorEl: null | HTMLElement;
  onOpenContact: (
    e: React.MouseEvent,
    mode: 'edit' | 'add',
    contact: TContact | null,
  ) => void;
  loading?: boolean;
  onSelectContact: (contact: TContact) => void;
  onClickAway: () => void;
  resolveItems?: (items: TContact[]) => TContact[];
} & React.ComponentProps<typeof Popper>;

export default function ContactPopup({
  open,
  id,
  anchorEl,
  onOpenContact,
  onSelectContact,
  onClickAway,
  resolveItems,
  loading,
  ...rest
}: ContactPopupProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);

  const { data, isLoading } = useContactsQuery(
    {
      'filter[full_name]': debouncedQuery,
      'sort[column]': 'created_at',
      'sort[order]': 'desc',
    },
    {
      suspense: false,
      enabled: debouncedQuery.length >= 2 || !debouncedQuery,
    },
  );

  const items = useMemo(() => {
    if (resolveItems) {
      return resolveItems(data?.data?.contacts || []);
    }

    return data?.data?.contacts || [];
  }, [resolveItems, data]);

  // click outside to close
  useOnClickOutside(ref, () => {
    onClickAway();
    setQuery('');
  });

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setQuery(e.target.value);
  };

  return (
    <StyledContactPopper
      ref={ref}
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      {...rest}
    >
      <StyledContainer>
        <StyledContactPopupHeader direction="row" spacing={2}>
          <SearchBar
            onChange={handleSearch}
            value={query}
            onReset={() => setQuery('')}
            id="contact-search"
            placeholder="Search by name"
            data-cy="contact-search-input"
            autoFocus
            disabled={loading}
          />
          <StyledContactAddBtn
            variant="outlined"
            color="tertiary"
            startIcon={<FontAwesomeIcon icon={regular('plus-circle')} />}
            onClick={(e) => onOpenContact(e, 'add', null)}
            data-cy="add-new-contact-btn"
            disabled={loading}
          >
            Add New
          </StyledContactAddBtn>
        </StyledContactPopupHeader>
        {isLoading ? (
          <StyledLoadingContainer>
            <FontAwesomeIcon icon={regular('search')} />
            <StyledLoadingEllipsis>...</StyledLoadingEllipsis>
          </StyledLoadingContainer>
        ) : (
          <Stack spacing={1}>
            <StyledContactHeading data-cy="contact-heading">
              Contacts
            </StyledContactHeading>
            <StyledContactList
              direction="column"
              spacing={1}
              data-cy="contact-list"
            >
              {items.length ? (
                items.map((contact) => {
                  if (loading) {
                    return (
                      <StyledSkeletonContainer key={contact.id}>
                        <Skeleton
                          animation="wave"
                          variant="circular"
                          width={40}
                          height={40}
                        />
                        <div>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{ fontSize: '1rem' }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{ fontSize: '1rem' }}
                          />
                        </div>
                      </StyledSkeletonContainer>
                    );
                  }

                  return (
                    <StyledContactListItem
                      key={contact.id}
                      data-cy="contact-list-item"
                    >
                      <StyledBox
                        onClick={() => onSelectContact(contact)}
                        disabled={!!contact.disabled}
                      >
                        <StyledContactAvatar>
                          {stringAvatar(
                            contact.first_name,
                            contact.last_name,
                          ) || 'FI'}
                        </StyledContactAvatar>

                        <Stack direction="column">
                          <StyledContactName>{`${contact.first_name} ${contact.last_name}`}</StyledContactName>
                          <StyledContactEmail>
                            {contact.email}
                          </StyledContactEmail>
                        </Stack>
                      </StyledBox>
                      <Tooltip title="Edit" placement="top">
                        <StyledEditIcon
                          onClick={(_e) => onOpenContact(_e, 'edit', contact)}
                        >
                          <FontAwesomeIcon size="lg" icon={regular('pencil')} />
                        </StyledEditIcon>
                      </Tooltip>
                    </StyledContactListItem>
                  );
                })
              ) : (
                <StyledNoResults key="no-contacts" data-cy="contact-list-item">
                  No contact(s) found, please try searching by Name only.
                </StyledNoResults>
              )}
            </StyledContactList>
          </Stack>
        )}
      </StyledContainer>
    </StyledContactPopper>
  );
}
