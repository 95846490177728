import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IChangePasswordRequest } from '@gen2/api/user-settings/api';
import { useChangePasswordMutation } from '@gen2/api/user-settings/hooks';
import { PasswordHintList } from '@gen2/app/components/hint-list/hint-list';
import { useAuth } from '@gen2/hooks';
import { ErrorResponse } from '@gen2/utils/formatMessage';
import { yupResolver } from '@hookform/resolvers/yup';

import { Alert, InputAdornment, Stack, TextField } from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { AxiosResponse } from 'axios';
import { has } from 'lodash';
import { rem } from 'polished';
import * as React from 'react';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  StyledCancelButton,
  StyledColumnLeft,
  StyledColumnRight,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledFormGroup,
  StyledRowBox,
  StyledSubmit,
  Title,
} from './change-password-modal.styles';
import { useChangePasswordStore } from './store';

export type TChangePasswordModalProps = {
  open: boolean;
  onClose: () => void;
};

interface IChangePasswordError {
  current_password?: [];
  new_password?: [];
  new_password_confirmation?: [];
}

const initialFormValues: IChangePasswordRequest = {
  current_password: '',
  new_password: '',
  new_password_confirmation: '',
};

const schema = Yup.object().shape({
  current_password: Yup.string().required(
    'securitySettings.changePassword.modal.currentPassword.required',
  ),
  new_password: Yup.string()
    .required('securitySettings.changePassword.modal.newPassword.required')
    .matches(
      /^(?=.*[!@#$%^&*'])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,99}$/,
      'securitySettings.changePassword.modal.newPassword.pattern',
    ),
  new_password_confirmation: Yup.string()
    .required('securitySettings.changePassword.modal.confirmPassword.required')
    .oneOf(
      [Yup.ref('new_password'), 'asdasd'],
      'securitySettings.changePassword.modal.confirmPassword.match',
    ),
});

const ChangePasswordModal = ({ open, onClose }: TChangePasswordModalProps) => {
  const { t } = useTranslation('userSettings');
  const { mutate } = useChangePasswordMutation();
  const { initialize } = useAuth();

  const { setIsSuccessShow, setIsSuccessShowMessage } =
    useChangePasswordStore();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    watch,
  } = useForm<IChangePasswordRequest>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });

  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [showPassword3, setShowPassword3] = React.useState(false);

  const [isShow, setIsShow] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);

  const handleTogglePasswordVisibility = (field: number) => {
    switch (field) {
      case 1:
        setShowPassword1(!showPassword1);
        break;
      case 2:
        setShowPassword2(!showPassword2);
        break;
      case 3:
        setShowPassword3(!showPassword3);
        break;
      default:
        break;
    }

    return;
  };

  const password = watch('new_password');

  const onSubmit: SubmitHandler<IChangePasswordRequest> = (form) => {
    const current_password = form.current_password;
    const new_password = form.new_password;
    const new_password_confirmation = form.new_password_confirmation;

    const data = {
      current_password,
      new_password,
      new_password_confirmation,
    } as IChangePasswordRequest;

    mutate(data, {
      onSuccess: () => {
        reset();
        onClose();
        setIsShow(false);
        initialize();
        setIsSuccessShow(true);
        setShowPassword1(false);
        setShowPassword2(false);
        setShowPassword3(false);
        setIsSuccessShowMessage(t('securitySettings.changePassword.success'));
      },
      onError: (err: unknown) => {
        const axiosError = err as AxiosResponse;

        if (axiosError) {
          const { status, data } = axiosError;

          if (status === 422) {
            const responseData = data as ErrorResponse;
            const errors = responseData.errors as IChangePasswordError;

            if (errors) {
              if (has(errors, 'current_password')) {
                setError('current_password', {
                  type: 'server',
                  message: errors.current_password
                    ? errors.current_password.at(0)
                    : 'securitySettings.changePassword.modal.currentPassword.match',
                });
              }

              if (has(errors, 'new_password')) {
                setError('new_password', {
                  type: 'server',
                  message: errors.new_password
                    ? errors.new_password.at(0)
                    : 'securitySettings.changePassword.modal.newPassword.invalid',
                });
              }

              if (has(errors, 'new_password_confirmation')) {
                setError('new_password_confirmation', {
                  type: 'server',
                  message: errors.new_password_confirmation
                    ? errors.new_password_confirmation.at(0)
                    : 'profileSettings.form.lastName.invalid',
                });
              }
            }
          }

          if (
            status >= 500 ||
            status === 401 ||
            status === 400 ||
            status === 429
          ) {
            setIsShow(true);

            if (status >= 500) {
              setMessages([t('error.500', { ns: 'common' })]);
            }

            if (status === 401 || status === 400) {
              setMessages([t(`error.${status}`)]);
            }

            if (status === 429) {
              setMessages([data.message]);
            }
          }
        }
      },
    });
  };

  const handleOnClose = () => {
    reset();
    onClose();
    setIsShow(false);
    setIsSuccessShow(false);
    setShowPassword1(false);
    setShowPassword2(false);
    setShowPassword3(false);
  };

  return (
    <StyledDialog
      open={open}
      onClose={() => handleOnClose()}
      aria-labelledby="change-password-modal-title"
      aria-describedby="change-password-modal-description"
      data-cy="change-password-modal"
    >
      <ModalCloseButton
        data-cy="close-btn"
        aria-label="close"
        onClick={() => handleOnClose()}
      />

      <StyledDialogTitle data-cy="title" id="change-password-modal-title">
        <Title>{t('securitySettings.changePassword.modal.title')}</Title>
      </StyledDialogTitle>

      <StyledDialogContent data-cy="content" dividers>
        {isShow && (
          <Alert
            data-cy="alert"
            icon={false}
            severity="error"
            color="error"
            sx={{ mb: rem(25) }}
          >
            {messages}
          </Alert>
        )}

        <StyledRowBox>
          <StyledColumnLeft>
            <form noValidate>
              <Stack spacing={3} sx={{ flexWrap: 'wrap' }}>
                <StyledFormGroup>
                  <Controller
                    name="current_password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="current_name"
                        fullWidth
                        required
                        type={showPassword1 ? 'text' : 'password'}
                        label={t(
                          'securitySettings.changePassword.modal.currentPassword.label',
                        )}
                        error={Boolean(errors.current_password)}
                        helperText={
                          errors.current_password?.message &&
                          t(errors.current_password.message)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <FontAwesomeIcon
                                onClick={() =>
                                  handleTogglePasswordVisibility(1)
                                }
                                icon={
                                  showPassword1
                                    ? light('eye-slash')
                                    : light('eye')
                                }
                              />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ 'data-cy': 'current_password' }}
                      ></TextField>
                    )}
                  />
                </StyledFormGroup>
                <Controller
                  name="new_password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="new_password"
                      fullWidth
                      required
                      type={showPassword2 ? 'text' : 'password'}
                      label={t(
                        'securitySettings.changePassword.modal.newPassword.label',
                      )}
                      error={Boolean(errors.new_password)}
                      helperText={
                        errors.new_password?.message &&
                        t(errors.new_password.message)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <FontAwesomeIcon
                              onClick={() => handleTogglePasswordVisibility(2)}
                              icon={
                                showPassword2
                                  ? light('eye-slash')
                                  : light('eye')
                              }
                            />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{ 'data-cy': 'new_password' }}
                    />
                  )}
                />

                <StyledFormGroup>
                  <Controller
                    name="new_password_confirmation"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="new_password_confirmation"
                        fullWidth
                        required
                        type={showPassword3 ? 'text' : 'password'}
                        label={t(
                          'securitySettings.changePassword.modal.confirmPassword.label',
                        )}
                        error={Boolean(errors.new_password_confirmation)}
                        helperText={
                          errors.new_password_confirmation?.message &&
                          t(errors.new_password_confirmation.message)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <FontAwesomeIcon
                                onClick={() =>
                                  handleTogglePasswordVisibility(3)
                                }
                                icon={
                                  showPassword3
                                    ? light('eye-slash')
                                    : light('eye')
                                }
                              />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ 'data-cy': 'new_password_confirmation' }}
                      />
                    )}
                  />
                </StyledFormGroup>
              </Stack>
            </form>
          </StyledColumnLeft>
          <StyledColumnRight style={{ flex: 2 }}>
            <PasswordHintList password={password} />
          </StyledColumnRight>
        </StyledRowBox>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledCancelButton
          onClick={() => handleOnClose()}
          variant="outlined"
          data-cy="mfa-modal-close-btn"
          color="tertiary"
        >
          Cancel
        </StyledCancelButton>
        <StyledSubmit
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          //onClick={() => alert(123) }
          data-cy="submit"
        >
          {t('securitySettings.changePassword.modal.submit')}
        </StyledSubmit>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ChangePasswordModal;
