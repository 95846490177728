import { Box } from '@mui/material';
import { FC } from 'react';

const NotFound: FC = () => {
  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        display: 'flex',
        flexGrow: 1,
        py: '80px',
      }}
    >
      404 not found
    </Box>
  );
};

export default NotFound;
