import { styled } from '@mui/material';
import { rem } from 'polished';

export const StyledTemplateButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: rem(50),

  '> span': {
    marginLeft: rem(5),
    marginRight: rem(10),
  },
});
