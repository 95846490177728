import { useGenerateBackupCodeMutation } from '@gen2/api/user-settings/hooks';
import { useToast } from '@gen2/hooks';
import { Loader, ModalCloseButton } from '@nx-fe/components';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledBackupCodeBox,
  StyledBackupContainerBox,
  StyledCancelButton,
  StyledDescriptionBox,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledDownloadButton,
  StyledGenerateButton,
  StyledInfoBox,
  Title,
} from './mfa-backup-code-modal.styles';

export type TMfaModalProps = {
  open: boolean;
  onClose: () => void;
  backupCodes?: string[];
};

const MfaBackupCodeModal = ({ open, onClose, backupCodes }: TMfaModalProps) => {
  const { t } = useTranslation('userSettings');
  const [isLoading, setIsLoading] = useState(false);
  const [codes, setCodes] = useState<string[]>([]);
  const toast = useToast();
  const { mutate } = useGenerateBackupCodeMutation();

  useEffect(() => {
    setCodes(backupCodes ?? []);
  }, [backupCodes]);

  const onSubmit = () => {
    setIsLoading(true);

    mutate([], {
      onSuccess: (res) => {
        const response = res as AxiosResponse;

        setCodes(response?.data?.data);
        setIsLoading(false);

        toast.show({
          text: t('securitySettings.twoFactorAuth.backupModal.newCodes'),
          variant: 'success',
        });
      },
      onError: () => {
        setIsLoading(false);
      },
    });
  };

  const handleDownload = () => {
    let textContent = '';

    codes?.forEach((code: string, index: number) => {
      textContent += code;

      if (index !== codes.length - 1) {
        textContent += '\n';
      }
    });

    const blob = new Blob([textContent], { type: 'text/plain' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'fileinvite-backup-codes.txt';
    link.click();

    URL.revokeObjectURL(link.href);
  };

  const handleOnClose = () => {
    if (isLoading) {
      return;
    }

    onClose();
  };

  return (
    <StyledDialog
      open={open}
      onClose={() => handleOnClose()}
      aria-labelledby="mfa-backup-code-modal-title"
      aria-describedby="mfa-backup-code-modal-description"
      data-cy="mfa-modal"
    >
      <ModalCloseButton
        data-cy="close-btn"
        aria-label="close"
        onClick={() => handleOnClose()}
      />

      <StyledDialogTitle data-cy="title" id="mfa-backup-code-modal-title">
        <Title>{t('securitySettings.twoFactorAuth.backupModal.title')}</Title>
      </StyledDialogTitle>

      <StyledDialogContent data-cy="content" dividers>
        <StyledInfoBox>
          <StyledDescriptionBox>
            {t('securitySettings.twoFactorAuth.backupModal.description')}
          </StyledDescriptionBox>
        </StyledInfoBox>

        <StyledBackupContainerBox>
          {codes?.map((code: string, index: number) => (
            <StyledBackupCodeBox key={index}>{code}</StyledBackupCodeBox>
          ))}
        </StyledBackupContainerBox>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledCancelButton
          onClick={onClose}
          variant="outlined"
          color="tertiary"
          data-cy="mfa-backup-code-close-btn"
          disabled={isLoading}
        >
          {t('securitySettings.twoFactorAuth.backupModal.cancel')}
        </StyledCancelButton>
        <StyledDownloadButton
          onClick={handleDownload}
          color="secondary"
          variant="contained"
          data-cy="mfa-backup-code-submit-btn"
          disabled={isLoading}
          isLoading={isLoading}
        >
          {isLoading ? (
            <Loader
              props={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 'inherit',
              }}
            />
          ) : (
            t('securitySettings.twoFactorAuth.backupModal.download')
          )}
        </StyledDownloadButton>
        <StyledGenerateButton
          onClick={onSubmit}
          color="primary"
          variant="contained"
          data-cy="mfa-backup-code-submit-btn"
          disabled={isLoading}
          isLoading={isLoading}
        >
          {isLoading ? (
            <Loader
              props={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 'inherit',
              }}
            />
          ) : (
            t('securitySettings.twoFactorAuth.backupModal.generate')
          )}
        </StyledGenerateButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default MfaBackupCodeModal;
