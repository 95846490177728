import { config } from '../config';
import HttpRequest from './axios';

export const baseUrl =
  process.env['NX_RUNTIME_ENV'] === 'prod'
    ? config.baseUrl.prod
    : process.env['NX_RUNTIME_ENV'] === 'preprod'
      ? config.baseUrl.preprod
      : process.env['NX_RUNTIME_ENV'] === 'local'
        ? config.baseUrl.local
        : config.baseUrl.dev;

export default new HttpRequest(baseUrl);
